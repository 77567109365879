import { Beer } from "../types";
import { toTitleCase } from "../Utilities";
import '../styles/Product.scss';

function Product(beer: Beer) {
  const name = beer.name.split('-')
    .map(toTitleCase)
    .join(' ');
  const brewery = beer.custom_fields.find(i => i.name === 'Producer')?.value;
  const image = beer.custom_fields.find(i => i.name === 'product_image_1')?.value ||
    beer.custom_fields.find(i => i.name === 'product_image_2')?.value;

  return (
    <article className="product">
      <h2>
        {name}
        <span className="brewery">{brewery}</span>
      </h2>
      <img
        src={image}
        alt={beer.name + " Product Image"} className="image" />
      <ol className="variants">
        {beer.variants.map(variant => {
          return <li key={variant.id}>
            <span className="label">{variant.option_values[0].label}</span>
            <span className="price">{variant.sale_price || variant.calculated_price}</span>
          </li>
        })}
      </ol>
    </article>
  );
}

export default Product;
