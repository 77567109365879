import { Beer } from './types';
import './styles/App.scss';
import { useEffect, useState } from 'react';
import { getBeers } from './proxies/Beer';
import Product from './components/Product';


function App() {
  // const { beers, loading, error } = useBeers({}, 0);
  // const [beers, setBeers] = useState([]);
  // // let filtered: Beer[] = [];

  useEffect(() => {
    const { beers } = getBeers();
    setBeers(beers);
  }, [])

  // fetch('http://localhost:3001')
  //   .then(response => response.json())
  //   .then(data => {
  //     setBeers(data);
  //   });

  // const filtered = beers.filter((d: any) => d.name.match(/ipa/));
  //   console.log(filtered)

  const [beers, setBeers] = useState([] as Beer[]);


  // getBeers()
  // fetch('http://localhost:3001')
  //   .then(async response => {
  //     const newBeers = await response.json();
  //     setBeers(newBeers);
  //     console.log(beers);
  //   });

  // .then((beers: Beer[]) => {
  //   filtered = beers.filter(d => d.name.match(/ipa/));
  //   console.log(filtered)
  // });
  return (
    <main className="App">
      <header className="App-header">
        <h1>Experimenting with TBS</h1>
      </header>
      <section>
        {beers.map((beer, index) =>
          <Product {...beer} key={index}></Product>
        )}
      </section>
    </main>
  );
}

export default App;
