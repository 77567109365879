import { Beer } from "../types";

const beers: Beer[] = [
  {
    "id": 462343,
    "name": "8-6-extreme",
    "type": "physical",
    "sku": "2396_2405",
    "description": "Beer to the extreme, this rich, golden lager's taste is driven by huge malt notes.",
    "weight": 1,
    "width": 0,
    "depth": 0,
    "height": 0,
    "price": 88.95,
    "cost_price": 0,
    "retail_price": 0,
    "sale_price": 0,
    "map_price": 0,
    "tax_class_id": 0,
    "product_tax_code": "",
    "calculated_price": 88.95,
    "categories": [250, 232, 169, 165, 170, 167],
    "brand_id": 1030,
    "option_set_id": 462241,
    "option_set_display": "right",
    "inventory_level": 6,
    "inventory_warning_level": 0,
    "reviews_rating_sum": 0,
    "inventory_tracking": "variant",
    "reviews_count": 0,
    "total_sold": 0,
    "fixed_cost_shipping_price": 0,
    "is_free_shipping": false,
    "is_visible": true,
    "is_featured": false,
    "related_products": [-1],
    "warranty": "",
    "bin_picking_number": "",
    "layout_file": "",
    "upc": "",
    "mpn": "",
    "gtin": "",
    "search_keywords": "",
    "availability": "available",
    "availability_description": "",
    "gift_wrapping_options_type": "any",
    "gift_wrapping_options_list": [],
    "sort_order": 0,
    "condition": "New",
    "is_condition_shown": false,
    "order_quantity_minimum": 0,
    "order_quantity_maximum": 0,
    "page_title": "8.6 EXTREME~2405",
    "meta_keywords": [],
    "meta_description": "",
    "date_created": "2020-10-16T13:24:54+00:00",
    "date_modified": "2021-12-22T11:25:14+00:00",
    "view_count": 0,
    "preorder_release_date": null,
    "preorder_message": "",
    "is_preorder_only": false,
    "is_price_hidden": false,
    "price_hidden_label": "",
    "custom_url": {
      "url": "/bavaria-extreme-2405/",
      "is_customized": false
    },
    "base_variant_id": null,
    "open_graph_type": "product",
    "open_graph_title": "",
    "open_graph_description": "",
    "open_graph_use_meta_description": true,
    "open_graph_use_product_name": true,
    "open_graph_use_image": true,
    "variants": [
      {
        "id": 2288596,
        "product_id": 462343,
        "sku": "1322063_2405",
        "sku_id": 1826421,
        "price": 3.95,
        "calculated_price": 3.95,
        "sale_price": 3.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 6,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1827623,
            "label": "1 X Can 500 ml",
            "option_id": 462337,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2288602,
        "product_id": 462343,
        "sku": "1322130_2405",
        "sku_id": 1826426,
        "price": 88.95,
        "calculated_price": 88.95,
        "sale_price": 88.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1827628,
            "label": "24 X Can 500 ml",
            "option_id": 462337,
            "option_display_name": "Beer"
          }
        ]
      }
    ],
    "images": [],
    "primary_image": null,
    "custom_fields": [
      {
        "id": 6652438,
        "name": "ABV",
        "value": "10.50"
      },
      {
        "id": 6652445,
        "name": "Country",
        "value": "NL"
      },
      {
        "id": 6652448,
        "name": "Category",
        "value": "Import"
      },
      {
        "id": 6652452,
        "name": "Type",
        "value": "Lager"
      },
      {
        "id": 6652456,
        "name": "Beverage_type",
        "value": "Beer"
      },
      {
        "id": 6652459,
        "name": "Producer",
        "value": "BAVARIA"
      },
      {
        "id": 6652462,
        "name": "Price_Metadata_1322063",
        "value": "{\"current_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.44}],\"total_price\":3.95},\"previous_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.45}],\"total_price\":4.00},\"on_sale\":\"N\"}"
      },
      {
        "id": 6652464,
        "name": "Price_Metadata_1322130",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":9.96}],\"total_price\":88.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":9.98}],\"total_price\":89.15},\"on_sale\":\"N\"}"
      },
      {
        "id": 6652466,
        "name": "product_image_1",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbdaw0-1pypk0-2mijm5/2396.png?position=1"
      },
      {
        "id": 6652467,
        "name": "product_image_2",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbdaw0-1pypk0-2mijm5/2396.png?position=2"
      },
      {
        "id": 6652468,
        "name": "container_type ",
        "value": "C"
      },
      {
        "id": 6652469,
        "name": "pack",
        "value": "1,24"
      }
    ]
  },
  {
    "id": 885098,
    "name": "8-6-india-pale-lager",
    "type": "physical",
    "sku": "1548_2405",
    "description": "New 8.6 India Pale Lager is a rich, bright golden beer characterized by a fresh hoppy aroma, well balanced body and refreshing hop finish.",
    "weight": 1,
    "width": 0,
    "depth": 0,
    "height": 0,
    "price": 18,
    "cost_price": 0,
    "retail_price": 0,
    "sale_price": 0,
    "map_price": 0,
    "tax_class_id": 0,
    "product_tax_code": "",
    "calculated_price": 18,
    "categories": [
      250, 232, 169, 199, 217, 218, 179, 165, 170, 167, 166, 171
    ],
    "brand_id": 1030,
    "option_set_id": 884996,
    "option_set_display": "right",
    "inventory_level": 67,
    "inventory_warning_level": 0,
    "inventory_tracking": "variant",
    "reviews_rating_sum": 0,
    "reviews_count": 0,
    "total_sold": 0,
    "fixed_cost_shipping_price": 0,
    "is_free_shipping": false,
    "is_visible": true,
    "is_featured": false,
    "related_products": [-1],
    "warranty": "",
    "bin_picking_number": "",
    "layout_file": "",
    "upc": "",
    "mpn": "",
    "gtin": "",
    "search_keywords": "",
    "availability": "available",
    "availability_description": "",
    "gift_wrapping_options_type": "any",
    "gift_wrapping_options_list": [],
    "sort_order": 0,
    "condition": "New",
    "is_condition_shown": false,
    "order_quantity_minimum": 0,
    "order_quantity_maximum": 0,
    "page_title": "8.6 INDIA PALE LAGER~2405",
    "meta_keywords": [],
    "meta_description": "",
    "date_created": "2021-10-04T10:22:45+00:00",
    "date_modified": "2021-12-06T11:27:22+00:00",
    "view_count": 0,
    "preorder_release_date": null,
    "preorder_message": "",
    "is_preorder_only": false,
    "is_price_hidden": false,
    "price_hidden_label": "",
    "custom_url": {
      "url": "/8-6-india-pale-lager-2405/",
      "is_customized": false
    },
    "base_variant_id": null,
    "open_graph_type": "product",
    "open_graph_title": "",
    "open_graph_description": "",
    "open_graph_use_meta_description": true,
    "open_graph_use_product_name": true,
    "open_graph_use_image": true,
    "variants": [
      {
        "id": 4286342,
        "product_id": 885098,
        "sku": "3016063_2405",
        "sku_id": 3401412,
        "price": 3.05,
        "calculated_price": 2.8,
        "sale_price": 2.8,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 67,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 3402622,
            "label": "1 X Can 500 ml",
            "option_id": 885092,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 4286343,
        "product_id": 885098,
        "sku": "3016130_2405",
        "sku_id": 3401413,
        "price": 70.5,
        "calculated_price": 70.5,
        "sale_price": 70.5,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 3402623,
            "label": "24 X Can 500 ml",
            "option_id": 885092,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 4286344,
        "product_id": 885098,
        "sku": "3016199_2405",
        "sku_id": 3401414,
        "price": 35.75,
        "calculated_price": 35.75,
        "sale_price": 35.75,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 3402624,
            "label": "12 X Can 500 ml",
            "option_id": 885092,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 4286345,
        "product_id": 885098,
        "sku": "3016217_2405",
        "sku_id": 3401415,
        "price": 18,
        "calculated_price": 18,
        "sale_price": 18,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 3402625,
            "label": "6 X Can 500 ml",
            "option_id": 885092,
            "option_display_name": "Beer"
          }
        ]
      }
    ],
    "images": [],
    "primary_image": null,
    "custom_fields": [
      {
        "id": 12887921,
        "name": "ABV",
        "value": "7.00"
      },
      {
        "id": 12887922,
        "name": "Country",
        "value": "NL"
      },
      {
        "id": 12887923,
        "name": "Category",
        "value": "Import"
      },
      {
        "id": 12887924,
        "name": "Type",
        "value": "Lager"
      },
      {
        "id": 12887925,
        "name": "Beverage_type",
        "value": "Beer"
      },
      {
        "id": 12887926,
        "name": "Producer",
        "value": "BAVARIA"
      },
      {
        "id": 12887927,
        "name": "Beer_styles",
        "value": "India Pale Ale,Blonde,Golden"
      },
      {
        "id": 12887928,
        "name": "Price_Metadata_3016063",
        "value": "{\"current_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.31}],\"total_price\":2.80},\"previous_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.34}],\"total_price\":3.05},\"on_sale\":\"Y\"}"
      },
      {
        "id": 12887929,
        "name": "Price_Metadata_3016130",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":7.83}],\"total_price\":70.50},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.00}],\"total_price\":0.00},\"on_sale\":\"N\"}"
      },
      {
        "id": 12887930,
        "name": "Price_Metadata_3016199",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.97}],\"total_price\":35.75},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.00}],\"total_price\":0.00},\"on_sale\":\"N\"}"
      },
      {
        "id": 12887931,
        "name": "Price_Metadata_3016217",
        "value": "{\"current_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.00}],\"total_price\":18.00},\"previous_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.00}],\"total_price\":0.00},\"on_sale\":\"N\"}"
      },
      {
        "id": 12887932,
        "name": "container_type ",
        "value": "C"
      },
      {
        "id": 12887933,
        "name": "pack",
        "value": "1,24,12,6"
      },
      {
        "id": 12915000,
        "name": "product_image_1",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/gs7qt9bx86rtjzqkj6jt97b6/1548.png?position=1"
      },
      {
        "id": 12915001,
        "name": "product_image_2",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/gs7qt9bx86rtjzqkj6jt97b6/1548.png?position=2"
      }
    ]
  },
  {
    "id": 462558,
    "name": "ace-hill-grapefruit-radler",
    "type": "physical",
    "sku": "4852_2405",
    "description": "Our Grapefruit Radler is now 100 calories. Still the same vibrant grapefruit taste and aroma, with juicy fruity notes of citrus. The only 100-cal Radler made with real fruit. ",
    "weight": 1,
    "width": 0,
    "depth": 0,
    "height": 0,
    "price": 2.8,
    "cost_price": 0,
    "retail_price": 0,
    "sale_price": 0,
    "map_price": 0,
    "tax_class_id": 0,
    "product_tax_code": "",
    "calculated_price": 2.8,
    "categories": [162, 198, 189, 237, 165, 170],
    "brand_id": 1030,
    "option_set_id": 462455,
    "option_set_display": "right",
    "inventory_level": 68,
    "inventory_warning_level": 0,
    "inventory_tracking": "variant",
    "reviews_rating_sum": 0,
    "reviews_count": 0,
    "total_sold": 22,
    "fixed_cost_shipping_price": 0,
    "is_free_shipping": false,
    "is_visible": true,
    "is_featured": false,
    "related_products": [-1],
    "warranty": "",
    "bin_picking_number": "",
    "layout_file": "",
    "upc": "",
    "mpn": "",
    "gtin": "",
    "search_keywords": "",
    "availability": "available",
    "availability_description": "",
    "gift_wrapping_options_type": "any",
    "gift_wrapping_options_list": [],
    "sort_order": 0,
    "condition": "New",
    "is_condition_shown": false,
    "order_quantity_minimum": 0,
    "order_quantity_maximum": 0,
    "page_title": "ACE HILL GRAPEFRUIT RADLER~2405",
    "meta_keywords": [],
    "meta_description": "",
    "date_created": "2020-10-16T13:25:00+00:00",
    "date_modified": "2021-12-22T11:23:09+00:00",
    "view_count": 0,
    "preorder_release_date": null,
    "preorder_message": "",
    "is_preorder_only": false,
    "is_price_hidden": false,
    "price_hidden_label": "",
    "custom_url": {
      "url": "/ace-hill-radler-2405/",
      "is_customized": false
    },
    "base_variant_id": null,
    "open_graph_type": "product",
    "open_graph_title": "",
    "open_graph_description": "",
    "open_graph_use_meta_description": true,
    "open_graph_use_product_name": true,
    "open_graph_use_image": true,
    "variants": [
      {
        "id": 2289667,
        "product_id": 462558,
        "sku": "2011004_2405",
        "sku_id": 1827278,
        "price": 3.25,
        "calculated_price": 3.25,
        "sale_price": 3.25,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": true,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828479,
            "label": "1 X Can 473 ml",
            "option_id": 462551,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2289673,
        "product_id": 462558,
        "sku": "2011041_2405",
        "sku_id": 1827281,
        "price": 75.6,
        "calculated_price": 75.6,
        "sale_price": 75.6,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": true,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828484,
            "label": "24 X Can 473 ml",
            "option_id": 462551,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 4164079,
        "product_id": 462558,
        "sku": "2011272_2405",
        "sku_id": 3307971,
        "price": 2.8,
        "calculated_price": 2.8,
        "sale_price": 2.8,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 68,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 3309180,
            "label": "1 X Can 355 ml",
            "option_id": 462551,
            "option_display_name": "Beer"
          }
        ]
      }
    ],
    "images": [],
    "primary_image": null,
    "custom_fields": [
      {
        "id": 6655614,
        "name": "ABV",
        "value": "2.50"
      },
      {
        "id": 6655616,
        "name": "Country",
        "value": "CA"
      },
      {
        "id": 6655618,
        "name": "Category",
        "value": "Ontario Craft"
      },
      {
        "id": 6655619,
        "name": "Type",
        "value": "Light"
      },
      {
        "id": 6655621,
        "name": "Beverage_type",
        "value": "Beer"
      },
      {
        "id": 6655623,
        "name": "Producer",
        "value": "ACE HILL BEER COMPANY INC."
      },
      {
        "id": 6655626,
        "name": "Beer_styles",
        "value": "Radler"
      },
      {
        "id": 6655628,
        "name": "Price_Metadata_2011004",
        "value": "{\"current_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.36}],\"total_price\":3.25},\"previous_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.36}],\"total_price\":3.25},\"on_sale\":\"N\"}"
      },
      {
        "id": 6655629,
        "name": "Price_Metadata_2011041",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":8.42}],\"total_price\":75.60},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":8.42}],\"total_price\":75.60},\"on_sale\":\"N\"}"
      },
      {
        "id": 6655631,
        "name": "product_image_1",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbdaw0-1pypk0-bnt5ax/4852.png?position=1"
      },
      {
        "id": 6655632,
        "name": "product_image_2",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbdaw0-1pypk0-bnt5ax/4852.png?position=2"
      },
      {
        "id": 6655633,
        "name": "container_type ",
        "value": "C"
      },
      {
        "id": 6655635,
        "name": "pack",
        "value": "1,24"
      },
      {
        "id": 12482438,
        "name": "Price_Metadata_2011272",
        "value": "{\"current_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.31}],\"total_price\":2.80},\"previous_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.00}],\"total_price\":0.00},\"on_sale\":\"N\"}"
      }
    ]
  },
  {
    "id": 462557,
    "name": "ace-hill-pilsner",
    "type": "physical",
    "sku": "4850_2405",
    "description": "The Ace Hill Pilsner is a perfectly simple premium beer. With modern good looks, award-winning quality and a smooth, easy-drinking taste, it's a beer that stands out anytime, anywhere. Proudly brewed locally in Ontario.",
    "weight": 1,
    "width": 0,
    "depth": 0,
    "height": 0,
    "price": 34.95,
    "cost_price": 0,
    "retail_price": 0,
    "sale_price": 0,
    "map_price": 0,
    "tax_class_id": 0,
    "product_tax_code": "",
    "calculated_price": 34.95,
    "categories": [162, 198, 169, 180, 165, 170, 181, 171, 167, 166],
    "brand_id": 1030,
    "option_set_id": 462457,
    "option_set_display": "right",
    "inventory_level": 89,
    "inventory_warning_level": 0,
    "inventory_tracking": "variant",
    "reviews_rating_sum": 0,
    "reviews_count": 0,
    "total_sold": 0,
    "fixed_cost_shipping_price": 0,
    "is_free_shipping": false,
    "is_visible": true,
    "is_featured": false,
    "related_products": [-1],
    "warranty": "",
    "bin_picking_number": "",
    "layout_file": "",
    "upc": "",
    "mpn": "",
    "gtin": "",
    "search_keywords": "",
    "availability": "available",
    "availability_description": "",
    "gift_wrapping_options_type": "any",
    "gift_wrapping_options_list": [],
    "sort_order": 0,
    "condition": "New",
    "is_condition_shown": false,
    "order_quantity_minimum": 0,
    "order_quantity_maximum": 0,
    "page_title": "ACE HILL PILSNER~2405",
    "meta_keywords": [],
    "meta_description": "",
    "date_created": "2020-10-16T13:25:00+00:00",
    "date_modified": "2021-12-22T11:25:14+00:00",
    "view_count": 0,
    "preorder_release_date": null,
    "preorder_message": "",
    "is_preorder_only": false,
    "is_price_hidden": false,
    "price_hidden_label": "",
    "custom_url": {
      "url": "/ace-hill-pilsner-2405/",
      "is_customized": false
    },
    "base_variant_id": null,
    "open_graph_type": "product",
    "open_graph_title": "",
    "open_graph_description": "",
    "open_graph_use_meta_description": true,
    "open_graph_use_product_name": true,
    "open_graph_use_image": true,
    "variants": [
      {
        "id": 2289677,
        "product_id": 462557,
        "sku": "1533004_2405",
        "sku_id": 1827284,
        "price": 3.25,
        "calculated_price": 3.25,
        "sale_price": 3.25,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 89,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828486,
            "label": "1 X Can 473 ml",
            "option_id": 462553,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2289686,
        "product_id": 462557,
        "sku": "1533028_2405",
        "sku_id": 1827292,
        "price": 205.65,
        "calculated_price": 205.65,
        "sale_price": 205.65,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828494,
            "label": "1 X Keg 30000 ml",
            "option_id": 462553,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2289698,
        "product_id": 462557,
        "sku": "1533038_2405",
        "sku_id": 1827301,
        "price": 17.95,
        "calculated_price": 17.95,
        "sale_price": 17.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828503,
            "label": "6 X Can 473 ml",
            "option_id": 462553,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2289708,
        "product_id": 462557,
        "sku": "1533041_2405",
        "sku_id": 1827309,
        "price": 68,
        "calculated_price": 68,
        "sale_price": 68,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828511,
            "label": "24 X Can 473 ml",
            "option_id": 462553,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2289714,
        "product_id": 462557,
        "sku": "1533042_2405",
        "sku_id": 1827314,
        "price": 34.95,
        "calculated_price": 34.95,
        "sale_price": 34.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828517,
            "label": "12 X Can 473 ml",
            "option_id": 462553,
            "option_display_name": "Beer"
          }
        ]
      }
    ],
    "images": [],
    "primary_image": null,
    "custom_fields": [
      {
        "id": 6655624,
        "name": "ABV",
        "value": "4.90"
      },
      {
        "id": 6655630,
        "name": "Country",
        "value": "CA"
      },
      {
        "id": 6655636,
        "name": "Category",
        "value": "Ontario Craft"
      },
      {
        "id": 6655638,
        "name": "Type",
        "value": "Lager"
      },
      {
        "id": 6655640,
        "name": "Beverage_type",
        "value": "Beer"
      },
      {
        "id": 6655643,
        "name": "Producer",
        "value": "ACE HILL BEER COMPANY INC."
      },
      {
        "id": 6655645,
        "name": "Price_Metadata_1533004",
        "value": "{\"current_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.36}],\"total_price\":3.25},\"previous_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.36}],\"total_price\":3.25},\"on_sale\":\"N\"}"
      },
      {
        "id": 6655647,
        "name": "Price_Metadata_1533028",
        "value": "{\"current_price\":{\"deposit\":50.00,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":17.91}],\"total_price\":205.65},\"previous_price\":{\"deposit\":50.00,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":17.91}],\"total_price\":205.65},\"on_sale\":\"N\"}"
      },
      {
        "id": 6655649,
        "name": "Price_Metadata_1533038",
        "value": "{\"current_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.00}],\"total_price\":17.95},\"previous_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.00}],\"total_price\":17.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6655651,
        "name": "Price_Metadata_1533041",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":7.55}],\"total_price\":68.00},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":7.55}],\"total_price\":68.00},\"on_sale\":\"N\"}"
      },
      {
        "id": 6655654,
        "name": "Price_Metadata_1533042",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.88}],\"total_price\":34.95},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.88}],\"total_price\":34.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6655656,
        "name": "product_image_1",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbdaw0-1pypk0-6nhkas/4850.png?position=1"
      },
      {
        "id": 6655660,
        "name": "product_image_2",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbdaw0-1pypk0-6nhkas/4850.png?position=2"
      },
      {
        "id": 6655663,
        "name": "container_type ",
        "value": "C,K"
      },
      {
        "id": 6655666,
        "name": "pack",
        "value": "1,6,24,12"
      }
    ]
  },
  {
    "id": 462559,
    "name": "ace-hill-vienna-lager",
    "type": "physical",
    "sku": "4851_2405",
    "description": "An easy-drinking Vienna-style amber lager with slow roasted caramel malts and a fine balance of character and smoothness.",
    "weight": 1,
    "width": 0,
    "depth": 0,
    "height": 0,
    "price": 34.95,
    "cost_price": 0,
    "retail_price": 0,
    "sale_price": 0,
    "map_price": 0,
    "tax_class_id": 0,
    "product_tax_code": "",
    "calculated_price": 34.95,
    "categories": [162, 198, 169, 200, 180, 165, 170, 181, 171, 167, 166],
    "brand_id": 1030,
    "option_set_id": 462456,
    "option_set_display": "right",
    "inventory_level": 82,
    "inventory_warning_level": 0,
    "inventory_tracking": "variant",
    "reviews_rating_sum": 0,
    "reviews_count": 0,
    "total_sold": 24,
    "fixed_cost_shipping_price": 0,
    "is_free_shipping": false,
    "is_visible": true,
    "is_featured": false,
    "related_products": [-1],
    "warranty": "",
    "bin_picking_number": "",
    "layout_file": "",
    "upc": "",
    "mpn": "",
    "gtin": "",
    "search_keywords": "",
    "availability": "available",
    "availability_description": "",
    "gift_wrapping_options_type": "any",
    "gift_wrapping_options_list": [],
    "sort_order": 0,
    "condition": "New",
    "is_condition_shown": false,
    "order_quantity_minimum": 0,
    "order_quantity_maximum": 0,
    "page_title": "ACE HILL VIENNA LAGER~2405",
    "meta_keywords": [],
    "meta_description": "",
    "date_created": "2020-10-16T13:25:00+00:00",
    "date_modified": "2021-12-22T19:20:47+00:00",
    "view_count": 0,
    "preorder_release_date": null,
    "preorder_message": "",
    "is_preorder_only": false,
    "is_price_hidden": false,
    "price_hidden_label": "",
    "custom_url": {
      "url": "/ace-hill-vienna-lager-2405/",
      "is_customized": false
    },
    "base_variant_id": null,
    "open_graph_type": "product",
    "open_graph_title": "",
    "open_graph_description": "",
    "open_graph_use_meta_description": true,
    "open_graph_use_product_name": true,
    "open_graph_use_image": true,
    "variants": [
      {
        "id": 2289675,
        "product_id": 462559,
        "sku": "1762004_2405",
        "sku_id": 1827283,
        "price": 3.25,
        "calculated_price": 3.25,
        "sale_price": 3.25,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 82,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828485,
            "label": "1 X Can 473 ml",
            "option_id": 462552,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2289691,
        "product_id": 462559,
        "sku": "1762028_2405",
        "sku_id": 1827294,
        "price": 205.65,
        "calculated_price": 205.65,
        "sale_price": 205.65,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828496,
            "label": "1 X Keg 30000 ml",
            "option_id": 462552,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2289700,
        "product_id": 462559,
        "sku": "1762038_2405",
        "sku_id": 1827303,
        "price": 17.95,
        "calculated_price": 17.95,
        "sale_price": 17.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828505,
            "label": "6 X Can 473 ml",
            "option_id": 462552,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2289711,
        "product_id": 462559,
        "sku": "1762041_2405",
        "sku_id": 1827311,
        "price": 68,
        "calculated_price": 68,
        "sale_price": 68,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828514,
            "label": "24 X Can 473 ml",
            "option_id": 462552,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2289717,
        "product_id": 462559,
        "sku": "1762042_2405",
        "sku_id": 1827317,
        "price": 34.95,
        "calculated_price": 34.95,
        "sale_price": 34.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828519,
            "label": "12 X Can 473 ml",
            "option_id": 462552,
            "option_display_name": "Beer"
          }
        ]
      }
    ],
    "images": [],
    "primary_image": null,
    "custom_fields": [
      {
        "id": 6655634,
        "name": "ABV",
        "value": "5.00"
      },
      {
        "id": 6655637,
        "name": "Country",
        "value": "CA"
      },
      {
        "id": 6655639,
        "name": "Category",
        "value": "Ontario Craft"
      },
      {
        "id": 6655641,
        "name": "Type",
        "value": "Lager"
      },
      {
        "id": 6655642,
        "name": "Beverage_type",
        "value": "Beer"
      },
      {
        "id": 6655644,
        "name": "Producer",
        "value": "ACE HILL BEER COMPANY INC."
      },
      {
        "id": 6655646,
        "name": "Beer_styles",
        "value": "Amber"
      },
      {
        "id": 6655648,
        "name": "Price_Metadata_1762004",
        "value": "{\"current_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.36}],\"total_price\":3.25},\"previous_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.36}],\"total_price\":3.25},\"on_sale\":\"N\"}"
      },
      {
        "id": 6655650,
        "name": "Price_Metadata_1762028",
        "value": "{\"current_price\":{\"deposit\":50.00,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":17.91}],\"total_price\":205.65},\"previous_price\":{\"deposit\":50.00,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":17.91}],\"total_price\":205.65},\"on_sale\":\"N\"}"
      },
      {
        "id": 6655652,
        "name": "Price_Metadata_1762038",
        "value": "{\"current_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.00}],\"total_price\":17.95},\"previous_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.00}],\"total_price\":17.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6655653,
        "name": "Price_Metadata_1762041",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":7.55}],\"total_price\":68.00},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":7.55}],\"total_price\":68.00},\"on_sale\":\"N\"}"
      },
      {
        "id": 6655655,
        "name": "Price_Metadata_1762042",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.88}],\"total_price\":34.95},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.88}],\"total_price\":34.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6655657,
        "name": "product_image_1",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbdaw0-1pypk0-51g0co/4851.png?position=1"
      },
      {
        "id": 6655659,
        "name": "product_image_2",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbdaw0-1pypk0-51g0co/4851.png?position=2"
      },
      {
        "id": 6655662,
        "name": "container_type ",
        "value": "C,K"
      },
      {
        "id": 6655665,
        "name": "pack",
        "value": "1,6,24,12"
      }
    ]
  },
  {
    "id": 461974,
    "name": "alexander-keiths-ipa",
    "type": "physical",
    "sku": "512_2405",
    "description": "Brewed in Halifax since 1820, India Pale Ale is light in colour and hopped in flavour. Only the lightest and finest barleys that produce a pale malt are used while the amounts of hops are increased so as to give a pronounced hop flavour.",
    "weight": 1,
    "width": 0,
    "depth": 0,
    "height": 0,
    "price": 98.25,
    "cost_price": 0,
    "retail_price": 0,
    "sale_price": 0,
    "map_price": 0,
    "tax_class_id": 0,
    "product_tax_code": "",
    "calculated_price": 98.25,
    "categories": [
      162, 163, 178, 200, 199, 179, 180, 165, 175, 202, 176, 170, 177, 181,
      171, 166, 167, 172, 203, 204, 205
    ],
    "brand_id": 1030,
    "option_set_id": 461872,
    "option_set_display": "right",
    "inventory_level": 492,
    "inventory_warning_level": 0,
    "inventory_tracking": "variant",
    "reviews_rating_sum": 0,
    "reviews_count": 0,
    "total_sold": 2,
    "fixed_cost_shipping_price": 0,
    "is_free_shipping": false,
    "is_visible": true,
    "is_featured": false,
    "related_products": [-1],
    "warranty": "",
    "bin_picking_number": "",
    "layout_file": "",
    "upc": "",
    "mpn": "",
    "gtin": "",
    "search_keywords": "",
    "availability": "available",
    "availability_description": "",
    "gift_wrapping_options_type": "any",
    "gift_wrapping_options_list": [],
    "sort_order": 0,
    "condition": "New",
    "is_condition_shown": false,
    "order_quantity_minimum": 0,
    "order_quantity_maximum": 0,
    "page_title": "ALEXANDER KEITHS IPA~2405",
    "meta_keywords": [],
    "meta_description": "",
    "date_created": "2020-10-16T13:24:42+00:00",
    "date_modified": "2021-12-22T19:20:50+00:00",
    "view_count": 0,
    "preorder_release_date": null,
    "preorder_message": "",
    "is_preorder_only": false,
    "is_price_hidden": false,
    "price_hidden_label": "",
    "custom_url": {
      "url": "/alexander-keiths-ipa-2405/",
      "is_customized": false
    },
    "base_variant_id": null,
    "open_graph_type": "product",
    "open_graph_title": "",
    "open_graph_description": "",
    "open_graph_use_meta_description": true,
    "open_graph_use_product_name": true,
    "open_graph_use_image": true,
    "variants": [
      {
        "id": 2286686,
        "product_id": 461974,
        "sku": "1026001_2405",
        "sku_id": 1824880,
        "price": 14.95,
        "calculated_price": 14.95,
        "sale_price": 14.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 45,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826080,
            "label": "6 X Bottle 341 ml",
            "option_id": 461968,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286695,
        "product_id": 461974,
        "sku": "1026002_2405",
        "sku_id": 1824887,
        "price": 27.95,
        "calculated_price": 27.95,
        "sale_price": 27.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826090,
            "label": "12 X Bottle 341 ml",
            "option_id": 461968,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286709,
        "product_id": 461974,
        "sku": "1026004_2405",
        "sku_id": 1824897,
        "price": 2.95,
        "calculated_price": 2.95,
        "sale_price": 2.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 418,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826098,
            "label": "1 X Can 473 ml",
            "option_id": 461968,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286720,
        "product_id": 461974,
        "sku": "1026005_2405",
        "sku_id": 1824908,
        "price": 43.5,
        "calculated_price": 43.5,
        "sale_price": 43.5,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826110,
            "label": "24 X Bottle 341 ml",
            "option_id": 461968,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286739,
        "product_id": 461974,
        "sku": "1026027_2405",
        "sku_id": 1824924,
        "price": 363.95,
        "calculated_price": 363.95,
        "sale_price": 363.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826126,
            "label": "1 X Keg 58600 ml",
            "option_id": 461968,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286762,
        "product_id": 461974,
        "sku": "1026030_2405",
        "sku_id": 1824943,
        "price": 15.25,
        "calculated_price": 15.25,
        "sale_price": 15.25,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826144,
            "label": "6 X Can 355 ml",
            "option_id": 461968,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286779,
        "product_id": 461974,
        "sku": "1026031_2405",
        "sku_id": 1824957,
        "price": 30.5,
        "calculated_price": 30.5,
        "sale_price": 30.5,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826159,
            "label": "12 X Can 355 ml",
            "option_id": 461968,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286797,
        "product_id": 461974,
        "sku": "1026033_2405",
        "sku_id": 1824975,
        "price": 48.95,
        "calculated_price": 48.95,
        "sale_price": 48.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826177,
            "label": "24 X Can 355 ml",
            "option_id": 461968,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286813,
        "product_id": 461974,
        "sku": "1026041_2405",
        "sku_id": 1824989,
        "price": 61.95,
        "calculated_price": 61.95,
        "sale_price": 61.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826191,
            "label": "24 X Can 473 ml",
            "option_id": 461968,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286829,
        "product_id": 461974,
        "sku": "1026042_2405",
        "sku_id": 1825004,
        "price": 33.5,
        "calculated_price": 33.5,
        "sale_price": 33.5,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826207,
            "label": "12 X Can 473 ml",
            "option_id": 461968,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286840,
        "product_id": 461974,
        "sku": "1026091_2405",
        "sku_id": 1825014,
        "price": 147.25,
        "calculated_price": 147.25,
        "sale_price": 147.25,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826218,
            "label": "1 X Keg 20000 ml",
            "option_id": 461968,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286865,
        "product_id": 461974,
        "sku": "1026097_2405",
        "sku_id": 1825034,
        "price": 11.6,
        "calculated_price": 11.6,
        "sale_price": 11.6,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826234,
            "label": "4 X Can 473 ml",
            "option_id": 461968,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286881,
        "product_id": 461974,
        "sku": "1026100_2405",
        "sku_id": 1825049,
        "price": 48.5,
        "calculated_price": 46.5,
        "sale_price": 46.5,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 10,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826251,
            "label": "28 X Bottle 341 ml",
            "option_id": 461968,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286901,
        "product_id": 461974,
        "sku": "1026160_2405",
        "sku_id": 1825069,
        "price": 30.95,
        "calculated_price": 30.95,
        "sale_price": 30.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 19,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826270,
            "label": "15 X Bottle 341 ml",
            "option_id": 461968,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286919,
        "product_id": 461974,
        "sku": "1026211_2405",
        "sku_id": 1825084,
        "price": 98.25,
        "calculated_price": 98.25,
        "sale_price": 98.25,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826286,
            "label": "48 X Can 355 ml",
            "option_id": 461968,
            "option_display_name": "Beer"
          }
        ]
      }
    ],
    "images": [],
    "primary_image": null,
    "custom_fields": [
      {
        "id": 6647017,
        "name": "ABV",
        "value": "5.00"
      },
      {
        "id": 6647018,
        "name": "Country",
        "value": "CA"
      },
      {
        "id": 6647019,
        "name": "Category",
        "value": "Domestic Specialty"
      },
      {
        "id": 6647020,
        "name": "Type",
        "value": "Ale"
      },
      {
        "id": 6647021,
        "name": "Beverage_type",
        "value": "Beer"
      },
      {
        "id": 6647022,
        "name": "Producer",
        "value": "KEITHS BREWERY"
      },
      {
        "id": 6647023,
        "name": "Beer_styles",
        "value": "Amber,India Pale Ale"
      },
      {
        "id": 6647024,
        "name": "Price_Metadata_1026001",
        "value": "{\"current_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.65}],\"total_price\":14.95},\"previous_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.60}],\"total_price\":14.50},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647025,
        "name": "Price_Metadata_1026002",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.08}],\"total_price\":27.95},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.03}],\"total_price\":27.50},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647026,
        "name": "Price_Metadata_1026004",
        "value": "{\"current_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.33}],\"total_price\":2.95},\"previous_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.32}],\"total_price\":2.90},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647027,
        "name": "Price_Metadata_1026005",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":4.73}],\"total_price\":43.50},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":4.43}],\"total_price\":40.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647028,
        "name": "Price_Metadata_1026027",
        "value": "{\"current_price\":{\"deposit\":50.00,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":36.12}],\"total_price\":363.95},\"previous_price\":{\"deposit\":50.00,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":35.54}],\"total_price\":358.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647029,
        "name": "Price_Metadata_1026030",
        "value": "{\"current_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.69}],\"total_price\":15.25},\"previous_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.65}],\"total_price\":14.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647030,
        "name": "Price_Metadata_1026031",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.37}],\"total_price\":30.50},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.37}],\"total_price\":30.50},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647031,
        "name": "Price_Metadata_1026033",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.36}],\"total_price\":48.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.13}],\"total_price\":46.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647032,
        "name": "Price_Metadata_1026041",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":6.85}],\"total_price\":61.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":6.74}],\"total_price\":60.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647033,
        "name": "Price_Metadata_1026042",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.72}],\"total_price\":33.50},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.60}],\"total_price\":32.50},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647034,
        "name": "Price_Metadata_1026091",
        "value": "{\"current_price\":{\"deposit\":20.00,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":14.64}],\"total_price\":147.25},\"previous_price\":{\"deposit\":20.00,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":14.29}],\"total_price\":144.25},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647035,
        "name": "Price_Metadata_1026097",
        "value": "{\"current_price\":{\"deposit\":0.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.29}],\"total_price\":11.60},\"previous_price\":{\"deposit\":0.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.27}],\"total_price\":11.40},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647036,
        "name": "Price_Metadata_1026100",
        "value": "{\"current_price\":{\"deposit\":2.80,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.03}],\"total_price\":46.50},\"previous_price\":{\"deposit\":2.80,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.26}],\"total_price\":48.50},\"on_sale\":\"Y\"}"
      },
      {
        "id": 6647037,
        "name": "Price_Metadata_1026160",
        "value": "{\"current_price\":{\"deposit\":1.50,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.39}],\"total_price\":30.95},\"previous_price\":{\"deposit\":1.50,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.34}],\"total_price\":30.50},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647038,
        "name": "Price_Metadata_1026211",
        "value": "{\"current_price\":{\"deposit\":4.80,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":10.75}],\"total_price\":98.25},\"previous_price\":{\"deposit\":4.80,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":10.64}],\"total_price\":97.25},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647039,
        "name": "product_image_1",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbgbe1-3vkh68-7k9fes/0512_0.png?position=1"
      },
      {
        "id": 6647040,
        "name": "product_image_2",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbgbe1-3vkh68-7k9fes/0512_0.png?position=2"
      },
      {
        "id": 6647041,
        "name": "container_type ",
        "value": "B,C,K"
      },
      {
        "id": 6647042,
        "name": "pack",
        "value": "6,12,1,24,4,28,15,48"
      }
    ]
  },
  {
    "id": 461973,
    "name": "alexander-keiths-red",
    "type": "physical",
    "sku": "507_2405",
    "description": "Keith's Red is brewed in Halifax and is a smooth, well-balanced ale that is brewed to the exact standards first laid out by Alexander Keith 185 years ago. With its dark amber color and rich red overtones, Keith's Red offers a specialty beer experience.",
    "weight": 1,
    "width": 0,
    "depth": 0,
    "height": 0,
    "price": 11.6,
    "cost_price": 0,
    "retail_price": 0,
    "sale_price": 0,
    "map_price": 0,
    "tax_class_id": 0,
    "product_tax_code": "",
    "calculated_price": 11.6,
    "categories": [
      162, 163, 178, 201, 200, 180, 165, 175, 176, 170, 177, 181, 171, 167,
      166, 172
    ],
    "brand_id": 1030,
    "option_set_id": 461871,
    "option_set_display": "right",
    "inventory_level": 69,
    "inventory_warning_level": 0,
    "inventory_tracking": "variant",
    "reviews_rating_sum": 0,
    "reviews_count": 0,
    "total_sold": 0,
    "fixed_cost_shipping_price": 0,
    "is_free_shipping": false,
    "is_visible": true,
    "is_featured": false,
    "related_products": [-1],
    "warranty": "",
    "bin_picking_number": "",
    "layout_file": "",
    "upc": "",
    "mpn": "",
    "gtin": "",
    "search_keywords": "",
    "availability": "available",
    "availability_description": "",
    "gift_wrapping_options_type": "any",
    "gift_wrapping_options_list": [],
    "sort_order": 0,
    "condition": "New",
    "is_condition_shown": false,
    "order_quantity_minimum": 0,
    "order_quantity_maximum": 0,
    "page_title": "ALEXANDER KEITHS RED~2405",
    "meta_keywords": [],
    "meta_description": "",
    "date_created": "2020-10-16T13:24:42+00:00",
    "date_modified": "2021-12-21T17:18:22+00:00",
    "view_count": 0,
    "preorder_release_date": null,
    "preorder_message": "",
    "is_preorder_only": false,
    "is_price_hidden": false,
    "price_hidden_label": "",
    "custom_url": {
      "url": "/alexander-keiths-red-2405/",
      "is_customized": false
    },
    "base_variant_id": null,
    "open_graph_type": "product",
    "open_graph_title": "",
    "open_graph_description": "",
    "open_graph_use_meta_description": true,
    "open_graph_use_product_name": true,
    "open_graph_use_image": true,
    "variants": [
      {
        "id": 2286673,
        "product_id": 461973,
        "sku": "1025002_2405",
        "sku_id": 1824868,
        "price": 27.95,
        "calculated_price": 27.95,
        "sale_price": 27.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826070,
            "label": "12 X Bottle 341 ml",
            "option_id": 461967,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286681,
        "product_id": 461973,
        "sku": "1025004_2405",
        "sku_id": 1824875,
        "price": 2.95,
        "calculated_price": 2.95,
        "sale_price": 2.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 69,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826077,
            "label": "1 X Can 473 ml",
            "option_id": 461967,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286687,
        "product_id": 461973,
        "sku": "1025005_2405",
        "sku_id": 1824881,
        "price": 43.5,
        "calculated_price": 43.5,
        "sale_price": 43.5,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826083,
            "label": "24 X Bottle 341 ml",
            "option_id": 461967,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286697,
        "product_id": 461973,
        "sku": "1025027_2405",
        "sku_id": 1824889,
        "price": 356.95,
        "calculated_price": 356.95,
        "sale_price": 356.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826092,
            "label": "1 X Keg 58600 ml",
            "option_id": 461967,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286708,
        "product_id": 461973,
        "sku": "1025038_2405",
        "sku_id": 1824896,
        "price": 16.95,
        "calculated_price": 16.95,
        "sale_price": 16.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826099,
            "label": "6 X Can 473 ml",
            "option_id": 461967,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286719,
        "product_id": 461973,
        "sku": "1025041_2405",
        "sku_id": 1824907,
        "price": 61.95,
        "calculated_price": 61.95,
        "sale_price": 61.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826109,
            "label": "24 X Can 473 ml",
            "option_id": 461967,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286734,
        "product_id": 461973,
        "sku": "1025042_2405",
        "sku_id": 1824920,
        "price": 33.5,
        "calculated_price": 33.5,
        "sale_price": 33.5,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826122,
            "label": "12 X Can 473 ml",
            "option_id": 461967,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286750,
        "product_id": 461973,
        "sku": "1025097_2405",
        "sku_id": 1824932,
        "price": 11.6,
        "calculated_price": 11.6,
        "sale_price": 11.6,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826135,
            "label": "4 X Can 473 ml",
            "option_id": 461967,
            "option_display_name": "Beer"
          }
        ]
      }
    ],
    "images": [],
    "primary_image": null,
    "custom_fields": [
      {
        "id": 6646998,
        "name": "ABV",
        "value": "5.00"
      },
      {
        "id": 6646999,
        "name": "Country",
        "value": "CA"
      },
      {
        "id": 6647000,
        "name": "Category",
        "value": "Domestic Specialty"
      },
      {
        "id": 6647001,
        "name": "Type",
        "value": "Ale"
      },
      {
        "id": 6647002,
        "name": "Beverage_type",
        "value": "Beer"
      },
      {
        "id": 6647003,
        "name": "Producer",
        "value": "KEITHS BREWERY"
      },
      {
        "id": 6647004,
        "name": "Beer_styles",
        "value": "Red,Amber"
      },
      {
        "id": 6647005,
        "name": "Price_Metadata_1025002",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.08}],\"total_price\":27.95},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.03}],\"total_price\":27.50},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647006,
        "name": "Price_Metadata_1025004",
        "value": "{\"current_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.33}],\"total_price\":2.95},\"previous_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.32}],\"total_price\":2.90},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647007,
        "name": "Price_Metadata_1025005",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":4.73}],\"total_price\":43.50},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":4.67}],\"total_price\":42.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647008,
        "name": "Price_Metadata_1025027",
        "value": "{\"current_price\":{\"deposit\":50.00,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":35.31}],\"total_price\":356.95},\"previous_price\":{\"deposit\":50.00,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":34.74}],\"total_price\":351.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647009,
        "name": "Price_Metadata_1025038",
        "value": "{\"current_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.88}],\"total_price\":16.95},\"previous_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.83}],\"total_price\":16.50},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647010,
        "name": "Price_Metadata_1025041",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":6.85}],\"total_price\":61.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":6.74}],\"total_price\":60.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647011,
        "name": "Price_Metadata_1025042",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.72}],\"total_price\":33.50},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.60}],\"total_price\":32.50},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647012,
        "name": "Price_Metadata_1025097",
        "value": "{\"current_price\":{\"deposit\":0.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.29}],\"total_price\":11.60},\"previous_price\":{\"deposit\":0.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.27}],\"total_price\":11.40},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647013,
        "name": "product_image_1",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbgbfp-6588yo-xhuyq/0507_0.png?position=1"
      },
      {
        "id": 6647014,
        "name": "product_image_2",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbgbfp-6588yo-xhuyq/0507_0.png?position=2"
      },
      {
        "id": 6647015,
        "name": "container_type ",
        "value": "B,C,K"
      },
      {
        "id": 6647016,
        "name": "pack",
        "value": "12,1,24,6,4"
      }
    ]
  },
  {
    "id": 462643,
    "name": "alpine-lager",
    "type": "physical",
    "sku": "7357_2405",
    "description": "Get outside and reconnect, with a beer that's as real as it gets. This classic lager is light-coloured, medium-bodied, and brewed with Styrian Golding and Nugget hops. ",
    "weight": 1,
    "width": 0,
    "depth": 0,
    "height": 0,
    "price": 39.95,
    "cost_price": 0,
    "retail_price": 0,
    "sale_price": 0,
    "map_price": 0,
    "tax_class_id": 0,
    "product_tax_code": "",
    "calculated_price": 39.95,
    "categories": [162, 168, 169, 165, 175, 177, 167],
    "brand_id": 1030,
    "option_set_id": 462539,
    "option_set_display": "right",
    "inventory_level": 129,
    "inventory_warning_level": 0,
    "inventory_tracking": "variant",
    "reviews_rating_sum": 0,
    "reviews_count": 0,
    "total_sold": 8,
    "fixed_cost_shipping_price": 0,
    "is_free_shipping": false,
    "is_visible": true,
    "is_featured": false,
    "related_products": [-1],
    "warranty": "",
    "bin_picking_number": "",
    "layout_file": "",
    "upc": "",
    "mpn": "",
    "gtin": "",
    "search_keywords": "",
    "availability": "available",
    "availability_description": "",
    "gift_wrapping_options_type": "any",
    "gift_wrapping_options_list": [],
    "sort_order": 0,
    "condition": "New",
    "is_condition_shown": false,
    "order_quantity_minimum": 0,
    "order_quantity_maximum": 0,
    "page_title": "ALPINE LAGER~2405",
    "meta_keywords": [],
    "meta_description": "",
    "date_created": "2020-10-16T13:25:03+00:00",
    "date_modified": "2021-12-22T23:24:22+00:00",
    "view_count": 0,
    "preorder_release_date": null,
    "preorder_message": "",
    "is_preorder_only": false,
    "is_price_hidden": false,
    "price_hidden_label": "",
    "custom_url": {
      "url": "/alpine-lager-2405/",
      "is_customized": false
    },
    "base_variant_id": null,
    "open_graph_type": "product",
    "open_graph_title": "",
    "open_graph_description": "",
    "open_graph_use_meta_description": true,
    "open_graph_use_product_name": true,
    "open_graph_use_image": true,
    "variants": [
      {
        "id": 2290057,
        "product_id": 462643,
        "sku": "1632005_2405",
        "sku_id": 1827582,
        "price": 39.5,
        "calculated_price": 39.5,
        "sale_price": 39.5,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828784,
            "label": "24 X Bottle 341 ml",
            "option_id": 462635,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290072,
        "product_id": 462643,
        "sku": "1632033_2405",
        "sku_id": 1827594,
        "price": 39.95,
        "calculated_price": 39.95,
        "sale_price": 39.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 22,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828797,
            "label": "24 X Can 355 ml",
            "option_id": 462635,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 3902585,
        "product_id": 462643,
        "sku": "1632042_2405",
        "sku_id": 3112323,
        "price": 25.5,
        "calculated_price": 25.5,
        "sale_price": 25.5,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": true,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 3113519,
            "label": "12 X Can 473 ml",
            "option_id": 462635,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 3902599,
        "product_id": 462643,
        "sku": "1632041_2405",
        "sku_id": 3112337,
        "price": 48.95,
        "calculated_price": 48.95,
        "sale_price": 48.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": true,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 3113527,
            "label": "24 X Can 473 ml",
            "option_id": 462635,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 3902613,
        "product_id": 462643,
        "sku": "1632038_2405",
        "sku_id": 3112351,
        "price": 12.95,
        "calculated_price": 12.95,
        "sale_price": 12.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": true,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 3113541,
            "label": "6 X Can 473 ml",
            "option_id": 462635,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 3902625,
        "product_id": 462643,
        "sku": "1632004_2405",
        "sku_id": 3112363,
        "price": 2.25,
        "calculated_price": 2.25,
        "sale_price": 2.25,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": true,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 107,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 3113553,
            "label": "1 X Can 473 ml",
            "option_id": 462635,
            "option_display_name": "Beer"
          }
        ]
      }
    ],
    "images": [],
    "primary_image": null,
    "custom_fields": [
      {
        "id": 6656841,
        "name": "ABV",
        "value": "5.00"
      },
      {
        "id": 6656844,
        "name": "Country",
        "value": "CA"
      },
      {
        "id": 6656846,
        "name": "Category",
        "value": "Value"
      },
      {
        "id": 6656847,
        "name": "Type",
        "value": "Lager"
      },
      {
        "id": 6656849,
        "name": "Beverage_type",
        "value": "Beer"
      },
      {
        "id": 6656853,
        "name": "Producer",
        "value": "MOOSEHEAD BREWERIES LIMITED"
      },
      {
        "id": 6656856,
        "name": "Price_Metadata_1632005",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":4.27}],\"total_price\":39.50},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":4.15}],\"total_price\":38.50},\"on_sale\":\"N\"}"
      },
      {
        "id": 6656859,
        "name": "Price_Metadata_1632033",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":4.32}],\"total_price\":39.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":4.29}],\"total_price\":39.70},\"on_sale\":\"N\"}"
      },
      {
        "id": 6656861,
        "name": "product_image_1",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbfupy-9yrnqg-28osxf/7357.png?position=1"
      },
      {
        "id": 6656887,
        "name": "product_image_2",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbfupy-9yrnqg-28osxf/7357.png?position=2"
      },
      {
        "id": 6656890,
        "name": "container_type ",
        "value": "B,C"
      },
      {
        "id": 6656893,
        "name": "pack",
        "value": "24"
      },
      {
        "id": 11431046,
        "name": "Price_Metadata_1632004",
        "value": "{\"current_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.25}],\"total_price\":2.25},\"previous_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.24}],\"total_price\":2.15},\"on_sale\":\"N\"}"
      },
      {
        "id": 11431047,
        "name": "Price_Metadata_1632038",
        "value": "{\"current_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.42}],\"total_price\":12.95},\"previous_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.39}],\"total_price\":12.70},\"on_sale\":\"N\"}"
      },
      {
        "id": 11431048,
        "name": "Price_Metadata_1632041",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.36}],\"total_price\":48.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.24}],\"total_price\":47.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 11431049,
        "name": "Price_Metadata_1632042",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.80}],\"total_price\":25.50},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.80}],\"total_price\":25.50},\"on_sale\":\"N\"}"
      }
    ]
  },
  {
    "id": 462696,
    "name": "amsterdam-3-speed-lager",
    "type": "physical",
    "sku": "7952_2405",
    "description": "Enjoy the easiest drinking Craft beer in the market. Aged 26 days to achieve ultimate smoothness and refined drinkability. Versatile & suitable for all thirst-quenching occasions!",
    "weight": 1,
    "width": 0,
    "depth": 0,
    "height": 0,
    "price": 9.85,
    "cost_price": 0,
    "retail_price": 0,
    "sale_price": 0,
    "map_price": 0,
    "tax_class_id": 0,
    "product_tax_code": "",
    "calculated_price": 9.85,
    "categories": [162, 198, 169, 179, 165, 166, 167, 171, 170, 242],
    "brand_id": 1030,
    "option_set_id": 462594,
    "option_set_display": "right",
    "inventory_level": 63,
    "inventory_warning_level": 0,
    "inventory_tracking": "variant",
    "reviews_rating_sum": 0,
    "reviews_count": 0,
    "total_sold": 7,
    "fixed_cost_shipping_price": 0,
    "is_free_shipping": false,
    "is_visible": true,
    "is_featured": false,
    "related_products": [-1],
    "warranty": "",
    "bin_picking_number": "",
    "layout_file": "",
    "upc": "",
    "mpn": "",
    "gtin": "",
    "search_keywords": "",
    "availability": "available",
    "availability_description": "",
    "gift_wrapping_options_type": "any",
    "gift_wrapping_options_list": [],
    "sort_order": 0,
    "condition": "New",
    "is_condition_shown": false,
    "order_quantity_minimum": 0,
    "order_quantity_maximum": 0,
    "page_title": "AMSTERDAM 3 SPEED LAGER~2405",
    "meta_keywords": [],
    "meta_description": "",
    "date_created": "2020-10-16T13:25:04+00:00",
    "date_modified": "2021-12-22T13:16:20+00:00",
    "view_count": 0,
    "preorder_release_date": null,
    "preorder_message": "",
    "is_preorder_only": false,
    "is_price_hidden": false,
    "price_hidden_label": "",
    "custom_url": {
      "url": "/amsterdam-3speed-lager-2405/",
      "is_customized": false
    },
    "base_variant_id": null,
    "open_graph_type": "product",
    "open_graph_title": "",
    "open_graph_description": "",
    "open_graph_use_meta_description": true,
    "open_graph_use_product_name": true,
    "open_graph_use_image": true,
    "variants": [
      {
        "id": 2290335,
        "product_id": 462696,
        "sku": "1684031_2405",
        "sku_id": 1827807,
        "price": 27.95,
        "calculated_price": 27.95,
        "sale_price": 27.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1829008,
            "label": "12 X Can 355 ml",
            "option_id": 462690,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290342,
        "product_id": 462696,
        "sku": "1684033_2405",
        "sku_id": 1827814,
        "price": 53.95,
        "calculated_price": 53.95,
        "sale_price": 53.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1829016,
            "label": "24 X Can 355 ml",
            "option_id": 462690,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290348,
        "product_id": 462696,
        "sku": "1684346_2405",
        "sku_id": 1827819,
        "price": 3.35,
        "calculated_price": 3.35,
        "sale_price": 3.35,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 60,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1829023,
            "label": "1 X Can 568 ml",
            "option_id": 462690,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290360,
        "product_id": 462696,
        "sku": "1684348_2405",
        "sku_id": 1827828,
        "price": 72.95,
        "calculated_price": 72.95,
        "sale_price": 72.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1829030,
            "label": "24 X Can 568 ml",
            "option_id": 462690,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290372,
        "product_id": 462696,
        "sku": "1684362_2405",
        "sku_id": 1827838,
        "price": 18.95,
        "calculated_price": 18.95,
        "sale_price": 18.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1829040,
            "label": "6 X Can 568 ml",
            "option_id": 462690,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290384,
        "product_id": 462696,
        "sku": "1684364_2405",
        "sku_id": 1827849,
        "price": 36.95,
        "calculated_price": 36.95,
        "sale_price": 36.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1829050,
            "label": "12 X Can 568 ml",
            "option_id": 462690,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 3963748,
        "product_id": 462696,
        "sku": "1684347_2405",
        "sku_id": 3156762,
        "price": 24.95,
        "calculated_price": 24.95,
        "sale_price": 24.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": true,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 3157977,
            "label": "8 X Can 568 ml",
            "option_id": 462690,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 3970587,
        "product_id": 462696,
        "sku": "1684371_2405",
        "sku_id": 3162699,
        "price": 9.85,
        "calculated_price": 9.85,
        "sale_price": 9.85,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 3163912,
            "label": "3 X Can 568 ml",
            "option_id": 462690,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 3970598,
        "product_id": 462696,
        "sku": "1684042_2405",
        "sku_id": 3162710,
        "price": 32.95,
        "calculated_price": 32.95,
        "sale_price": 32.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 3163920,
            "label": "12 X Can 473 ml",
            "option_id": 462690,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 3970616,
        "product_id": 462696,
        "sku": "1684038_2405",
        "sku_id": 3162728,
        "price": 16.95,
        "calculated_price": 15.95,
        "sale_price": 15.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 3,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 3163932,
            "label": "6 X Can 473 ml",
            "option_id": 462690,
            "option_display_name": "Beer"
          }
        ]
      }
    ],
    "images": [],
    "primary_image": null,
    "custom_fields": [
      {
        "id": 6657659,
        "name": "ABV",
        "value": "4.20"
      },
      {
        "id": 6657664,
        "name": "Country",
        "value": "CA"
      },
      {
        "id": 6657667,
        "name": "Category",
        "value": "Ontario Craft"
      },
      {
        "id": 6657671,
        "name": "Type",
        "value": "Lager"
      },
      {
        "id": 6657675,
        "name": "Beverage_type",
        "value": "Beer"
      },
      {
        "id": 6657678,
        "name": "Producer",
        "value": "AMSTERDAM BREWING COMPANY"
      },
      {
        "id": 6657681,
        "name": "Price_Metadata_1684031",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.08}],\"total_price\":27.95},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.08}],\"total_price\":27.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6657684,
        "name": "Price_Metadata_1684033",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.93}],\"total_price\":53.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.93}],\"total_price\":53.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6657688,
        "name": "Price_Metadata_1684346",
        "value": "{\"current_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.37}],\"total_price\":3.35},\"previous_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.34}],\"total_price\":3.05},\"on_sale\":\"N\"}"
      },
      {
        "id": 6657690,
        "name": "Price_Metadata_1684348",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":8.12}],\"total_price\":72.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":7.54}],\"total_price\":67.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6657691,
        "name": "Price_Metadata_1684362",
        "value": "{\"current_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.11}],\"total_price\":18.95},\"previous_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.11}],\"total_price\":18.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6657692,
        "name": "Price_Metadata_1684364",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":4.11}],\"total_price\":36.95},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":4.11}],\"total_price\":36.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6657693,
        "name": "product_image_1",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbdaw0-1pypk0-3v6qyk/7952.png?position=1"
      },
      {
        "id": 6657694,
        "name": "product_image_2",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbdaw0-1pypk0-3v6qyk/7952.png?position=2"
      },
      {
        "id": 6657695,
        "name": "container_type ",
        "value": "C"
      },
      {
        "id": 6657696,
        "name": "pack",
        "value": "12,24,1,6"
      },
      {
        "id": 11682829,
        "name": "Price_Metadata_1684347",
        "value": "{\"current_price\":{\"deposit\":0.80,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.78}],\"total_price\":24.95},\"previous_price\":{\"deposit\":0.80,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.00}],\"total_price\":0.00},\"on_sale\":\"N\"}"
      },
      {
        "id": 11705471,
        "name": "Price_Metadata_1684038",
        "value": "{\"current_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.77}],\"total_price\":15.95},\"previous_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.88}],\"total_price\":16.95},\"on_sale\":\"Y\"}"
      },
      {
        "id": 11705472,
        "name": "Price_Metadata_1684042",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.65}],\"total_price\":32.95},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.66}],\"total_price\":33.00},\"on_sale\":\"N\"}"
      },
      {
        "id": 11705473,
        "name": "Price_Metadata_1684371",
        "value": "{\"current_price\":{\"deposit\":0.30,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.10}],\"total_price\":9.85},\"previous_price\":{\"deposit\":0.30,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.00}],\"total_price\":0.00},\"on_sale\":\"N\"}"
      }
    ]
  },
  {
    "id": 462694,
    "name": "amsterdam-blonde",
    "type": "physical",
    "sku": "7951_2405",
    "description": "Every bottle of Natural Blonde Lager is handcrafted, cold filtered and non-pasteurized. We brew this beer fresh daily using all natural ingredients for a clean crisp taste with a smooth, mellow finish.",
    "weight": 1,
    "width": 0,
    "depth": 0,
    "height": 0,
    "price": 34.95,
    "cost_price": 0,
    "retail_price": 0,
    "sale_price": 0,
    "map_price": 0,
    "tax_class_id": 0,
    "product_tax_code": "",
    "calculated_price": 34.95,
    "categories": [
      162, 198, 169, 195, 217, 218, 165, 175, 171, 167, 166, 202, 176, 177,
      170
    ],
    "brand_id": 1030,
    "option_set_id": 462593,
    "option_set_display": "right",
    "inventory_level": 131,
    "inventory_warning_level": 0,
    "inventory_tracking": "variant",
    "reviews_rating_sum": 0,
    "reviews_count": 0,
    "total_sold": 0,
    "fixed_cost_shipping_price": 0,
    "is_free_shipping": false,
    "is_visible": true,
    "is_featured": false,
    "related_products": [-1],
    "warranty": "",
    "bin_picking_number": "",
    "layout_file": "",
    "upc": "",
    "mpn": "",
    "gtin": "",
    "search_keywords": "",
    "availability": "available",
    "availability_description": "",
    "gift_wrapping_options_type": "any",
    "gift_wrapping_options_list": [],
    "sort_order": 0,
    "condition": "New",
    "is_condition_shown": false,
    "order_quantity_minimum": 0,
    "order_quantity_maximum": 0,
    "page_title": "AMSTERDAM BLONDE~2405",
    "meta_keywords": [],
    "meta_description": "",
    "date_created": "2020-10-16T13:25:04+00:00",
    "date_modified": "2021-12-19T21:20:00+00:00",
    "view_count": 0,
    "preorder_release_date": null,
    "preorder_message": "",
    "is_preorder_only": false,
    "is_price_hidden": false,
    "price_hidden_label": "",
    "custom_url": {
      "url": "/amsterdam-natural-blonde-2405/",
      "is_customized": false
    },
    "base_variant_id": null,
    "open_graph_type": "product",
    "open_graph_title": "",
    "open_graph_description": "",
    "open_graph_use_meta_description": true,
    "open_graph_use_product_name": true,
    "open_graph_use_image": true,
    "variants": [
      {
        "id": 2290331,
        "product_id": 462694,
        "sku": "1683038_2405",
        "sku_id": 1827803,
        "price": 15.95,
        "calculated_price": 15.95,
        "sale_price": 15.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1829002,
            "label": "6 X Can 473 ml",
            "option_id": 462689,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290338,
        "product_id": 462694,
        "sku": "1683041_2405",
        "sku_id": 1827810,
        "price": 68.95,
        "calculated_price": 68.95,
        "sale_price": 68.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1829012,
            "label": "24 X Can 473 ml",
            "option_id": 462689,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290345,
        "product_id": 462694,
        "sku": "1683042_2405",
        "sku_id": 1827817,
        "price": 29.95,
        "calculated_price": 29.95,
        "sale_price": 29.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1829019,
            "label": "12 X Can 473 ml",
            "option_id": 462689,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290354,
        "product_id": 462694,
        "sku": "1683053_2405",
        "sku_id": 1827824,
        "price": 14.95,
        "calculated_price": 14.95,
        "sale_price": 14.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 7,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1829027,
            "label": "6 X Bottle 355 ml",
            "option_id": 462689,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290362,
        "product_id": 462694,
        "sku": "1683054_2405",
        "sku_id": 1827830,
        "price": 28.5,
        "calculated_price": 28.5,
        "sale_price": 28.5,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1829033,
            "label": "12 X Bottle 355 ml",
            "option_id": 462689,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290375,
        "product_id": 462694,
        "sku": "1683055_2405",
        "sku_id": 1827841,
        "price": 50.95,
        "calculated_price": 50.95,
        "sale_price": 50.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1829043,
            "label": "24 X Bottle 355 ml",
            "option_id": 462689,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290383,
        "product_id": 462694,
        "sku": "1683346_2405",
        "sku_id": 1827850,
        "price": 3.1,
        "calculated_price": 3.1,
        "sale_price": 3.1,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 124,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1829052,
            "label": "1 X Can 568 ml",
            "option_id": 462689,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290391,
        "product_id": 462694,
        "sku": "1683348_2405",
        "sku_id": 1827855,
        "price": 68.95,
        "calculated_price": 68.95,
        "sale_price": 68.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1829057,
            "label": "24 X Can 568 ml",
            "option_id": 462689,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290401,
        "product_id": 462694,
        "sku": "1683362_2405",
        "sku_id": 1827861,
        "price": 17.95,
        "calculated_price": 17.95,
        "sale_price": 17.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1829063,
            "label": "6 X Can 568 ml",
            "option_id": 462689,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290417,
        "product_id": 462694,
        "sku": "1683364_2405",
        "sku_id": 1827875,
        "price": 34.95,
        "calculated_price": 34.95,
        "sale_price": 34.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1829076,
            "label": "12 X Can 568 ml",
            "option_id": 462689,
            "option_display_name": "Beer"
          }
        ]
      }
    ],
    "images": [],
    "primary_image": null,
    "custom_fields": [
      {
        "id": 6657636,
        "name": "ABV",
        "value": "5.00"
      },
      {
        "id": 6657640,
        "name": "Country",
        "value": "CA"
      },
      {
        "id": 6657643,
        "name": "Category",
        "value": "Ontario Craft"
      },
      {
        "id": 6657644,
        "name": "Type",
        "value": "Lager"
      },
      {
        "id": 6657646,
        "name": "Beverage_type",
        "value": "Beer"
      },
      {
        "id": 6657648,
        "name": "Producer",
        "value": "AMSTERDAM BREWING COMPANY"
      },
      {
        "id": 6657650,
        "name": "Beer_attributes",
        "value": "Light"
      },
      {
        "id": 6657653,
        "name": "Beer_styles",
        "value": "Blonde,Golden"
      },
      {
        "id": 6657656,
        "name": "Price_Metadata_1683038",
        "value": "{\"current_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.77}],\"total_price\":15.95},\"previous_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.77}],\"total_price\":15.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6657658,
        "name": "Price_Metadata_1683041",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":7.66}],\"total_price\":68.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":7.20}],\"total_price\":64.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6657661,
        "name": "Price_Metadata_1683042",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.31}],\"total_price\":29.95},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.31}],\"total_price\":29.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6657663,
        "name": "Price_Metadata_1683053",
        "value": "{\"current_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.65}],\"total_price\":14.95},\"previous_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.63}],\"total_price\":14.75},\"on_sale\":\"N\"}"
      },
      {
        "id": 6657665,
        "name": "Price_Metadata_1683054",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.14}],\"total_price\":28.50},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.05}],\"total_price\":27.75},\"on_sale\":\"N\"}"
      },
      {
        "id": 6657668,
        "name": "Price_Metadata_1683055",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.59}],\"total_price\":50.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.47}],\"total_price\":49.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6657670,
        "name": "Price_Metadata_1683346",
        "value": "{\"current_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.35}],\"total_price\":3.10},\"previous_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.32}],\"total_price\":2.85},\"on_sale\":\"N\"}"
      },
      {
        "id": 6657673,
        "name": "Price_Metadata_1683348",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":7.66}],\"total_price\":68.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":7.08}],\"total_price\":63.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6657676,
        "name": "Price_Metadata_1683362",
        "value": "{\"current_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.00}],\"total_price\":17.95},\"previous_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.88}],\"total_price\":16.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6657679,
        "name": "Price_Metadata_1683364",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.88}],\"total_price\":34.95},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.65}],\"total_price\":32.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6657682,
        "name": "product_image_1",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbdaw0-1pypk0-95rvdg/7951.png?position=1"
      },
      {
        "id": 6657685,
        "name": "product_image_2",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbdaw0-1pypk0-95rvdg/7951.png?position=2"
      },
      {
        "id": 6657687,
        "name": "container_type ",
        "value": "C,B"
      },
      {
        "id": 6657689,
        "name": "pack",
        "value": "6,24,12,1"
      }
    ]
  },
  {
    "id": 462686,
    "name": "amsterdam-boneshaker-ipa",
    "type": "physical",
    "sku": "7913_2405",
    "description": "Brewed with copious amounts of fresh hops, this naturally carbonated, unfiltered IPA is loaded with citrus and pine aromas. A trulyunique taste experience.",
    "weight": 1,
    "width": 0,
    "depth": 0,
    "height": 0,
    "price": 55.95,
    "cost_price": 0,
    "retail_price": 0,
    "sale_price": 0,
    "map_price": 0,
    "tax_class_id": 0,
    "product_tax_code": "",
    "calculated_price": 55.95,
    "categories": [
      162, 198, 178, 199, 179, 165, 175, 170, 171, 167, 166, 202, 176, 177
    ],
    "brand_id": 1030,
    "option_set_id": 462584,
    "option_set_display": "right",
    "inventory_level": 219,
    "inventory_warning_level": 0,
    "inventory_tracking": "variant",
    "reviews_rating_sum": 0,
    "reviews_count": 0,
    "total_sold": 1,
    "fixed_cost_shipping_price": 0,
    "is_free_shipping": false,
    "is_visible": true,
    "is_featured": false,
    "related_products": [-1],
    "warranty": "",
    "bin_picking_number": "",
    "layout_file": "",
    "upc": "",
    "mpn": "",
    "gtin": "",
    "search_keywords": "",
    "availability": "available",
    "availability_description": "",
    "gift_wrapping_options_type": "any",
    "gift_wrapping_options_list": [],
    "sort_order": 0,
    "condition": "New",
    "is_condition_shown": false,
    "order_quantity_minimum": 0,
    "order_quantity_maximum": 0,
    "page_title": "AMSTERDAM BONESHAKER IPA~2405",
    "meta_keywords": [],
    "meta_description": "",
    "date_created": "2020-10-16T13:25:04+00:00",
    "date_modified": "2021-12-22T19:20:46+00:00",
    "view_count": 0,
    "preorder_release_date": null,
    "preorder_message": "",
    "is_preorder_only": false,
    "is_price_hidden": false,
    "price_hidden_label": "",
    "custom_url": {
      "url": "/amsterdam-boneshaker-ipa-2405/",
      "is_customized": false
    },
    "base_variant_id": null,
    "open_graph_type": "product",
    "open_graph_title": "",
    "open_graph_description": "",
    "open_graph_use_meta_description": true,
    "open_graph_use_product_name": true,
    "open_graph_use_image": true,
    "variants": [
      {
        "id": 2290285,
        "product_id": 462686,
        "sku": "1679004_2405",
        "sku_id": 1827768,
        "price": 3.55,
        "calculated_price": 3.25,
        "sale_price": 3.25,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 176,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828968,
            "label": "1 X Can 473 ml",
            "option_id": 462680,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290297,
        "product_id": 462686,
        "sku": "1679038_2405",
        "sku_id": 1827776,
        "price": 19.45,
        "calculated_price": 19.45,
        "sale_price": 19.45,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 34,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828979,
            "label": "6 X Can 473 ml",
            "option_id": 462680,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290311,
        "product_id": 462686,
        "sku": "1679041_2405",
        "sku_id": 1827786,
        "price": 73.95,
        "calculated_price": 68.95,
        "sale_price": 68.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828988,
            "label": "24 X Can 473 ml",
            "option_id": 462680,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290326,
        "product_id": 462686,
        "sku": "1679042_2405",
        "sku_id": 1827798,
        "price": 38.45,
        "calculated_price": 38.45,
        "sale_price": 38.45,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1829001,
            "label": "12 X Can 473 ml",
            "option_id": 462680,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290339,
        "product_id": 462686,
        "sku": "1679053_2405",
        "sku_id": 1827811,
        "price": 15.95,
        "calculated_price": 15.95,
        "sale_price": 15.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 9,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1829014,
            "label": "6 X Bottle 355 ml",
            "option_id": 462680,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290349,
        "product_id": 462686,
        "sku": "1679054_2405",
        "sku_id": 1827821,
        "price": 30.95,
        "calculated_price": 30.95,
        "sale_price": 30.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1829021,
            "label": "12 X Bottle 355 ml",
            "option_id": 462680,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290361,
        "product_id": 462686,
        "sku": "1679055_2405",
        "sku_id": 1827829,
        "price": 55.95,
        "calculated_price": 55.95,
        "sale_price": 55.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1829031,
            "label": "24 X Bottle 355 ml",
            "option_id": 462680,
            "option_display_name": "Beer"
          }
        ]
      }
    ],
    "images": [],
    "primary_image": null,
    "custom_fields": [
      {
        "id": 6657527,
        "name": "ABV",
        "value": "7.10"
      },
      {
        "id": 6657528,
        "name": "Country",
        "value": "CA"
      },
      {
        "id": 6657529,
        "name": "Category",
        "value": "Ontario Craft"
      },
      {
        "id": 6657530,
        "name": "Type",
        "value": "Ale"
      },
      {
        "id": 6657531,
        "name": "Beverage_type",
        "value": "Beer"
      },
      {
        "id": 6657532,
        "name": "Producer",
        "value": "AMSTERDAM BREWING COMPANY"
      },
      {
        "id": 6657533,
        "name": "Beer_styles",
        "value": "India Pale Ale"
      },
      {
        "id": 6657534,
        "name": "Price_Metadata_1679004",
        "value": "{\"current_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.36}],\"total_price\":3.25},\"previous_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.40}],\"total_price\":3.55},\"on_sale\":\"Y\"}"
      },
      {
        "id": 6657535,
        "name": "Price_Metadata_1679038",
        "value": "{\"current_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.17}],\"total_price\":19.45},\"previous_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.00}],\"total_price\":17.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6657536,
        "name": "Price_Metadata_1679041",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":7.66}],\"total_price\":68.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":8.23}],\"total_price\":73.95},\"on_sale\":\"Y\"}"
      },
      {
        "id": 6657537,
        "name": "Price_Metadata_1679042",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":4.29}],\"total_price\":38.45},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":4.23}],\"total_price\":37.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6657538,
        "name": "Price_Metadata_1679053",
        "value": "{\"current_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.77}],\"total_price\":15.95},\"previous_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.63}],\"total_price\":14.75},\"on_sale\":\"N\"}"
      },
      {
        "id": 6657539,
        "name": "Price_Metadata_1679054",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.42}],\"total_price\":30.95},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.26}],\"total_price\":29.50},\"on_sale\":\"N\"}"
      },
      {
        "id": 6657540,
        "name": "Price_Metadata_1679055",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":6.16}],\"total_price\":55.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":6.16}],\"total_price\":55.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6657541,
        "name": "product_image_1",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbdaw0-1pypk0-3ztfv/7913.png?position=1"
      },
      {
        "id": 6657542,
        "name": "product_image_2",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbdaw0-1pypk0-3ztfv/7913.png?position=2"
      },
      {
        "id": 6657543,
        "name": "container_type ",
        "value": "C,B"
      },
      {
        "id": 6657544,
        "name": "pack",
        "value": "1,6,24,12"
      }
    ]
  },
  {
    "id": 462685,
    "name": "amsterdam-downtown-brown",
    "type": "physical",
    "sku": "7911_2405",
    "description": "British style brown ale. Blend of CDN, European & Chocolate malts from Belgium & 4 continental hops. Medium bodied dark beer gets some fine character from English ale yeast for a smooth traditional ale of chestnut brown colour with distinct drinkability",
    "weight": 1,
    "width": 0,
    "depth": 0,
    "height": 0,
    "price": 35.5,
    "cost_price": 0,
    "retail_price": 0,
    "sale_price": 0,
    "map_price": 0,
    "tax_class_id": 0,
    "product_tax_code": "",
    "calculated_price": 35.5,
    "categories": [162, 198, 178, 216, 226, 165, 170, 171, 167, 166],
    "brand_id": 1030,
    "option_set_id": 462583,
    "option_set_display": "right",
    "inventory_level": 20,
    "inventory_warning_level": 0,
    "inventory_tracking": "variant",
    "reviews_rating_sum": 0,
    "reviews_count": 0,
    "total_sold": 26,
    "fixed_cost_shipping_price": 0,
    "is_free_shipping": false,
    "is_visible": true,
    "is_featured": false,
    "related_products": [-1],
    "warranty": "",
    "bin_picking_number": "",
    "layout_file": "",
    "upc": "",
    "mpn": "",
    "gtin": "",
    "search_keywords": "",
    "availability": "available",
    "availability_description": "",
    "gift_wrapping_options_type": "any",
    "gift_wrapping_options_list": [],
    "sort_order": 0,
    "condition": "New",
    "is_condition_shown": false,
    "order_quantity_minimum": 0,
    "order_quantity_maximum": 0,
    "page_title": "AMSTERDAM DOWNTOWN BROWN~2405",
    "meta_keywords": [],
    "meta_description": "",
    "date_created": "2020-10-16T13:25:04+00:00",
    "date_modified": "2021-12-20T23:23:13+00:00",
    "view_count": 0,
    "preorder_release_date": null,
    "preorder_message": "",
    "is_preorder_only": false,
    "is_price_hidden": false,
    "price_hidden_label": "",
    "custom_url": {
      "url": "/amsterdam-downtown-brown-2405/",
      "is_customized": false
    },
    "base_variant_id": null,
    "open_graph_type": "product",
    "open_graph_title": "",
    "open_graph_description": "",
    "open_graph_use_meta_description": true,
    "open_graph_use_product_name": true,
    "open_graph_use_image": true,
    "variants": [
      {
        "id": 2290278,
        "product_id": 462685,
        "sku": "1678004_2405",
        "sku_id": 1827761,
        "price": 3.25,
        "calculated_price": 3.25,
        "sale_price": 3.25,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 20,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828963,
            "label": "1 X Can 473 ml",
            "option_id": 462679,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290287,
        "product_id": 462685,
        "sku": "1678038_2405",
        "sku_id": 1827769,
        "price": 18.95,
        "calculated_price": 18.95,
        "sale_price": 18.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828972,
            "label": "6 X Can 473 ml",
            "option_id": 462679,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290296,
        "product_id": 462685,
        "sku": "1678041_2405",
        "sku_id": 1827775,
        "price": 66.95,
        "calculated_price": 66.95,
        "sale_price": 66.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828978,
            "label": "24 X Can 473 ml",
            "option_id": 462679,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290308,
        "product_id": 462685,
        "sku": "1678042_2405",
        "sku_id": 1827783,
        "price": 35.5,
        "calculated_price": 35.5,
        "sale_price": 35.5,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828985,
            "label": "12 X Can 473 ml",
            "option_id": 462679,
            "option_display_name": "Beer"
          }
        ]
      }
    ],
    "images": [],
    "primary_image": null,
    "custom_fields": [
      {
        "id": 6657494,
        "name": "ABV",
        "value": "5.00"
      },
      {
        "id": 6657499,
        "name": "Country",
        "value": "CA"
      },
      {
        "id": 6657502,
        "name": "Category",
        "value": "Ontario Craft"
      },
      {
        "id": 6657504,
        "name": "Type",
        "value": "Ale"
      },
      {
        "id": 6657508,
        "name": "Beverage_type",
        "value": "Beer"
      },
      {
        "id": 6657510,
        "name": "Producer",
        "value": "AMSTERDAM BREWING COMPANY"
      },
      {
        "id": 6657512,
        "name": "Beer_styles",
        "value": "Dark,Brown"
      },
      {
        "id": 6657514,
        "name": "Price_Metadata_1678004",
        "value": "{\"current_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.36}],\"total_price\":3.25},\"previous_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.36}],\"total_price\":3.25},\"on_sale\":\"N\"}"
      },
      {
        "id": 6657516,
        "name": "Price_Metadata_1678038",
        "value": "{\"current_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.11}],\"total_price\":18.95},\"previous_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.11}],\"total_price\":18.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6657518,
        "name": "Price_Metadata_1678041",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":7.43}],\"total_price\":66.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":7.43}],\"total_price\":66.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6657520,
        "name": "Price_Metadata_1678042",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.95}],\"total_price\":35.50},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.95}],\"total_price\":35.50},\"on_sale\":\"N\"}"
      },
      {
        "id": 6657522,
        "name": "product_image_1",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbdaw0-1pypk0-4shtj2/7911.png?position=1"
      },
      {
        "id": 6657524,
        "name": "product_image_2",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbdaw0-1pypk0-4shtj2/7911.png?position=2"
      },
      {
        "id": 6657525,
        "name": "container_type ",
        "value": "C"
      },
      {
        "id": 6657526,
        "name": "pack",
        "value": "1,6,24,12"
      }
    ]
  },
  {
    "id": 462062,
    "name": "amsterdam-fria-cerveza-especial",
    "type": "physical",
    "sku": "1015_2405",
    "description": "Light and crisp with a hint of lime zest. Sunshine in a can.",
    "weight": 1,
    "width": 0,
    "depth": 0,
    "height": 0,
    "price": 34.95,
    "cost_price": 0,
    "retail_price": 0,
    "sale_price": 0,
    "map_price": 0,
    "tax_class_id": 0,
    "product_tax_code": "",
    "calculated_price": 34.95,
    "categories": [162, 198, 169, 217, 165, 170, 171, 167, 166],
    "brand_id": 1030,
    "option_set_id": 461962,
    "option_set_display": "right",
    "inventory_level": 40,
    "inventory_warning_level": 0,
    "inventory_tracking": "variant",
    "reviews_rating_sum": 0,
    "reviews_count": 0,
    "total_sold": 12,
    "fixed_cost_shipping_price": 0,
    "is_free_shipping": false,
    "is_visible": true,
    "is_featured": false,
    "related_products": [-1],
    "warranty": "",
    "bin_picking_number": "",
    "layout_file": "",
    "upc": "",
    "mpn": "",
    "gtin": "",
    "search_keywords": "",
    "availability": "available",
    "availability_description": "",
    "gift_wrapping_options_type": "any",
    "gift_wrapping_options_list": [],
    "sort_order": 0,
    "condition": "New",
    "is_condition_shown": false,
    "order_quantity_minimum": 0,
    "order_quantity_maximum": 0,
    "page_title": "AMSTERDAM FRIA CERVEZA ESPECIAL~2405",
    "meta_keywords": [],
    "meta_description": "",
    "date_created": "2020-10-16T13:24:46+00:00",
    "date_modified": "2021-12-21T23:23:20+00:00",
    "view_count": 0,
    "preorder_release_date": null,
    "preorder_message": "",
    "is_preorder_only": false,
    "is_price_hidden": false,
    "price_hidden_label": "",
    "custom_url": {
      "url": "/amsterdam-fria-cerveza-especial-2405/",
      "is_customized": false
    },
    "base_variant_id": null,
    "open_graph_type": "product",
    "open_graph_title": "",
    "open_graph_description": "",
    "open_graph_use_meta_description": true,
    "open_graph_use_product_name": true,
    "open_graph_use_image": true,
    "variants": [
      {
        "id": 2287377,
        "product_id": 462062,
        "sku": "2495004_2405",
        "sku_id": 1825480,
        "price": 3.15,
        "calculated_price": 3.15,
        "sale_price": 3.15,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 40,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826682,
            "label": "1 X Can 473 ml",
            "option_id": 462058,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2287393,
        "product_id": 462062,
        "sku": "2495038_2405",
        "sku_id": 1825492,
        "price": 17.95,
        "calculated_price": 17.95,
        "sale_price": 17.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826693,
            "label": "6 X Can 473 ml",
            "option_id": 462058,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2287405,
        "product_id": 462062,
        "sku": "2495041_2405",
        "sku_id": 1825500,
        "price": 65.95,
        "calculated_price": 65.95,
        "sale_price": 65.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826702,
            "label": "24 X Can 473 ml",
            "option_id": 462058,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2287418,
        "product_id": 462062,
        "sku": "2495042_2405",
        "sku_id": 1825510,
        "price": 34.95,
        "calculated_price": 34.95,
        "sale_price": 34.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826712,
            "label": "12 X Can 473 ml",
            "option_id": 462058,
            "option_display_name": "Beer"
          }
        ]
      }
    ],
    "images": [],
    "primary_image": null,
    "custom_fields": [
      {
        "id": 6648572,
        "name": "ABV",
        "value": "4.60"
      },
      {
        "id": 6648578,
        "name": "Country",
        "value": "CA"
      },
      {
        "id": 6648580,
        "name": "Category",
        "value": "Ontario Craft"
      },
      {
        "id": 6648584,
        "name": "Type",
        "value": "Lager"
      },
      {
        "id": 6648587,
        "name": "Beverage_type",
        "value": "Beer"
      },
      {
        "id": 6648590,
        "name": "Producer",
        "value": "AMSTERDAM BREWING COMPANY"
      },
      {
        "id": 6648595,
        "name": "Beer_styles",
        "value": "Blonde"
      },
      {
        "id": 6648598,
        "name": "Price_Metadata_2495004",
        "value": "{\"current_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.35}],\"total_price\":3.15},\"previous_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.35}],\"total_price\":3.15},\"on_sale\":\"N\"}"
      },
      {
        "id": 6648603,
        "name": "Price_Metadata_2495038",
        "value": "{\"current_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.00}],\"total_price\":17.95},\"previous_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.00}],\"total_price\":17.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6648605,
        "name": "Price_Metadata_2495041",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":7.31}],\"total_price\":65.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":6.62}],\"total_price\":59.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6648609,
        "name": "Price_Metadata_2495042",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.88}],\"total_price\":34.95},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.88}],\"total_price\":34.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6648611,
        "name": "product_image_1",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qfymwi-137xh4-13q5vf/1015.png?position=1"
      },
      {
        "id": 6648614,
        "name": "product_image_2",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qfymwi-137xh4-13q5vf/1015.png?position=2"
      },
      {
        "id": 6648618,
        "name": "container_type ",
        "value": "C"
      },
      {
        "id": 6648622,
        "name": "pack",
        "value": "1,6,24,12"
      }
    ]
  },
  {
    "id": 462688,
    "name": "amsterdam-space-invader-ipa",
    "type": "physical",
    "sku": "7919_2405",
    "description": "An out of this world Juicy IPA brewed with Citra hops.",
    "weight": 1,
    "width": 0,
    "depth": 0,
    "height": 0,
    "price": 35.95,
    "cost_price": 0,
    "retail_price": 0,
    "sale_price": 0,
    "map_price": 0,
    "tax_class_id": 0,
    "product_tax_code": "",
    "calculated_price": 35.95,
    "categories": [162, 198, 178, 199, 218, 165, 170, 171, 167, 166],
    "brand_id": 1030,
    "option_set_id": 462586,
    "option_set_display": "right",
    "inventory_level": 33,
    "inventory_warning_level": 0,
    "inventory_tracking": "variant",
    "reviews_rating_sum": 0,
    "reviews_count": 0,
    "total_sold": 0,
    "fixed_cost_shipping_price": 0,
    "is_free_shipping": false,
    "is_visible": true,
    "is_featured": false,
    "related_products": [-1],
    "warranty": "",
    "bin_picking_number": "",
    "layout_file": "",
    "upc": "",
    "mpn": "",
    "gtin": "",
    "search_keywords": "",
    "availability": "available",
    "availability_description": "",
    "gift_wrapping_options_type": "any",
    "gift_wrapping_options_list": [],
    "sort_order": 0,
    "condition": "New",
    "is_condition_shown": false,
    "order_quantity_minimum": 0,
    "order_quantity_maximum": 0,
    "page_title": "AMSTERDAM SPACE INVADER IPA~2405",
    "meta_keywords": [],
    "meta_description": "",
    "date_created": "2020-10-16T13:25:04+00:00",
    "date_modified": "2021-12-22T11:25:14+00:00",
    "view_count": 0,
    "preorder_release_date": null,
    "preorder_message": "",
    "is_preorder_only": false,
    "is_price_hidden": false,
    "price_hidden_label": "",
    "custom_url": {
      "url": "/amsterdam-space-invader-ipa-2405/",
      "is_customized": false
    },
    "base_variant_id": null,
    "open_graph_type": "product",
    "open_graph_title": "",
    "open_graph_description": "",
    "open_graph_use_meta_description": true,
    "open_graph_use_product_name": true,
    "open_graph_use_image": true,
    "variants": [
      {
        "id": 2290298,
        "product_id": 462688,
        "sku": "1976004_2405",
        "sku_id": 1827777,
        "price": 3.35,
        "calculated_price": 3.35,
        "sale_price": 3.35,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 33,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828977,
            "label": "1 X Can 473 ml",
            "option_id": 462682,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290313,
        "product_id": 462688,
        "sku": "1976041_2405",
        "sku_id": 1827788,
        "price": 69.95,
        "calculated_price": 69.95,
        "sale_price": 69.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828989,
            "label": "24 X Can 473 ml",
            "option_id": 462682,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290329,
        "product_id": 462688,
        "sku": "1976042_2405",
        "sku_id": 1827801,
        "price": 35.95,
        "calculated_price": 35.95,
        "sale_price": 35.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1829004,
            "label": "12 X Can 473 ml",
            "option_id": 462682,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 4033301,
        "product_id": 462688,
        "sku": "1976038_2405",
        "sku_id": 3208792,
        "price": 18.95,
        "calculated_price": 18.95,
        "sale_price": 18.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 3210001,
            "label": "6 X Can 473 ml",
            "option_id": 462682,
            "option_display_name": "Beer"
          }
        ]
      }
    ],
    "images": [],
    "primary_image": null,
    "custom_fields": [
      {
        "id": 6657547,
        "name": "ABV",
        "value": "6.00"
      },
      {
        "id": 6657549,
        "name": "Country",
        "value": "CA"
      },
      {
        "id": 6657552,
        "name": "Category",
        "value": "Ontario Craft"
      },
      {
        "id": 6657554,
        "name": "Type",
        "value": "Ale"
      },
      {
        "id": 6657556,
        "name": "Beverage_type",
        "value": "Beer"
      },
      {
        "id": 6657558,
        "name": "Producer",
        "value": "AMSTERDAM BREWING COMPANY"
      },
      {
        "id": 6657560,
        "name": "Beer_styles",
        "value": "India Pale Ale,Golden"
      },
      {
        "id": 6657562,
        "name": "Price_Metadata_1976004",
        "value": "{\"current_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.37}],\"total_price\":3.35},\"previous_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.34}],\"total_price\":3.05},\"on_sale\":\"N\"}"
      },
      {
        "id": 6657564,
        "name": "Price_Metadata_1976041",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":7.77}],\"total_price\":69.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":7.20}],\"total_price\":64.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6657566,
        "name": "Price_Metadata_1976042",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":4.00}],\"total_price\":35.95},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.77}],\"total_price\":33.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6657569,
        "name": "product_image_1",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbdaw0-1pypk0-7ptq8i/7919.png?position=1"
      },
      {
        "id": 6657571,
        "name": "product_image_2",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbdaw0-1pypk0-7ptq8i/7919.png?position=2"
      },
      {
        "id": 6657573,
        "name": "container_type ",
        "value": "C"
      },
      {
        "id": 6657575,
        "name": "pack",
        "value": "1,24,12"
      },
      {
        "id": 11936771,
        "name": "Price_Metadata_1976038",
        "value": "{\"current_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.11}],\"total_price\":18.95},\"previous_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.00}],\"total_price\":17.95},\"on_sale\":\"N\"}"
      }
    ]
  },
  {
    "id": 853703,
    "name": "amsterdam-sweetwater-squeeze-seltzer-variety",
    "type": "physical",
    "sku": "1452_2405",
    "description": "Crafted with quality ingredients for maximum refreshment. The perfect mix of seltzer water, a subtle base and a squeeze of fruit flavour. 140 calories and 2g of carbs per can.",
    "weight": 1,
    "width": 0,
    "depth": 0,
    "height": 0,
    "price": 32.95,
    "cost_price": 0,
    "retail_price": 0,
    "sale_price": 0,
    "map_price": 0,
    "tax_class_id": 0,
    "product_tax_code": "",
    "calculated_price": 32.95,
    "categories": [162, 198, 164, 207, 227, 165, 171, 167, 166],
    "brand_id": 1030,
    "option_set_id": 853601,
    "option_set_display": "right",
    "inventory_level": 7,
    "inventory_warning_level": 0,
    "inventory_tracking": "variant",
    "reviews_rating_sum": 0,
    "reviews_count": 0,
    "total_sold": 1,
    "fixed_cost_shipping_price": 0,
    "is_free_shipping": false,
    "is_visible": true,
    "is_featured": false,
    "related_products": [-1],
    "warranty": "",
    "bin_picking_number": "",
    "layout_file": "",
    "upc": "",
    "mpn": "",
    "gtin": "",
    "search_keywords": "",
    "availability": "available",
    "availability_description": "",
    "gift_wrapping_options_type": "any",
    "gift_wrapping_options_list": [],
    "sort_order": 0,
    "condition": "New",
    "is_condition_shown": false,
    "order_quantity_minimum": 0,
    "order_quantity_maximum": 0,
    "page_title": "AMSTERDAM SWEETWATER SQUEEZE SELTZER VARIETY~2405",
    "meta_keywords": [],
    "meta_description": "",
    "date_created": "2021-06-12T10:08:45+00:00",
    "date_modified": "2021-12-21T23:23:20+00:00",
    "view_count": 0,
    "preorder_release_date": null,
    "preorder_message": "",
    "is_preorder_only": false,
    "is_price_hidden": false,
    "price_hidden_label": "",
    "custom_url": {
      "url": "/amsterdam-sweetwater-squeeze-seltzer-variety-2405/",
      "is_customized": false
    },
    "base_variant_id": null,
    "open_graph_type": "product",
    "open_graph_title": "",
    "open_graph_description": "",
    "open_graph_use_meta_description": true,
    "open_graph_use_product_name": true,
    "open_graph_use_image": true,
    "variants": [
      {
        "id": 4157400,
        "product_id": 853703,
        "sku": "2918038_2405",
        "sku_id": 3303865,
        "price": 16.95,
        "calculated_price": 16.95,
        "sale_price": 16.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 7,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 3305075,
            "label": "6 X Can 473 ml",
            "option_id": 853697,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 4157401,
        "product_id": 853703,
        "sku": "2918041_2405",
        "sku_id": 3303866,
        "price": 62.95,
        "calculated_price": 62.95,
        "sale_price": 62.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 3305076,
            "label": "24 X Can 473 ml",
            "option_id": 853697,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 4157402,
        "product_id": 853703,
        "sku": "2918042_2405",
        "sku_id": 3303867,
        "price": 32.95,
        "calculated_price": 32.95,
        "sale_price": 32.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 3305077,
            "label": "12 X Can 473 ml",
            "option_id": 853697,
            "option_display_name": "Beer"
          }
        ]
      }
    ],
    "images": [],
    "primary_image": null,
    "custom_fields": [
      {
        "id": 12456934,
        "name": "ABV",
        "value": "5.00"
      },
      {
        "id": 12456935,
        "name": "Country",
        "value": "CA"
      },
      {
        "id": 12456936,
        "name": "Category",
        "value": "Ontario Craft"
      },
      {
        "id": 12456937,
        "name": "Type",
        "value": "Mixed Case"
      },
      {
        "id": 12456938,
        "name": "Beverage_type",
        "value": "Beer"
      },
      {
        "id": 12456939,
        "name": "Producer",
        "value": "AMSTERDAM BREWING COMPANY"
      },
      {
        "id": 12456940,
        "name": "Beer_attributes",
        "value": "Low Carb,Sessional"
      },
      {
        "id": 12456941,
        "name": "Price_Metadata_2918038",
        "value": "{\"current_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.88}],\"total_price\":16.95},\"previous_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.00}],\"total_price\":0.00},\"on_sale\":\"N\"}"
      },
      {
        "id": 12456942,
        "name": "Price_Metadata_2918041",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":6.97}],\"total_price\":62.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.00}],\"total_price\":0.00},\"on_sale\":\"N\"}"
      },
      {
        "id": 12456943,
        "name": "Price_Metadata_2918042",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.65}],\"total_price\":32.95},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.00}],\"total_price\":0.00},\"on_sale\":\"N\"}"
      },
      {
        "id": 12456944,
        "name": "container_type ",
        "value": "C"
      },
      {
        "id": 12456945,
        "name": "pack",
        "value": "6,24,12"
      },
      {
        "id": 12493598,
        "name": "product_image_1",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/sjp99vmt6w5c8mqtfpkp979b/1452.png?position=1"
      },
      {
        "id": 12493599,
        "name": "product_image_2",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/sjp99vmt6w5c8mqtfpkp979b/1452.png?position=2"
      }
    ]
  },
  {
    "id": 868205,
    "name": "arizona-hard-lemon-ice-tea",
    "type": "physical",
    "sku": "1497_2405",
    "description": "The iconic Green Tea with ginseng and honey; North America’s #1 Green Tea beverage uses 100% real brewed green tea, ginseng extract and a touch of premium orange honey ",
    "weight": 1,
    "width": 0,
    "depth": 0,
    "height": 0,
    "price": 33.95,
    "cost_price": 0,
    "retail_price": 0,
    "sale_price": 0,
    "map_price": 0,
    "tax_class_id": 0,
    "product_tax_code": "",
    "calculated_price": 33.95,
    "categories": [162, 163, 190, 197, 179, 165, 170, 167, 166],
    "brand_id": 1030,
    "option_set_id": 868103,
    "option_set_display": "right",
    "inventory_level": 116,
    "inventory_warning_level": 0,
    "inventory_tracking": "variant",
    "reviews_rating_sum": 0,
    "reviews_count": 0,
    "total_sold": 0,
    "fixed_cost_shipping_price": 0,
    "is_free_shipping": false,
    "is_visible": true,
    "is_featured": false,
    "related_products": [-1],
    "warranty": "",
    "bin_picking_number": "",
    "layout_file": "",
    "upc": "",
    "mpn": "",
    "gtin": "",
    "search_keywords": "",
    "availability": "available",
    "availability_description": "",
    "gift_wrapping_options_type": "any",
    "gift_wrapping_options_list": [],
    "sort_order": 0,
    "condition": "New",
    "is_condition_shown": false,
    "order_quantity_minimum": 0,
    "order_quantity_maximum": 0,
    "page_title": "ARIZONA HARD LEMON ICE TEA~2405",
    "meta_keywords": [],
    "meta_description": "",
    "date_created": "2021-07-26T10:19:21+00:00",
    "date_modified": "2021-12-22T11:25:14+00:00",
    "view_count": 0,
    "preorder_release_date": null,
    "preorder_message": "",
    "is_preorder_only": false,
    "is_price_hidden": false,
    "price_hidden_label": "",
    "custom_url": {
      "url": "/arizona-hard-m-lemon-ice-tea-2405/",
      "is_customized": false
    },
    "base_variant_id": null,
    "open_graph_type": "product",
    "open_graph_title": "",
    "open_graph_description": "",
    "open_graph_use_meta_description": true,
    "open_graph_use_product_name": true,
    "open_graph_use_image": true,
    "variants": [
      {
        "id": 4215465,
        "product_id": 868205,
        "sku": "2963004_2405",
        "sku_id": 3347428,
        "price": 3,
        "calculated_price": 2.9,
        "sale_price": 2.9,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 116,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 3348638,
            "label": "1 X Can 473 ml",
            "option_id": 868199,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 4215466,
        "product_id": 868205,
        "sku": "2963041_2405",
        "sku_id": 3347429,
        "price": 70.8,
        "calculated_price": 66.95,
        "sale_price": 66.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 3348639,
            "label": "24 X Can 473 ml",
            "option_id": 868199,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 4215467,
        "product_id": 868205,
        "sku": "2963042_2405",
        "sku_id": 3347430,
        "price": 33.95,
        "calculated_price": 33.95,
        "sale_price": 33.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 3348640,
            "label": "12 X Can 473 ml",
            "option_id": 868199,
            "option_display_name": "Beer"
          }
        ]
      }
    ],
    "images": [],
    "primary_image": null,
    "custom_fields": [
      {
        "id": 12645556,
        "name": "ABV",
        "value": "5.00"
      },
      {
        "id": 12645557,
        "name": "Country",
        "value": "CA"
      },
      {
        "id": 12645558,
        "name": "Category",
        "value": "Domestic Specialty"
      },
      {
        "id": 12645559,
        "name": "Type",
        "value": "Flavoured Malt"
      },
      {
        "id": 12645560,
        "name": "Beverage_type",
        "value": "Beer"
      },
      {
        "id": 12645561,
        "name": "Producer",
        "value": "MOLSON"
      },
      {
        "id": 12645562,
        "name": "Beer_styles",
        "value": "Fruit"
      },
      {
        "id": 12645563,
        "name": "Price_Metadata_2963004",
        "value": "{\"current_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.32}],\"total_price\":2.90},\"previous_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.33}],\"total_price\":3.00},\"on_sale\":\"Y\"}"
      },
      {
        "id": 12645564,
        "name": "Price_Metadata_2963041",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":7.43}],\"total_price\":66.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":7.87}],\"total_price\":70.80},\"on_sale\":\"Y\"}"
      },
      {
        "id": 12645565,
        "name": "Price_Metadata_2963042",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.77}],\"total_price\":33.95},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.00}],\"total_price\":0.00},\"on_sale\":\"N\"}"
      },
      {
        "id": 12645566,
        "name": "container_type ",
        "value": "C"
      },
      {
        "id": 12645567,
        "name": "pack",
        "value": "1,24,12"
      },
      {
        "id": 12904473,
        "name": "product_image_1",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/w597thrxttmg4v8hx29hnxp/1497.png?position=1"
      },
      {
        "id": 12904474,
        "name": "product_image_2",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/w597thrxttmg4v8hx29hnxp/1497.png?position=2"
      }
    ]
  },
  {
    "id": 868206,
    "name": "arizona-hard-m-green-tea",
    "type": "physical",
    "sku": "1498_2405",
    "description": "The iconic Green Tea with Ginseng & Honey; North America’s #1 Green Tea Beverage uses 100% Real Brewed Green Tea, Ginseng Extract &a touch of premium Orange Honey. A classic beverage, now with a kick!",
    "weight": 1,
    "width": 0,
    "depth": 0,
    "height": 0,
    "price": 33.95,
    "cost_price": 0,
    "retail_price": 0,
    "sale_price": 0,
    "map_price": 0,
    "tax_class_id": 0,
    "product_tax_code": "",
    "calculated_price": 33.95,
    "categories": [162, 163, 190, 197, 179, 165, 170, 167, 166],
    "brand_id": 1030,
    "option_set_id": 868104,
    "option_set_display": "right",
    "inventory_level": 106,
    "inventory_warning_level": 0,
    "inventory_tracking": "variant",
    "reviews_rating_sum": 0,
    "reviews_count": 0,
    "total_sold": 0,
    "fixed_cost_shipping_price": 0,
    "is_free_shipping": false,
    "is_visible": true,
    "is_featured": false,
    "related_products": [-1],
    "warranty": "",
    "bin_picking_number": "",
    "layout_file": "",
    "upc": "",
    "mpn": "",
    "gtin": "",
    "search_keywords": "",
    "availability": "available",
    "availability_description": "",
    "gift_wrapping_options_type": "any",
    "gift_wrapping_options_list": [],
    "sort_order": 0,
    "condition": "New",
    "is_condition_shown": false,
    "order_quantity_minimum": 0,
    "order_quantity_maximum": 0,
    "page_title": "ARIZONA HARD M GREEN TEA~2405",
    "meta_keywords": [],
    "meta_description": "",
    "date_created": "2021-07-26T10:19:21+00:00",
    "date_modified": "2021-12-22T11:25:14+00:00",
    "view_count": 0,
    "preorder_release_date": null,
    "preorder_message": "",
    "is_preorder_only": false,
    "is_price_hidden": false,
    "price_hidden_label": "",
    "custom_url": {
      "url": "/arizona-hard-m-green-tea-2405/",
      "is_customized": false
    },
    "base_variant_id": null,
    "open_graph_type": "product",
    "open_graph_title": "",
    "open_graph_description": "",
    "open_graph_use_meta_description": true,
    "open_graph_use_product_name": true,
    "open_graph_use_image": true,
    "variants": [
      {
        "id": 4215469,
        "product_id": 868206,
        "sku": "2964004_2405",
        "sku_id": 3347431,
        "price": 3,
        "calculated_price": 3,
        "sale_price": 3,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 106,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 3348641,
            "label": "1 X Can 473 ml",
            "option_id": 868200,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 4215470,
        "product_id": 868206,
        "sku": "2964041_2405",
        "sku_id": 3347432,
        "price": 70.8,
        "calculated_price": 66.95,
        "sale_price": 66.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 3348642,
            "label": "24 X Can 473 ml",
            "option_id": 868200,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 4215471,
        "product_id": 868206,
        "sku": "2964042_2405",
        "sku_id": 3347433,
        "price": 33.95,
        "calculated_price": 33.95,
        "sale_price": 33.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 3348643,
            "label": "12 X Can 473 ml",
            "option_id": 868200,
            "option_display_name": "Beer"
          }
        ]
      }
    ],
    "images": [],
    "primary_image": null,
    "custom_fields": [
      {
        "id": 12645568,
        "name": "ABV",
        "value": "5.00"
      },
      {
        "id": 12645569,
        "name": "Country",
        "value": "CA"
      },
      {
        "id": 12645570,
        "name": "Category",
        "value": "Domestic Specialty"
      },
      {
        "id": 12645571,
        "name": "Type",
        "value": "Flavoured Malt"
      },
      {
        "id": 12645572,
        "name": "Beverage_type",
        "value": "Beer"
      },
      {
        "id": 12645573,
        "name": "Producer",
        "value": "MOLSON"
      },
      {
        "id": 12645574,
        "name": "Beer_styles",
        "value": "Fruit"
      },
      {
        "id": 12645575,
        "name": "Price_Metadata_2964004",
        "value": "{\"current_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.33}],\"total_price\":3.00},\"previous_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.32}],\"total_price\":2.90},\"on_sale\":\"N\"}"
      },
      {
        "id": 12645576,
        "name": "Price_Metadata_2964041",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":7.43}],\"total_price\":66.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":7.87}],\"total_price\":70.80},\"on_sale\":\"Y\"}"
      },
      {
        "id": 12645577,
        "name": "Price_Metadata_2964042",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.77}],\"total_price\":33.95},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.00}],\"total_price\":0.00},\"on_sale\":\"N\"}"
      },
      {
        "id": 12645578,
        "name": "container_type ",
        "value": "C"
      },
      {
        "id": 12645579,
        "name": "pack",
        "value": "1,24,12"
      },
      {
        "id": 12811422,
        "name": "product_image_1",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/5r94jg7qw8fj7vzcc7v56pk/1498.png?position=1"
      },
      {
        "id": 12811423,
        "name": "product_image_2",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/5r94jg7qw8fj7vzcc7v56pk/1498.png?position=2"
      }
    ]
  },
  {
    "id": 462407,
    "name": "asahi-super-dry",
    "type": "physical",
    "sku": "2588_2405",
    "description": "Asahi beer has excellent richness, truly refreshing drinkability and satin smoothness.",
    "weight": 1,
    "width": 0,
    "depth": 0,
    "height": 0,
    "price": 14.95,
    "cost_price": 0,
    "retail_price": 0,
    "sale_price": 0,
    "map_price": 0,
    "tax_class_id": 0,
    "product_tax_code": "",
    "calculated_price": 14.95,
    "categories": [
      265, 232, 169, 224, 180, 165, 175, 176, 177, 181, 170, 167, 166, 202
    ],
    "brand_id": 1030,
    "option_set_id": 462305,
    "option_set_display": "right",
    "inventory_level": 256,
    "inventory_warning_level": 0,
    "inventory_tracking": "variant",
    "reviews_rating_sum": 0,
    "reviews_count": 0,
    "total_sold": 48,
    "fixed_cost_shipping_price": 0,
    "is_free_shipping": false,
    "is_visible": true,
    "is_featured": false,
    "related_products": [-1],
    "warranty": "",
    "bin_picking_number": "",
    "layout_file": "",
    "upc": "",
    "mpn": "",
    "gtin": "",
    "search_keywords": "",
    "availability": "available",
    "availability_description": "",
    "gift_wrapping_options_type": "any",
    "gift_wrapping_options_list": [],
    "sort_order": 0,
    "condition": "New",
    "is_condition_shown": false,
    "order_quantity_minimum": 0,
    "order_quantity_maximum": 0,
    "page_title": "ASAHI SUPER DRY~2405",
    "meta_keywords": [],
    "meta_description": "",
    "date_created": "2020-10-16T13:24:56+00:00",
    "date_modified": "2021-12-22T23:24:22+00:00",
    "view_count": 0,
    "preorder_release_date": null,
    "preorder_message": "",
    "is_preorder_only": false,
    "is_price_hidden": false,
    "price_hidden_label": "",
    "custom_url": {
      "url": "/asahi-super-dry-2405/",
      "is_customized": false
    },
    "base_variant_id": null,
    "open_graph_type": "product",
    "open_graph_title": "",
    "open_graph_description": "",
    "open_graph_use_meta_description": true,
    "open_graph_use_product_name": true,
    "open_graph_use_image": true,
    "variants": [
      {
        "id": 2288827,
        "product_id": 462407,
        "sku": "2109063_2405",
        "sku_id": 1826585,
        "price": 2.9,
        "calculated_price": 2.9,
        "sale_price": 2.9,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 237,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1827788,
            "label": "1 X Can 500 ml",
            "option_id": 462401,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2288836,
        "product_id": 462407,
        "sku": "2109130_2405",
        "sku_id": 1826594,
        "price": 65.95,
        "calculated_price": 65.95,
        "sale_price": 65.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1827796,
            "label": "24 X Can 500 ml",
            "option_id": 462401,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 3916084,
        "product_id": 462407,
        "sku": "2109048_2405",
        "sku_id": 3123110,
        "price": 143.1,
        "calculated_price": 143.1,
        "sale_price": 143.1,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 3124323,
            "label": "1 X Keg 19000 ml",
            "option_id": 462401,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 4116075,
        "product_id": 462407,
        "sku": "2109400_2405",
        "sku_id": 3272393,
        "price": 14.95,
        "calculated_price": 14.95,
        "sale_price": 14.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 19,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 3273601,
            "label": "6 X Bottle 330 ml",
            "option_id": 462401,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 4116086,
        "product_id": 462407,
        "sku": "2109013_2405",
        "sku_id": 3272404,
        "price": 50.95,
        "calculated_price": 50.95,
        "sale_price": 50.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 3273609,
            "label": "24 X Bottle 330 ml",
            "option_id": 462401,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 4116123,
        "product_id": 462407,
        "sku": "2109012_2405",
        "sku_id": 3272441,
        "price": 28.5,
        "calculated_price": 28.5,
        "sale_price": 28.5,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 3273619,
            "label": "12 X Bottle 330 ml",
            "option_id": 462401,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 4190591,
        "product_id": 462407,
        "sku": "2109199_2405",
        "sku_id": 3327713,
        "price": 33.75,
        "calculated_price": 33.75,
        "sale_price": 33.75,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 3328923,
            "label": "12 X Can 500 ml",
            "option_id": 462401,
            "option_display_name": "Beer"
          }
        ]
      }
    ],
    "images": [],
    "primary_image": null,
    "custom_fields": [
      {
        "id": 6653306,
        "name": "ABV",
        "value": "5.00"
      },
      {
        "id": 6653308,
        "name": "Country",
        "value": "JP"
      },
      {
        "id": 6653310,
        "name": "Category",
        "value": "Import"
      },
      {
        "id": 6653311,
        "name": "Type",
        "value": "Lager"
      },
      {
        "id": 6653313,
        "name": "Beverage_type",
        "value": "Beer"
      },
      {
        "id": 6653315,
        "name": "Producer",
        "value": "ASAHI BREWERIES LTD"
      },
      {
        "id": 6653317,
        "name": "Beer_styles",
        "value": "Dry"
      },
      {
        "id": 6653319,
        "name": "Price_Metadata_2109063",
        "value": "{\"current_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.32}],\"total_price\":2.90},\"previous_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.33}],\"total_price\":2.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6653322,
        "name": "Price_Metadata_2109130",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":7.31}],\"total_price\":65.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":6.05}],\"total_price\":54.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6653324,
        "name": "product_image_1",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbdaw0-1pypk0-4zjnac/2588.png?position=1"
      },
      {
        "id": 6653326,
        "name": "product_image_2",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbdaw0-1pypk0-4zjnac/2588.png?position=2"
      },
      {
        "id": 6653328,
        "name": "container_type ",
        "value": "C"
      },
      {
        "id": 6653330,
        "name": "pack",
        "value": "1,24"
      },
      {
        "id": 11552894,
        "name": "Price_Metadata_2109048",
        "value": "{\"current_price\":{\"deposit\":20.00,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":14.16}],\"total_price\":143.10},\"previous_price\":{\"deposit\":20.00,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":14.15}],\"total_price\":143.00},\"on_sale\":\"N\"}"
      },
      {
        "id": 12290510,
        "name": "Price_Metadata_2109012",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.14}],\"total_price\":28.50},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.00}],\"total_price\":0.00},\"on_sale\":\"N\"}"
      },
      {
        "id": 12290511,
        "name": "Price_Metadata_2109013",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.59}],\"total_price\":50.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.00}],\"total_price\":0.00},\"on_sale\":\"N\"}"
      },
      {
        "id": 12290512,
        "name": "Price_Metadata_2109400",
        "value": "{\"current_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.65}],\"total_price\":14.95},\"previous_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.54}],\"total_price\":14.00},\"on_sale\":\"N\"}"
      },
      {
        "id": 12576243,
        "name": "Price_Metadata_2109199",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.74}],\"total_price\":33.75},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.31}],\"total_price\":29.95},\"on_sale\":\"N\"}"
      }
    ]
  },
  {
    "id": 462136,
    "name": "bangarang-blue-raspberry-hard-seltzer",
    "type": "physical",
    "sku": "1128_2405",
    "description": "Bangarang Blue Raspberry Hard Seltzer is a refreshing blend of our craft seltzer and all natural flavours. Low-calories, Low car, full enjoyment! Bangarang is a call to Unite, to share a connection, whether together or apart.",
    "weight": 1,
    "width": 0,
    "depth": 0,
    "height": 0,
    "price": 31.95,
    "cost_price": 0,
    "retail_price": 0,
    "sale_price": 0,
    "map_price": 0,
    "tax_class_id": 0,
    "product_tax_code": "",
    "calculated_price": 31.95,
    "categories": [162, 198, 190, 207, 191, 165, 170, 171, 167, 166],
    "brand_id": 1030,
    "option_set_id": 462033,
    "option_set_display": "right",
    "inventory_level": 54,
    "inventory_warning_level": 0,
    "inventory_tracking": "variant",
    "reviews_rating_sum": 0,
    "reviews_count": 0,
    "total_sold": 4,
    "fixed_cost_shipping_price": 0,
    "is_free_shipping": false,
    "is_visible": true,
    "is_featured": false,
    "related_products": [-1],
    "warranty": "",
    "bin_picking_number": "",
    "layout_file": "",
    "upc": "",
    "mpn": "",
    "gtin": "",
    "search_keywords": "",
    "availability": "available",
    "availability_description": "",
    "gift_wrapping_options_type": "any",
    "gift_wrapping_options_list": [],
    "sort_order": 0,
    "condition": "New",
    "is_condition_shown": false,
    "order_quantity_minimum": 0,
    "order_quantity_maximum": 0,
    "page_title": "BANGARANG BLUE RASPBERRY HARD SELTZER~2405",
    "meta_keywords": [],
    "meta_description": "",
    "date_created": "2020-10-16T13:24:48+00:00",
    "date_modified": "2021-11-15T11:28:31+00:00",
    "view_count": 0,
    "preorder_release_date": null,
    "preorder_message": "",
    "is_preorder_only": false,
    "is_price_hidden": false,
    "price_hidden_label": "",
    "custom_url": {
      "url": "/bangarang-blue-raspberry-hard-seltzer-2405/",
      "is_customized": false
    },
    "base_variant_id": null,
    "open_graph_type": "product",
    "open_graph_title": "",
    "open_graph_description": "",
    "open_graph_use_meta_description": true,
    "open_graph_use_product_name": true,
    "open_graph_use_image": true,
    "variants": [
      {
        "id": 2287648,
        "product_id": 462136,
        "sku": "2609004_2405",
        "sku_id": 1825680,
        "price": 2.85,
        "calculated_price": 2.85,
        "sale_price": 2.85,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 54,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826882,
            "label": "1 X Can 473 ml",
            "option_id": 462129,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2287656,
        "product_id": 462136,
        "sku": "2609038_2405",
        "sku_id": 1825686,
        "price": 16.95,
        "calculated_price": 16.95,
        "sale_price": 16.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826888,
            "label": "6 X Can 473 ml",
            "option_id": 462129,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2287663,
        "product_id": 462136,
        "sku": "2609041_2405",
        "sku_id": 1825693,
        "price": 60,
        "calculated_price": 60,
        "sale_price": 60,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826895,
            "label": "24 X Can 473 ml",
            "option_id": 462129,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2287671,
        "product_id": 462136,
        "sku": "2609042_2405",
        "sku_id": 1825698,
        "price": 31.95,
        "calculated_price": 31.95,
        "sale_price": 31.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826901,
            "label": "12 X Can 473 ml",
            "option_id": 462129,
            "option_display_name": "Beer"
          }
        ]
      }
    ],
    "images": [],
    "primary_image": null,
    "custom_fields": [
      {
        "id": 6649501,
        "name": "ABV",
        "value": "5.00"
      },
      {
        "id": 6649509,
        "name": "Country",
        "value": "CA"
      },
      {
        "id": 6649513,
        "name": "Category",
        "value": "Ontario Craft"
      },
      {
        "id": 6649517,
        "name": "Type",
        "value": "Flavoured Malt"
      },
      {
        "id": 6649521,
        "name": "Beverage_type",
        "value": "Beer"
      },
      {
        "id": 6649524,
        "name": "Producer",
        "value": "BANGARANG BEVERAGE CO."
      },
      {
        "id": 6649528,
        "name": "Beer_attributes",
        "value": "Low Carb,Low Calorie"
      },
      {
        "id": 6649532,
        "name": "Price_Metadata_2609004",
        "value": "{\"current_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.32}],\"total_price\":2.85},\"previous_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.29}],\"total_price\":2.65},\"on_sale\":\"N\"}"
      },
      {
        "id": 6649536,
        "name": "Price_Metadata_2609038",
        "value": "{\"current_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.88}],\"total_price\":16.95},\"previous_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.83}],\"total_price\":16.50},\"on_sale\":\"N\"}"
      },
      {
        "id": 6649541,
        "name": "Price_Metadata_2609041",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":6.63}],\"total_price\":60.00},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":6.63}],\"total_price\":60.00},\"on_sale\":\"N\"}"
      },
      {
        "id": 6649545,
        "name": "Price_Metadata_2609042",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.54}],\"total_price\":31.95},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.49}],\"total_price\":31.50},\"on_sale\":\"N\"}"
      },
      {
        "id": 6649550,
        "name": "container_type ",
        "value": "C"
      },
      {
        "id": 6649552,
        "name": "pack",
        "value": "1,6,24,12"
      },
      {
        "id": 11385672,
        "name": "product_image_1",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/pwq3cbgrvsp5jchzsws6xc6/1128.png?position=1"
      },
      {
        "id": 11385673,
        "name": "product_image_2",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/pwq3cbgrvsp5jchzsws6xc6/1128.png?position=2"
      }
    ]
  },
  {
    "id": 462115,
    "name": "bangarang-hard-seltzer-mixer-pack",
    "type": "physical",
    "sku": "1093_2405",
    "description": "Bangarang Hard Seltzer is a refreshing change from the norm. Made in Ontario and comes in your new favourite flavours! Lemon-Lime, Blue-Raspberry and Mango. All-natural. Low cal. Low carb. Premium, clear, seltzer. @drinkbangarang ",
    "weight": 1,
    "width": 0,
    "depth": 0,
    "height": 0,
    "price": 31.95,
    "cost_price": 0,
    "retail_price": 0,
    "sale_price": 0,
    "map_price": 0,
    "tax_class_id": 0,
    "product_tax_code": "",
    "calculated_price": 31.95,
    "categories": [162, 198, 164, 207, 191, 219, 197, 179, 165, 167, 166],
    "brand_id": 1030,
    "option_set_id": 462012,
    "option_set_display": "right",
    "inventory_level": 12,
    "inventory_warning_level": 0,
    "inventory_tracking": "variant",
    "reviews_rating_sum": 0,
    "reviews_count": 0,
    "total_sold": 0,
    "fixed_cost_shipping_price": 0,
    "is_free_shipping": false,
    "is_visible": true,
    "is_featured": false,
    "related_products": [-1],
    "warranty": "",
    "bin_picking_number": "",
    "layout_file": "",
    "upc": "",
    "mpn": "",
    "gtin": "",
    "search_keywords": "",
    "availability": "available",
    "availability_description": "",
    "gift_wrapping_options_type": "any",
    "gift_wrapping_options_list": [],
    "sort_order": 0,
    "condition": "New",
    "is_condition_shown": false,
    "order_quantity_minimum": 0,
    "order_quantity_maximum": 0,
    "page_title": "BANGARANG HARD SELTZER - MIXER PACK~2405",
    "meta_keywords": [],
    "meta_description": "",
    "date_created": "2020-10-16T13:24:48+00:00",
    "date_modified": "2021-12-11T13:20:01+00:00",
    "view_count": 0,
    "preorder_release_date": null,
    "preorder_message": "",
    "is_preorder_only": false,
    "is_price_hidden": false,
    "price_hidden_label": "",
    "custom_url": {
      "url": "/bangarang-hard-seltzer-mixer-pack-2405/",
      "is_customized": false
    },
    "base_variant_id": null,
    "open_graph_type": "product",
    "open_graph_title": "",
    "open_graph_description": "",
    "open_graph_use_meta_description": true,
    "open_graph_use_product_name": true,
    "open_graph_use_image": true,
    "variants": [
      {
        "id": 2287570,
        "product_id": 462115,
        "sku": "2581041_2405",
        "sku_id": 1825621,
        "price": 60,
        "calculated_price": 60,
        "sale_price": 60,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826823,
            "label": "24 X Can 473 ml",
            "option_id": 462108,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2287580,
        "product_id": 462115,
        "sku": "2581042_2405",
        "sku_id": 1825627,
        "price": 31.95,
        "calculated_price": 29.95,
        "sale_price": 29.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 12,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826831,
            "label": "12 X Can 473 ml",
            "option_id": 462108,
            "option_display_name": "Beer"
          }
        ]
      }
    ],
    "images": [],
    "primary_image": null,
    "custom_fields": [
      {
        "id": 6649232,
        "name": "ABV",
        "value": "5.00"
      },
      {
        "id": 6649237,
        "name": "Country",
        "value": "CA"
      },
      {
        "id": 6649240,
        "name": "Category",
        "value": "Ontario Craft"
      },
      {
        "id": 6649244,
        "name": "Type",
        "value": "Mixed Case"
      },
      {
        "id": 6649247,
        "name": "Beverage_type",
        "value": "Beer"
      },
      {
        "id": 6649249,
        "name": "Producer",
        "value": "BANGARANG BEVERAGE CO."
      },
      {
        "id": 6649251,
        "name": "Beer_attributes",
        "value": "Low Carb,Low Calorie,Mixed Case"
      },
      {
        "id": 6649254,
        "name": "Beer_styles",
        "value": "Fruit"
      },
      {
        "id": 6649257,
        "name": "Price_Metadata_2581041",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":6.63}],\"total_price\":60.00},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":6.63}],\"total_price\":60.00},\"on_sale\":\"N\"}"
      },
      {
        "id": 6649259,
        "name": "Price_Metadata_2581042",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.31}],\"total_price\":29.95},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.54}],\"total_price\":31.95},\"on_sale\":\"Y\"}"
      },
      {
        "id": 6649263,
        "name": "product_image_1",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/8j2pp6fr3r2fgvx634fspqf/1093.png?position=1"
      },
      {
        "id": 6649265,
        "name": "product_image_2",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/8j2pp6fr3r2fgvx634fspqf/1093.png?position=2"
      },
      {
        "id": 6649269,
        "name": "container_type ",
        "value": "C"
      },
      {
        "id": 6649272,
        "name": "pack",
        "value": "24,12"
      }
    ]
  },
  {
    "id": 462123,
    "name": "bangarang-mango-hard-seltzer",
    "type": "physical",
    "sku": "1105_2405",
    "description": "Bangarang Mango Hard Seltzer is a refreshing blend of our craft seltzer and all natural flavours. 110 calories and 1g of carbs per 355mL. Bangarang is a call to Unite, to share a connection, whether together or apart. Bangarang. What's your Rally Cry?",
    "weight": 1,
    "width": 0,
    "depth": 0,
    "height": 0,
    "price": 31.95,
    "cost_price": 0,
    "retail_price": 0,
    "sale_price": 0,
    "map_price": 0,
    "tax_class_id": 0,
    "product_tax_code": "",
    "calculated_price": 31.95,
    "categories": [162, 198, 190, 207, 191, 165, 170, 171, 167, 166],
    "brand_id": 1030,
    "option_set_id": 462021,
    "option_set_display": "right",
    "inventory_level": 74,
    "inventory_warning_level": 0,
    "inventory_tracking": "variant",
    "reviews_rating_sum": 0,
    "reviews_count": 0,
    "total_sold": 2,
    "fixed_cost_shipping_price": 0,
    "is_free_shipping": false,
    "is_visible": true,
    "is_featured": false,
    "related_products": [-1],
    "warranty": "",
    "bin_picking_number": "",
    "layout_file": "",
    "upc": "",
    "mpn": "",
    "gtin": "",
    "search_keywords": "",
    "availability": "available",
    "availability_description": "",
    "gift_wrapping_options_type": "any",
    "gift_wrapping_options_list": [],
    "sort_order": 0,
    "condition": "New",
    "is_condition_shown": false,
    "order_quantity_minimum": 0,
    "order_quantity_maximum": 0,
    "page_title": "BANGARANG MANGO HARD SELTZER~2405",
    "meta_keywords": [],
    "meta_description": "",
    "date_created": "2020-10-16T13:24:48+00:00",
    "date_modified": "2021-11-23T01:22:13+00:00",
    "view_count": 0,
    "preorder_release_date": null,
    "preorder_message": "",
    "is_preorder_only": false,
    "is_price_hidden": false,
    "price_hidden_label": "",
    "custom_url": {
      "url": "/bangarang-mango-hard-seltzer-2405/",
      "is_customized": false
    },
    "base_variant_id": null,
    "open_graph_type": "product",
    "open_graph_title": "",
    "open_graph_description": "",
    "open_graph_use_meta_description": true,
    "open_graph_use_product_name": true,
    "open_graph_use_image": true,
    "variants": [
      {
        "id": 2287593,
        "product_id": 462123,
        "sku": "2564004_2405",
        "sku_id": 1825638,
        "price": 2.85,
        "calculated_price": 2.85,
        "sale_price": 2.85,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 74,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826840,
            "label": "1 X Can 473 ml",
            "option_id": 462117,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2287600,
        "product_id": 462123,
        "sku": "2564038_2405",
        "sku_id": 1825645,
        "price": 16.95,
        "calculated_price": 16.95,
        "sale_price": 16.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826847,
            "label": "6 X Can 473 ml",
            "option_id": 462117,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2287609,
        "product_id": 462123,
        "sku": "2564041_2405",
        "sku_id": 1825652,
        "price": 60,
        "calculated_price": 60,
        "sale_price": 60,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826854,
            "label": "24 X Can 473 ml",
            "option_id": 462117,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2287616,
        "product_id": 462123,
        "sku": "2564042_2405",
        "sku_id": 1825659,
        "price": 31.95,
        "calculated_price": 31.95,
        "sale_price": 31.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826861,
            "label": "12 X Can 473 ml",
            "option_id": 462117,
            "option_display_name": "Beer"
          }
        ]
      }
    ],
    "images": [],
    "primary_image": null,
    "custom_fields": [
      {
        "id": 6649344,
        "name": "ABV",
        "value": "5.00"
      },
      {
        "id": 6649351,
        "name": "Country",
        "value": "CA"
      },
      {
        "id": 6649354,
        "name": "Category",
        "value": "Ontario Craft"
      },
      {
        "id": 6649356,
        "name": "Type",
        "value": "Flavoured Malt"
      },
      {
        "id": 6649358,
        "name": "Beverage_type",
        "value": "Beer"
      },
      {
        "id": 6649359,
        "name": "Producer",
        "value": "BANGARANG BEVERAGE CO."
      },
      {
        "id": 6649361,
        "name": "Beer_attributes",
        "value": "Low Carb,Low Calorie"
      },
      {
        "id": 6649363,
        "name": "Price_Metadata_2564004",
        "value": "{\"current_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.32}],\"total_price\":2.85},\"previous_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.29}],\"total_price\":2.65},\"on_sale\":\"N\"}"
      },
      {
        "id": 6649364,
        "name": "Price_Metadata_2564038",
        "value": "{\"current_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.88}],\"total_price\":16.95},\"previous_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.77}],\"total_price\":15.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6649366,
        "name": "Price_Metadata_2564041",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":6.63}],\"total_price\":60.00},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":6.63}],\"total_price\":60.00},\"on_sale\":\"N\"}"
      },
      {
        "id": 6649367,
        "name": "Price_Metadata_2564042",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.54}],\"total_price\":31.95},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.49}],\"total_price\":31.50},\"on_sale\":\"N\"}"
      },
      {
        "id": 6649368,
        "name": "product_image_1",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qg0vsj-4m0xr4-b7b1q5/1105.png?position=1"
      },
      {
        "id": 6649369,
        "name": "product_image_2",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qg0vsj-4m0xr4-b7b1q5/1105.png?position=2"
      },
      {
        "id": 6649370,
        "name": "container_type ",
        "value": "C"
      },
      {
        "id": 6649371,
        "name": "pack",
        "value": "1,6,24,12"
      }
    ]
  },
  {
    "id": 825979,
    "name": "bangarang-pink-lemonade-hard-seltzer",
    "type": "physical",
    "sku": "1298_2405",
    "description": "Bangarang Pink Lemonade is a delicious blend of our craft seltzer and a refreshing pink lemonade. Low-calories / Low carb / full enjoyment! Bangarang is a call to Unite, to share a connection, whether together or apart.",
    "weight": 1,
    "width": 0,
    "depth": 0,
    "height": 0,
    "price": 31.95,
    "cost_price": 0,
    "retail_price": 0,
    "sale_price": 0,
    "map_price": 0,
    "tax_class_id": 0,
    "product_tax_code": "",
    "calculated_price": 31.95,
    "categories": [162, 198, 190, 207, 191, 165, 170, 171, 167, 166],
    "brand_id": 1030,
    "option_set_id": 825877,
    "option_set_display": "right",
    "inventory_level": 54,
    "inventory_warning_level": 0,
    "inventory_tracking": "variant",
    "reviews_rating_sum": 0,
    "reviews_count": 0,
    "total_sold": 12,
    "fixed_cost_shipping_price": 0,
    "is_free_shipping": false,
    "is_visible": true,
    "is_featured": false,
    "related_products": [-1],
    "warranty": "",
    "bin_picking_number": "",
    "layout_file": "",
    "upc": "",
    "mpn": "",
    "gtin": "",
    "search_keywords": "",
    "availability": "available",
    "availability_description": "",
    "gift_wrapping_options_type": "any",
    "gift_wrapping_options_list": [],
    "sort_order": 0,
    "condition": "New",
    "is_condition_shown": false,
    "order_quantity_minimum": 0,
    "order_quantity_maximum": 0,
    "page_title": "BANGARANG PINK LEMONADE HARD SELTZER~2405",
    "meta_keywords": [],
    "meta_description": "",
    "date_created": "2021-03-22T08:43:18+00:00",
    "date_modified": "2021-12-06T01:21:58+00:00",
    "view_count": 0,
    "preorder_release_date": null,
    "preorder_message": "",
    "is_preorder_only": false,
    "is_price_hidden": false,
    "price_hidden_label": "",
    "custom_url": {
      "url": "/bangarang-pink-lemonade-hard-seltzer-2405/",
      "is_customized": false
    },
    "base_variant_id": null,
    "open_graph_type": "product",
    "open_graph_title": "",
    "open_graph_description": "",
    "open_graph_use_meta_description": true,
    "open_graph_use_product_name": true,
    "open_graph_use_image": true,
    "variants": [
      {
        "id": 4038910,
        "product_id": 825979,
        "sku": "2813004_2405",
        "sku_id": 3213099,
        "price": 2.85,
        "calculated_price": 2.85,
        "sale_price": 2.85,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 54,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 3214309,
            "label": "1 X Can 473 ml",
            "option_id": 825973,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 4038911,
        "product_id": 825979,
        "sku": "2813038_2405",
        "sku_id": 3213100,
        "price": 16.95,
        "calculated_price": 16.95,
        "sale_price": 16.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 3214310,
            "label": "6 X Can 473 ml",
            "option_id": 825973,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 4038912,
        "product_id": 825979,
        "sku": "2813041_2405",
        "sku_id": 3213101,
        "price": 60,
        "calculated_price": 60,
        "sale_price": 60,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 3214311,
            "label": "24 X Can 473 ml",
            "option_id": 825973,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 4038913,
        "product_id": 825979,
        "sku": "2813042_2405",
        "sku_id": 3213102,
        "price": 31.95,
        "calculated_price": 31.95,
        "sale_price": 31.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 3214312,
            "label": "12 X Can 473 ml",
            "option_id": 825973,
            "option_display_name": "Beer"
          }
        ]
      }
    ],
    "images": [],
    "primary_image": null,
    "custom_fields": [
      {
        "id": 11964156,
        "name": "ABV",
        "value": "5.00"
      },
      {
        "id": 11964157,
        "name": "Country",
        "value": "CA"
      },
      {
        "id": 11964158,
        "name": "Category",
        "value": "Ontario Craft"
      },
      {
        "id": 11964159,
        "name": "Type",
        "value": "Flavoured Malt"
      },
      {
        "id": 11964160,
        "name": "Beverage_type",
        "value": "Beer"
      },
      {
        "id": 11964161,
        "name": "Producer",
        "value": "BANGARANG BEVERAGE CO."
      },
      {
        "id": 11964162,
        "name": "Beer_attributes",
        "value": "Low Carb,Low Calorie"
      },
      {
        "id": 11964163,
        "name": "Price_Metadata_2813004",
        "value": "{\"current_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.32}],\"total_price\":2.85},\"previous_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.29}],\"total_price\":2.65},\"on_sale\":\"N\"}"
      },
      {
        "id": 11964164,
        "name": "Price_Metadata_2813038",
        "value": "{\"current_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.88}],\"total_price\":16.95},\"previous_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.00}],\"total_price\":0.00},\"on_sale\":\"N\"}"
      },
      {
        "id": 11964165,
        "name": "Price_Metadata_2813041",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":6.63}],\"total_price\":60.00},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.00}],\"total_price\":0.00},\"on_sale\":\"N\"}"
      },
      {
        "id": 11964166,
        "name": "Price_Metadata_2813042",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.54}],\"total_price\":31.95},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.00}],\"total_price\":0.00},\"on_sale\":\"N\"}"
      },
      {
        "id": 11964167,
        "name": "container_type ",
        "value": "C"
      },
      {
        "id": 11964168,
        "name": "pack",
        "value": "1,6,24,12"
      },
      {
        "id": 12151072,
        "name": "product_image_1",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/45cx657kw4c27qvxrkn89s/1298.png?position=1"
      },
      {
        "id": 12151073,
        "name": "product_image_2",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/45cx657kw4c27qvxrkn89s/1298.png?position=2"
      }
    ]
  },
  {
    "id": 462237,
    "name": "bavaria-8-6",
    "type": "physical",
    "sku": "2101_2405",
    "description": "Bavaria 8.6 is a beer with a high alcohol percentage of 8.6% by volume, hence the name. The beer is slightly darker than most lagers. The bitterness is well balanced, with a somewhat dry aftertaste. It's a smooth, strong beer!",
    "weight": 1,
    "width": 0,
    "depth": 0,
    "height": 0,
    "price": 67.95,
    "cost_price": 0,
    "retail_price": 0,
    "sale_price": 0,
    "map_price": 0,
    "tax_class_id": 0,
    "product_tax_code": "",
    "calculated_price": 67.95,
    "categories": [250, 232, 169, 186, 182, 165, 170, 167],
    "brand_id": 1030,
    "option_set_id": 462135,
    "option_set_display": "right",
    "inventory_level": 449,
    "inventory_warning_level": 0,
    "inventory_tracking": "variant",
    "reviews_rating_sum": 0,
    "reviews_count": 0,
    "total_sold": 24,
    "fixed_cost_shipping_price": 0,
    "is_free_shipping": false,
    "is_visible": true,
    "is_featured": false,
    "related_products": [-1],
    "warranty": "",
    "bin_picking_number": "",
    "layout_file": "",
    "upc": "",
    "mpn": "",
    "gtin": "",
    "search_keywords": "",
    "availability": "available",
    "availability_description": "",
    "gift_wrapping_options_type": "any",
    "gift_wrapping_options_list": [],
    "sort_order": 0,
    "condition": "New",
    "is_condition_shown": false,
    "order_quantity_minimum": 0,
    "order_quantity_maximum": 0,
    "page_title": "BAVARIA 8.6~2405",
    "meta_keywords": [],
    "meta_description": "",
    "date_created": "2020-10-16T13:24:51+00:00",
    "date_modified": "2021-12-22T11:25:13+00:00",
    "view_count": 0,
    "preorder_release_date": null,
    "preorder_message": "",
    "is_preorder_only": false,
    "is_price_hidden": false,
    "price_hidden_label": "",
    "custom_url": {
      "url": "/bavaria-8-6-2405/",
      "is_customized": false
    },
    "base_variant_id": null,
    "open_graph_type": "product",
    "open_graph_title": "",
    "open_graph_description": "",
    "open_graph_use_meta_description": true,
    "open_graph_use_product_name": true,
    "open_graph_use_image": true,
    "variants": [
      {
        "id": 2288138,
        "product_id": 462237,
        "sku": "1167063_2405",
        "sku_id": 1826069,
        "price": 3.3,
        "calculated_price": 3.3,
        "sale_price": 3.3,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 449,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1827271,
            "label": "1 X Can 500 ml",
            "option_id": 462231,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2288149,
        "product_id": 462237,
        "sku": "1167130_2405",
        "sku_id": 1826079,
        "price": 67.95,
        "calculated_price": 67.95,
        "sale_price": 67.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1827282,
            "label": "24 X Can 500 ml",
            "option_id": 462231,
            "option_display_name": "Beer"
          }
        ]
      }
    ],
    "images": [],
    "primary_image": null,
    "custom_fields": [
      {
        "id": 6651007,
        "name": "ABV",
        "value": "8.60"
      },
      {
        "id": 6651008,
        "name": "Country",
        "value": "NL"
      },
      {
        "id": 6651009,
        "name": "Category",
        "value": "Import"
      },
      {
        "id": 6651010,
        "name": "Type",
        "value": "Lager"
      },
      {
        "id": 6651011,
        "name": "Beverage_type",
        "value": "Beer"
      },
      {
        "id": 6651012,
        "name": "Producer",
        "value": "BAVARIA"
      },
      {
        "id": 6651013,
        "name": "Beer_styles",
        "value": "Strong,Pale"
      },
      {
        "id": 6651014,
        "name": "Price_Metadata_1167063",
        "value": "{\"current_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.37}],\"total_price\":3.30},\"previous_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.33}],\"total_price\":3.00},\"on_sale\":\"N\"}"
      },
      {
        "id": 6651015,
        "name": "Price_Metadata_1167130",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":7.54}],\"total_price\":67.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":7.58}],\"total_price\":68.30},\"on_sale\":\"N\"}"
      },
      {
        "id": 6651016,
        "name": "product_image_1",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbdaw0-1pypk0-c64lfe/2101.png?position=1"
      },
      {
        "id": 6651017,
        "name": "product_image_2",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbdaw0-1pypk0-c64lfe/2101.png?position=2"
      },
      {
        "id": 6651018,
        "name": "container_type ",
        "value": "C"
      },
      {
        "id": 6651019,
        "name": "pack",
        "value": "1,24"
      }
    ]
  },
  {
    "id": 462238,
    "name": "bavaria-premium-beer",
    "type": "physical",
    "sku": "2102_2405",
    "description": "Bavaria Premium Beer is a Pilsener type of beer with a smooth, neutral character. Brewed only with clear spring water, selected malts and hops. It's light in colour with a firm foamy head.",
    "weight": 1,
    "width": 0,
    "depth": 0,
    "height": 0,
    "price": 48.95,
    "cost_price": 0,
    "retail_price": 0,
    "sale_price": 0,
    "map_price": 0,
    "tax_class_id": 0,
    "product_tax_code": "",
    "calculated_price": 48.95,
    "categories": [250, 232, 169, 192, 165, 175, 176, 177, 170, 167],
    "brand_id": 1030,
    "option_set_id": 462136,
    "option_set_display": "right",
    "inventory_level": 404,
    "inventory_warning_level": 0,
    "inventory_tracking": "variant",
    "reviews_rating_sum": 0,
    "reviews_count": 0,
    "total_sold": 0,
    "fixed_cost_shipping_price": 0,
    "is_free_shipping": false,
    "is_visible": true,
    "is_featured": false,
    "related_products": [-1],
    "warranty": "",
    "bin_picking_number": "",
    "layout_file": "",
    "upc": "",
    "mpn": "",
    "gtin": "",
    "search_keywords": "",
    "availability": "available",
    "availability_description": "",
    "gift_wrapping_options_type": "any",
    "gift_wrapping_options_list": [],
    "sort_order": 0,
    "condition": "New",
    "is_condition_shown": false,
    "order_quantity_minimum": 0,
    "order_quantity_maximum": 0,
    "page_title": "BAVARIA PREMIUM BEER~2405",
    "meta_keywords": [],
    "meta_description": "",
    "date_created": "2020-10-16T13:24:51+00:00",
    "date_modified": "2021-12-22T19:20:47+00:00",
    "view_count": 0,
    "preorder_release_date": null,
    "preorder_message": "",
    "is_preorder_only": false,
    "is_price_hidden": false,
    "price_hidden_label": "",
    "custom_url": {
      "url": "/bavaria-premium-beer-2405/",
      "is_customized": false
    },
    "base_variant_id": null,
    "open_graph_type": "product",
    "open_graph_title": "",
    "open_graph_description": "",
    "open_graph_use_meta_description": true,
    "open_graph_use_product_name": true,
    "open_graph_use_image": true,
    "variants": [
      {
        "id": 2288152,
        "product_id": 462238,
        "sku": "1168012_2405",
        "sku_id": 1826082,
        "price": 23.6,
        "calculated_price": 23.6,
        "sale_price": 23.6,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1827284,
            "label": "12 X Bottle 330 ml",
            "option_id": 462232,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2288160,
        "product_id": 462238,
        "sku": "1168013_2405",
        "sku_id": 1826087,
        "price": 46.95,
        "calculated_price": 46.95,
        "sale_price": 46.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1827290,
            "label": "24 X Bottle 330 ml",
            "option_id": 462232,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2288171,
        "product_id": 462238,
        "sku": "1168063_2405",
        "sku_id": 1826096,
        "price": 2.3,
        "calculated_price": 2.3,
        "sale_price": 2.3,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 404,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1827298,
            "label": "1 X Can 500 ml",
            "option_id": 462232,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2288180,
        "product_id": 462238,
        "sku": "1168130_2405",
        "sku_id": 1826105,
        "price": 48.95,
        "calculated_price": 48.95,
        "sale_price": 48.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1827306,
            "label": "24 X Can 500 ml",
            "option_id": 462232,
            "option_display_name": "Beer"
          }
        ]
      }
    ],
    "images": [],
    "primary_image": null,
    "custom_fields": [
      {
        "id": 6651020,
        "name": "ABV",
        "value": "5.00"
      },
      {
        "id": 6651021,
        "name": "Country",
        "value": "NL"
      },
      {
        "id": 6651022,
        "name": "Category",
        "value": "Import"
      },
      {
        "id": 6651023,
        "name": "Type",
        "value": "Lager"
      },
      {
        "id": 6651024,
        "name": "Beverage_type",
        "value": "Beer"
      },
      {
        "id": 6651025,
        "name": "Producer",
        "value": "BAVARIA"
      },
      {
        "id": 6651026,
        "name": "Beer_styles",
        "value": "Pilsner"
      },
      {
        "id": 6651027,
        "name": "Price_Metadata_1168012",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.58}],\"total_price\":23.60},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.57}],\"total_price\":23.50},\"on_sale\":\"N\"}"
      },
      {
        "id": 6651028,
        "name": "Price_Metadata_1168013",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.13}],\"total_price\":46.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.13}],\"total_price\":46.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6651029,
        "name": "Price_Metadata_1168063",
        "value": "{\"current_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.25}],\"total_price\":2.30},\"previous_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.25}],\"total_price\":2.25},\"on_sale\":\"N\"}"
      },
      {
        "id": 6651030,
        "name": "Price_Metadata_1168130",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.36}],\"total_price\":48.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.93}],\"total_price\":53.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6651032,
        "name": "product_image_1",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbdaw0-1pypk0-g2suq2/2102.png?position=1"
      },
      {
        "id": 6651034,
        "name": "product_image_2",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbdaw0-1pypk0-g2suq2/2102.png?position=2"
      },
      {
        "id": 6651036,
        "name": "container_type ",
        "value": "B,C"
      },
      {
        "id": 6651038,
        "name": "pack",
        "value": "12,24,1"
      }
    ]
  },
  {
    "id": 462132,
    "name": "beach-day-every-day-berry-beach",
    "type": "physical",
    "sku": "1124_2405",
    "description": "This flavour is inspired by the iconic 3-color rocket shaped Popsicle. It is bursting with notes of cherries, lemons and blue raspberries.  ",
    "weight": 1,
    "width": 0,
    "depth": 0,
    "height": 0,
    "price": 10.95,
    "cost_price": 0,
    "retail_price": 0,
    "sale_price": 0,
    "map_price": 0,
    "tax_class_id": 0,
    "product_tax_code": "",
    "calculated_price": 10.95,
    "categories": [162, 163, 190, 275, 165, 170, 171, 167, 166, 172],
    "brand_id": 1030,
    "option_set_id": 462028,
    "option_set_display": "right",
    "inventory_level": 74,
    "inventory_warning_level": 0,
    "inventory_tracking": "variant",
    "reviews_rating_sum": 0,
    "reviews_count": 0,
    "total_sold": 0,
    "fixed_cost_shipping_price": 0,
    "is_free_shipping": false,
    "is_visible": true,
    "is_featured": false,
    "related_products": [-1],
    "warranty": "",
    "bin_picking_number": "",
    "layout_file": "",
    "upc": "",
    "mpn": "",
    "gtin": "",
    "search_keywords": "",
    "availability": "available",
    "availability_description": "",
    "gift_wrapping_options_type": "any",
    "gift_wrapping_options_list": [],
    "sort_order": 0,
    "condition": "New",
    "is_condition_shown": false,
    "order_quantity_minimum": 0,
    "order_quantity_maximum": 0,
    "page_title": "BEACH DAY EVERY DAY BERRY BEACH~2405",
    "meta_keywords": [],
    "meta_description": "",
    "date_created": "2020-10-16T13:24:48+00:00",
    "date_modified": "2021-12-21T17:18:21+00:00",
    "view_count": 0,
    "preorder_release_date": null,
    "preorder_message": "",
    "is_preorder_only": false,
    "is_price_hidden": false,
    "price_hidden_label": "",
    "custom_url": {
      "url": "/beach-day-every-day-berry-beach-2405/",
      "is_customized": false
    },
    "base_variant_id": null,
    "open_graph_type": "product",
    "open_graph_title": "",
    "open_graph_description": "",
    "open_graph_use_meta_description": true,
    "open_graph_use_product_name": true,
    "open_graph_use_image": true,
    "variants": [
      {
        "id": 2287641,
        "product_id": 462132,
        "sku": "2605004_2405",
        "sku_id": 1825673,
        "price": 2.85,
        "calculated_price": 2.85,
        "sale_price": 2.85,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 74,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826875,
            "label": "1 X Can 473 ml",
            "option_id": 462124,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2287651,
        "product_id": 462132,
        "sku": "2605097_2405",
        "sku_id": 1825682,
        "price": 10.95,
        "calculated_price": 10.95,
        "sale_price": 10.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826884,
            "label": "4 X Can 473 ml",
            "option_id": 462124,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 4352275,
        "product_id": 462132,
        "sku": "2605042_2405",
        "sku_id": 3454288,
        "price": 31.5,
        "calculated_price": 31.5,
        "sale_price": 31.5,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 3455510,
            "label": "12 X Can 473 ml",
            "option_id": 462124,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 4352283,
        "product_id": 462132,
        "sku": "2605041_2405",
        "sku_id": 3454296,
        "price": 59.95,
        "calculated_price": 59.95,
        "sale_price": 59.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 3455517,
            "label": "24 X Can 473 ml",
            "option_id": 462124,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 4352297,
        "product_id": 462132,
        "sku": "2605038_2405",
        "sku_id": 3454310,
        "price": 15.95,
        "calculated_price": 15.95,
        "sale_price": 15.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 3455528,
            "label": "6 X Can 473 ml",
            "option_id": 462124,
            "option_display_name": "Beer"
          }
        ]
      }
    ],
    "images": [],
    "primary_image": null,
    "custom_fields": [
      {
        "id": 6649457,
        "name": "ABV",
        "value": "5.40"
      },
      {
        "id": 6649461,
        "name": "Country",
        "value": "CA"
      },
      {
        "id": 6649463,
        "name": "Category",
        "value": "Domestic Specialty"
      },
      {
        "id": 6649466,
        "name": "Type",
        "value": "Flavoured Malt"
      },
      {
        "id": 6649470,
        "name": "Beverage_type",
        "value": "Beer"
      },
      {
        "id": 6649474,
        "name": "Producer",
        "value": "LABATT"
      },
      {
        "id": 6649480,
        "name": "Price_Metadata_2605004",
        "value": "{\"current_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.32}],\"total_price\":2.85},\"previous_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.32}],\"total_price\":2.85},\"on_sale\":\"N\"}"
      },
      {
        "id": 6649485,
        "name": "Price_Metadata_2605097",
        "value": "{\"current_price\":{\"deposit\":0.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.21}],\"total_price\":10.95},\"previous_price\":{\"deposit\":0.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.21}],\"total_price\":10.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6649489,
        "name": "product_image_1",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qg0y87-935hyw-by86v6/1124.png?position=1"
      },
      {
        "id": 6649494,
        "name": "product_image_2",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qg0y87-935hyw-by86v6/1124.png?position=2"
      },
      {
        "id": 6649499,
        "name": "container_type ",
        "value": "C"
      },
      {
        "id": 6649505,
        "name": "pack",
        "value": "1,4"
      },
      {
        "id": 13083807,
        "name": "Price_Metadata_2605038",
        "value": "{\"current_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.77}],\"total_price\":15.95},\"previous_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.00}],\"total_price\":0.00},\"on_sale\":\"N\"}"
      },
      {
        "id": 13083808,
        "name": "Price_Metadata_2605041",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":6.62}],\"total_price\":59.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.00}],\"total_price\":0.00},\"on_sale\":\"N\"}"
      },
      {
        "id": 13083809,
        "name": "Price_Metadata_2605042",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.49}],\"total_price\":31.50},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.00}],\"total_price\":0.00},\"on_sale\":\"N\"}"
      }
    ]
  },
  {
    "id": 889363,
    "name": "beach-day-every-day-mixer-pack",
    "type": "physical",
    "sku": "1562_2405",
    "description": "Introducing the new variety pack from Beach Day Every Day, bursting with 3 bold flavours: Berry Beach, Beach Peach & Pink Lemonade.These drinks will take you back in time, inspired by the classic, and sure to delight your taste buds.",
    "weight": 1,
    "width": 0,
    "depth": 0,
    "height": 0,
    "price": 48.95,
    "cost_price": 0,
    "retail_price": 0,
    "sale_price": 0,
    "map_price": 0,
    "tax_class_id": 0,
    "product_tax_code": "",
    "calculated_price": 48.95,
    "categories": [162, 163, 164, 165, 166, 167],
    "brand_id": 1030,
    "option_set_id": 889261,
    "option_set_display": "right",
    "inventory_level": 5,
    "inventory_warning_level": 0,
    "inventory_tracking": "variant",
    "reviews_rating_sum": 0,
    "reviews_count": 0,
    "total_sold": 0,
    "fixed_cost_shipping_price": 0,
    "is_free_shipping": false,
    "is_visible": true,
    "is_featured": false,
    "related_products": [-1],
    "warranty": "",
    "bin_picking_number": "",
    "layout_file": "",
    "upc": "",
    "mpn": "",
    "gtin": "",
    "search_keywords": "",
    "availability": "available",
    "availability_description": "",
    "gift_wrapping_options_type": "any",
    "gift_wrapping_options_list": [],
    "sort_order": 0,
    "condition": "New",
    "is_condition_shown": false,
    "order_quantity_minimum": 0,
    "order_quantity_maximum": 0,
    "page_title": "BEACH DAY EVERY DAY MIXER PACK~2405",
    "meta_keywords": [],
    "meta_description": "",
    "date_created": "2021-10-28T10:19:01+00:00",
    "date_modified": "2021-12-21T17:18:21+00:00",
    "view_count": 0,
    "preorder_release_date": null,
    "preorder_message": "",
    "is_preorder_only": false,
    "is_price_hidden": false,
    "price_hidden_label": "",
    "custom_url": {
      "url": "/beach-day-every-day-mixer-pack-2405/",
      "is_customized": false
    },
    "base_variant_id": null,
    "open_graph_type": "product",
    "open_graph_title": "",
    "open_graph_description": "",
    "open_graph_use_meta_description": true,
    "open_graph_use_product_name": true,
    "open_graph_use_image": true,
    "variants": [
      {
        "id": 4303033,
        "product_id": 889363,
        "sku": "3031031_2405",
        "sku_id": 3413838,
        "price": 27.95,
        "calculated_price": 27.95,
        "sale_price": 27.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 5,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 3415048,
            "label": "12 X Can 355 ml",
            "option_id": 889357,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 4303034,
        "product_id": 889363,
        "sku": "3031033_2405",
        "sku_id": 3413839,
        "price": 48.95,
        "calculated_price": 48.95,
        "sale_price": 48.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 3415049,
            "label": "24 X Can 355 ml",
            "option_id": 889357,
            "option_display_name": "Beer"
          }
        ]
      }
    ],
    "images": [],
    "primary_image": null,
    "custom_fields": [
      {
        "id": 12953001,
        "name": "ABV",
        "value": "5.40"
      },
      {
        "id": 12953002,
        "name": "Country",
        "value": "CA"
      },
      {
        "id": 12953003,
        "name": "Category",
        "value": "Domestic Specialty"
      },
      {
        "id": 12953004,
        "name": "Type",
        "value": "Mixed Case"
      },
      {
        "id": 12953005,
        "name": "Beverage_type",
        "value": "Beer"
      },
      {
        "id": 12953006,
        "name": "Producer",
        "value": "LABATT"
      },
      {
        "id": 12953007,
        "name": "Price_Metadata_3031031",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.08}],\"total_price\":27.95},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.00}],\"total_price\":0.00},\"on_sale\":\"N\"}"
      },
      {
        "id": 12953008,
        "name": "Price_Metadata_3031033",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.36}],\"total_price\":48.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.00}],\"total_price\":0.00},\"on_sale\":\"N\"}"
      },
      {
        "id": 12953009,
        "name": "container_type ",
        "value": "C"
      },
      {
        "id": 12953010,
        "name": "pack",
        "value": "12,24"
      }
    ]
  },
  {
    "id": 462081,
    "name": "beaus-country-vibes-amber-lagered-ale",
    "type": "physical",
    "sku": "1049_2405",
    "description": "A delicious mash up of lager and amber ale. Roasted caramel malts give a rich amber colour. The aroma is malty and toasty. The flavour is malt-driven, with mildly sweet bread notes that are balanced by a clean hop bitterness and a bright lager finish.",
    "weight": 1,
    "width": 0,
    "depth": 0,
    "height": 0,
    "price": 24.95,
    "cost_price": 0,
    "retail_price": 0,
    "sale_price": 0,
    "map_price": 0,
    "tax_class_id": 0,
    "product_tax_code": "",
    "calculated_price": 24.95,
    "categories": [162, 198, 178, 200, 165, 170, 167, 173],
    "brand_id": 1030,
    "option_set_id": 461979,
    "option_set_display": "right",
    "inventory_level": 15,
    "inventory_warning_level": 0,
    "inventory_tracking": "variant",
    "reviews_rating_sum": 0,
    "reviews_count": 0,
    "total_sold": 0,
    "fixed_cost_shipping_price": 0,
    "is_free_shipping": false,
    "is_visible": true,
    "is_featured": false,
    "related_products": [-1],
    "warranty": "",
    "bin_picking_number": "",
    "layout_file": "",
    "upc": "",
    "mpn": "",
    "gtin": "",
    "search_keywords": "",
    "availability": "available",
    "availability_description": "",
    "gift_wrapping_options_type": "any",
    "gift_wrapping_options_list": [],
    "sort_order": 0,
    "condition": "New",
    "is_condition_shown": false,
    "order_quantity_minimum": 0,
    "order_quantity_maximum": 0,
    "page_title": "BEAUS COUNTRY VIBES AMBER LAGERED ALE~2405",
    "meta_keywords": [],
    "meta_description": "",
    "date_created": "2020-10-16T13:24:47+00:00",
    "date_modified": "2021-12-11T19:20:46+00:00",
    "view_count": 0,
    "preorder_release_date": null,
    "preorder_message": "",
    "is_preorder_only": false,
    "is_price_hidden": false,
    "price_hidden_label": "",
    "custom_url": {
      "url": "/beaus-country-vibes-amber-lagered-ale-2405/",
      "is_customized": false
    },
    "base_variant_id": null,
    "open_graph_type": "product",
    "open_graph_title": "",
    "open_graph_description": "",
    "open_graph_use_meta_description": true,
    "open_graph_use_product_name": true,
    "open_graph_use_image": true,
    "variants": [
      {
        "id": 2287445,
        "product_id": 462081,
        "sku": "2536004_2405",
        "sku_id": 1825532,
        "price": 3.45,
        "calculated_price": 3.45,
        "sale_price": 3.45,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 15,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826734,
            "label": "1 X Can 473 ml",
            "option_id": 462075,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2287456,
        "product_id": 462081,
        "sku": "2536041_2405",
        "sku_id": 1825540,
        "price": 72.05,
        "calculated_price": 72.05,
        "sale_price": 72.05,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826742,
            "label": "24 X Can 473 ml",
            "option_id": 462075,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 4256926,
        "product_id": 462081,
        "sku": "2536156_2405",
        "sku_id": 3379429,
        "price": 24.95,
        "calculated_price": 24.95,
        "sale_price": 24.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 3380641,
            "label": "8 X Can 473 ml",
            "option_id": 462075,
            "option_display_name": "Beer"
          }
        ]
      }
    ],
    "images": [],
    "primary_image": null,
    "custom_fields": [
      {
        "id": 6648824,
        "name": "ABV",
        "value": "4.70"
      },
      {
        "id": 6648828,
        "name": "Country",
        "value": "CA"
      },
      {
        "id": 6648831,
        "name": "Category",
        "value": "Ontario Craft"
      },
      {
        "id": 6648833,
        "name": "Type",
        "value": "Ale"
      },
      {
        "id": 6648835,
        "name": "Beverage_type",
        "value": "Beer"
      },
      {
        "id": 6648836,
        "name": "Producer",
        "value": "BEAUS ALL NATURAL BREWING CO"
      },
      {
        "id": 6648837,
        "name": "Beer_styles",
        "value": "Amber"
      },
      {
        "id": 6648838,
        "name": "Price_Metadata_2536004",
        "value": "{\"current_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.39}],\"total_price\":3.45},\"previous_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.36}],\"total_price\":3.25},\"on_sale\":\"N\"}"
      },
      {
        "id": 6648839,
        "name": "Price_Metadata_2536041",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":8.01}],\"total_price\":72.05},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":8.10}],\"total_price\":72.80},\"on_sale\":\"N\"}"
      },
      {
        "id": 6648840,
        "name": "container_type ",
        "value": "C"
      },
      {
        "id": 6648841,
        "name": "pack",
        "value": "1,24"
      },
      {
        "id": 11672806,
        "name": "product_image_1",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/jbw9x4sqnt3skv87wjsgzmt/1049_low.png?position=1"
      },
      {
        "id": 11672807,
        "name": "product_image_2",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/jbw9x4sqnt3skv87wjsgzmt/1049_low.png?position=2"
      },
      {
        "id": 12780785,
        "name": "Price_Metadata_2536156",
        "value": "{\"current_price\":{\"deposit\":0.80,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.78}],\"total_price\":24.95},\"previous_price\":{\"deposit\":0.80,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.00}],\"total_price\":0.00},\"on_sale\":\"N\"}"
      }
    ]
  },
  {
    "id": 804525,
    "name": "beaus-lug-2-5-lagered-ale",
    "type": "physical",
    "sku": "1167_2405",
    "description": "This extra light beer delivers a delicious, full flavoured craft beer experience without compromise. The recipe is modeled on our popular Lug Tread, so expect a satisfying, balanced and tasty lagered ale, with less alcohol.",
    "weight": 1,
    "width": 0,
    "depth": 0,
    "height": 0,
    "price": 24.25,
    "cost_price": 0,
    "retail_price": 0,
    "sale_price": 0,
    "map_price": 0,
    "tax_class_id": 0,
    "product_tax_code": "",
    "calculated_price": 24.25,
    "categories": [162, 198, 189, 165, 170, 167, 173],
    "brand_id": 1030,
    "option_set_id": 804423,
    "option_set_display": "right",
    "inventory_level": 21,
    "inventory_warning_level": 0,
    "inventory_tracking": "variant",
    "reviews_rating_sum": 0,
    "reviews_count": 0,
    "total_sold": 0,
    "fixed_cost_shipping_price": 0,
    "is_free_shipping": false,
    "is_visible": true,
    "is_featured": false,
    "related_products": [-1],
    "warranty": "",
    "bin_picking_number": "",
    "layout_file": "",
    "upc": "",
    "mpn": "",
    "gtin": "",
    "search_keywords": "",
    "availability": "available",
    "availability_description": "",
    "gift_wrapping_options_type": "any",
    "gift_wrapping_options_list": [],
    "sort_order": 0,
    "condition": "New",
    "is_condition_shown": false,
    "order_quantity_minimum": 0,
    "order_quantity_maximum": 0,
    "page_title": "BEAUS LUG 2.5 LAGERED ALE~2405",
    "meta_keywords": [],
    "meta_description": "",
    "date_created": "2021-01-21T09:38:42+00:00",
    "date_modified": "2021-11-10T23:23:03+00:00",
    "view_count": 0,
    "preorder_release_date": null,
    "preorder_message": "",
    "is_preorder_only": false,
    "is_price_hidden": false,
    "price_hidden_label": "",
    "custom_url": {
      "url": "/beaus-lug-2-5-lagered-ale-2405/",
      "is_customized": false
    },
    "base_variant_id": null,
    "open_graph_type": "product",
    "open_graph_title": "",
    "open_graph_description": "",
    "open_graph_use_meta_description": true,
    "open_graph_use_product_name": true,
    "open_graph_use_image": true,
    "variants": [
      {
        "id": 3953115,
        "product_id": 804525,
        "sku": "2650004_2405",
        "sku_id": 3148758,
        "price": 3.35,
        "calculated_price": 3.35,
        "sale_price": 3.35,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 21,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 3149968,
            "label": "1 X Can 473 ml",
            "option_id": 804519,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 3953116,
        "product_id": 804525,
        "sku": "2650041_2405",
        "sku_id": 3148759,
        "price": 69.95,
        "calculated_price": 69.95,
        "sale_price": 69.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 3149969,
            "label": "24 X Can 473 ml",
            "option_id": 804519,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 4256931,
        "product_id": 804525,
        "sku": "2650156_2405",
        "sku_id": 3379434,
        "price": 24.25,
        "calculated_price": 24.25,
        "sale_price": 24.25,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 3380646,
            "label": "8 X Can 473 ml",
            "option_id": 804519,
            "option_display_name": "Beer"
          }
        ]
      }
    ],
    "images": [],
    "primary_image": null,
    "custom_fields": [
      {
        "id": 11643548,
        "name": "ABV",
        "value": "2.50"
      },
      {
        "id": 11643549,
        "name": "Country",
        "value": "CA"
      },
      {
        "id": 11643550,
        "name": "Category",
        "value": "Ontario Craft"
      },
      {
        "id": 11643551,
        "name": "Type",
        "value": "Light"
      },
      {
        "id": 11643552,
        "name": "Beverage_type",
        "value": "Beer"
      },
      {
        "id": 11643553,
        "name": "Producer",
        "value": "BEAUS ALL NATURAL BREWING CO"
      },
      {
        "id": 11643554,
        "name": "Price_Metadata_2650004",
        "value": "{\"current_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.37}],\"total_price\":3.35},\"previous_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.35}],\"total_price\":3.15},\"on_sale\":\"N\"}"
      },
      {
        "id": 11643555,
        "name": "Price_Metadata_2650041",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":7.77}],\"total_price\":69.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":8.97}],\"total_price\":80.40},\"on_sale\":\"N\"}"
      },
      {
        "id": 11643556,
        "name": "product_image_1",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/cbvvfqk54sn8r68hpw5jktn/1167.png?position=1"
      },
      {
        "id": 11643557,
        "name": "product_image_2",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/cbvvfqk54sn8r68hpw5jktn/1167.png?position=2"
      },
      {
        "id": 11643558,
        "name": "container_type ",
        "value": "C"
      },
      {
        "id": 11643559,
        "name": "pack",
        "value": "1,24"
      },
      {
        "id": 12780780,
        "name": "Price_Metadata_2650156",
        "value": "{\"current_price\":{\"deposit\":0.80,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.70}],\"total_price\":24.25},\"previous_price\":{\"deposit\":0.80,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.00}],\"total_price\":0.00},\"on_sale\":\"N\"}"
      }
    ]
  },
  {
    "id": 462782,
    "name": "beaus-lug-tread-lagered-ale",
    "type": "physical",
    "sku": "8611_2405",
    "description": "Brilliantly clear straw gold colour with rocky white foam; aromas of pilsner malt and grassy herbal hops, with light apple; forwardflavours of sweet grain malt followed by balanced hop bitterness; clean and dry on the finish.",
    "weight": 1,
    "width": 0,
    "depth": 0,
    "height": 0,
    "price": 59.95,
    "cost_price": 0,
    "retail_price": 0,
    "sale_price": 0,
    "map_price": 0,
    "tax_class_id": 0,
    "product_tax_code": "",
    "calculated_price": 59.95,
    "categories": [
      162, 198, 178, 179, 180, 165, 175, 170, 181, 166, 167, 171, 173, 172,
      252, 188, 271
    ],
    "brand_id": 1030,
    "option_set_id": 462684,
    "option_set_display": "right",
    "inventory_level": 263,
    "inventory_warning_level": 0,
    "inventory_tracking": "variant",
    "reviews_rating_sum": 0,
    "reviews_count": 0,
    "total_sold": 35,
    "fixed_cost_shipping_price": 0,
    "is_free_shipping": false,
    "is_visible": true,
    "is_featured": false,
    "related_products": [-1],
    "warranty": "",
    "bin_picking_number": "",
    "layout_file": "",
    "upc": "",
    "mpn": "",
    "gtin": "",
    "search_keywords": "",
    "availability": "available",
    "availability_description": "",
    "gift_wrapping_options_type": "any",
    "gift_wrapping_options_list": [],
    "sort_order": 0,
    "condition": "New",
    "is_condition_shown": false,
    "order_quantity_minimum": 0,
    "order_quantity_maximum": 0,
    "page_title": "BEAUS LUG-TREAD LAGERED ALE~2405",
    "meta_keywords": [],
    "meta_description": "",
    "date_created": "2020-10-16T13:25:07+00:00",
    "date_modified": "2021-12-22T19:20:46+00:00",
    "view_count": 0,
    "preorder_release_date": null,
    "preorder_message": "",
    "is_preorder_only": false,
    "is_price_hidden": false,
    "price_hidden_label": "",
    "custom_url": {
      "url": "/beaus-lug-tread-lagered-ale-2405/",
      "is_customized": false
    },
    "base_variant_id": null,
    "open_graph_type": "product",
    "open_graph_title": "",
    "open_graph_description": "",
    "open_graph_use_meta_description": true,
    "open_graph_use_product_name": true,
    "open_graph_use_image": true,
    "variants": [
      {
        "id": 2290719,
        "product_id": 462782,
        "sku": "1709004_2405",
        "sku_id": 1828099,
        "price": 3.35,
        "calculated_price": 3.35,
        "sale_price": 3.35,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 177,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1829301,
            "label": "1 X Can 473 ml",
            "option_id": 462780,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290730,
        "product_id": 462782,
        "sku": "1709028_2405",
        "sku_id": 1828108,
        "price": 243.25,
        "calculated_price": 243.25,
        "sale_price": 243.25,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1829310,
            "label": "1 X Keg 30000 ml",
            "option_id": 462780,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290738,
        "product_id": 462782,
        "sku": "1709031_2405",
        "sku_id": 1828114,
        "price": 28.5,
        "calculated_price": 26.5,
        "sale_price": 26.5,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 55,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1829317,
            "label": "12 X Can 355 ml",
            "option_id": 462780,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290749,
        "product_id": 462782,
        "sku": "1709033_2405",
        "sku_id": 1828121,
        "price": 49.95,
        "calculated_price": 49.95,
        "sale_price": 49.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1829324,
            "label": "24 X Can 355 ml",
            "option_id": 462780,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290757,
        "product_id": 462782,
        "sku": "1709038_2405",
        "sku_id": 1828129,
        "price": 18.45,
        "calculated_price": 17.45,
        "sale_price": 17.45,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 30,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1829331,
            "label": "6 X Can 473 ml",
            "option_id": 462780,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290763,
        "product_id": 462782,
        "sku": "1709041_2405",
        "sku_id": 1828132,
        "price": 67.95,
        "calculated_price": 67.95,
        "sale_price": 67.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1829335,
            "label": "24 X Can 473 ml",
            "option_id": 462780,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290774,
        "product_id": 462782,
        "sku": "1709049_2405",
        "sku_id": 1828139,
        "price": 152.2,
        "calculated_price": 152.2,
        "sale_price": 152.2,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1829341,
            "label": "1 X Keg 20000 ml",
            "option_id": 462780,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290784,
        "product_id": 462782,
        "sku": "1709068_2405",
        "sku_id": 1828145,
        "price": 360.75,
        "calculated_price": 360.75,
        "sale_price": 360.75,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1829348,
            "label": "1 X Keg 50000 ml",
            "option_id": 462780,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290800,
        "product_id": 462782,
        "sku": "1709296_2405",
        "sku_id": 1828158,
        "price": 9.95,
        "calculated_price": 9.95,
        "sale_price": 9.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1829360,
            "label": "4 X Can 355 ml",
            "option_id": 462780,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290813,
        "product_id": 462782,
        "sku": "1709628_2405",
        "sku_id": 1828167,
        "price": 17.95,
        "calculated_price": 17.95,
        "sale_price": 17.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1829370,
            "label": "4 X Bottle 600 ml",
            "option_id": 462780,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290838,
        "product_id": 462782,
        "sku": "1709630_2405",
        "sku_id": 1828185,
        "price": 34.95,
        "calculated_price": 34.95,
        "sale_price": 34.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 1,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1829387,
            "label": "8 X Bottle 600 ml",
            "option_id": 462780,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290860,
        "product_id": 462782,
        "sku": "1709631_2405",
        "sku_id": 1828204,
        "price": 59.95,
        "calculated_price": 59.95,
        "sale_price": 59.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1829407,
            "label": "16 X Bottle 600 ml",
            "option_id": 462780,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 4234622,
        "product_id": 462782,
        "sku": "1709042_2405",
        "sku_id": 3362495,
        "price": 34.95,
        "calculated_price": 34.95,
        "sale_price": 34.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 3363705,
            "label": "12 X Can 473 ml",
            "option_id": 462780,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 4265440,
        "product_id": 462782,
        "sku": "1709156_2405",
        "sku_id": 3385697,
        "price": 24.25,
        "calculated_price": 24.25,
        "sale_price": 24.25,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 3386907,
            "label": "8 X Can 473 ml",
            "option_id": 462780,
            "option_display_name": "Beer"
          }
        ]
      }
    ],
    "images": [],
    "primary_image": null,
    "custom_fields": [
      {
        "id": 6658880,
        "name": "ABV",
        "value": "5.20"
      },
      {
        "id": 6658885,
        "name": "Country",
        "value": "CA"
      },
      {
        "id": 6658887,
        "name": "Category",
        "value": "Ontario Craft"
      },
      {
        "id": 6658891,
        "name": "Type",
        "value": "Ale"
      },
      {
        "id": 6658896,
        "name": "Beverage_type",
        "value": "Beer"
      },
      {
        "id": 6658900,
        "name": "Producer",
        "value": "BEAUS ALL NATURAL BREWING CO"
      },
      {
        "id": 6658905,
        "name": "Price_Metadata_1709004",
        "value": "{\"current_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.37}],\"total_price\":3.35},\"previous_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.35}],\"total_price\":3.15},\"on_sale\":\"N\"}"
      },
      {
        "id": 6658910,
        "name": "Price_Metadata_1709028",
        "value": "{\"current_price\":{\"deposit\":50.00,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":22.23}],\"total_price\":243.25},\"previous_price\":{\"deposit\":50.00,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":22.23}],\"total_price\":243.25},\"on_sale\":\"N\"}"
      },
      {
        "id": 6658915,
        "name": "Price_Metadata_1709031",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.91}],\"total_price\":26.50},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.14}],\"total_price\":28.50},\"on_sale\":\"Y\"}"
      },
      {
        "id": 6658921,
        "name": "Price_Metadata_1709033",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.47}],\"total_price\":49.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":6.05}],\"total_price\":54.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6658924,
        "name": "Price_Metadata_1709038",
        "value": "{\"current_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.94}],\"total_price\":17.45},\"previous_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.05}],\"total_price\":18.45},\"on_sale\":\"Y\"}"
      },
      {
        "id": 6658930,
        "name": "Price_Metadata_1709041",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":7.54}],\"total_price\":67.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":7.54}],\"total_price\":67.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6658933,
        "name": "Price_Metadata_1709049",
        "value": "{\"current_price\":{\"deposit\":20.00,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":15.21}],\"total_price\":152.20},\"previous_price\":{\"deposit\":20.00,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":15.21}],\"total_price\":152.20},\"on_sale\":\"N\"}"
      },
      {
        "id": 6658938,
        "name": "Price_Metadata_1709068",
        "value": "{\"current_price\":{\"deposit\":50.00,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":35.75}],\"total_price\":360.75},\"previous_price\":{\"deposit\":50.00,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":35.75}],\"total_price\":360.75},\"on_sale\":\"N\"}"
      },
      {
        "id": 6658943,
        "name": "Price_Metadata_1709296",
        "value": "{\"current_price\":{\"deposit\":0.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.10}],\"total_price\":9.95},\"previous_price\":{\"deposit\":0.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.10}],\"total_price\":9.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6658946,
        "name": "Price_Metadata_1709628",
        "value": "{\"current_price\":{\"deposit\":0.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.02}],\"total_price\":17.95},\"previous_price\":{\"deposit\":0.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.02}],\"total_price\":17.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6658950,
        "name": "Price_Metadata_1709630",
        "value": "{\"current_price\":{\"deposit\":0.80,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.93}],\"total_price\":34.95},\"previous_price\":{\"deposit\":0.80,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.93}],\"total_price\":34.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6658952,
        "name": "Price_Metadata_1709631",
        "value": "{\"current_price\":{\"deposit\":1.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":6.71}],\"total_price\":59.95},\"previous_price\":{\"deposit\":1.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":6.71}],\"total_price\":59.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6658955,
        "name": "product_image_1",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbduj3-elyndk-fru7da/8611.png?position=1"
      },
      {
        "id": 6658958,
        "name": "product_image_2",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbduj3-elyndk-fru7da/8611.png?position=2"
      },
      {
        "id": 6658962,
        "name": "container_type ",
        "value": "C,K,B"
      },
      {
        "id": 6658963,
        "name": "pack",
        "value": "1,12,24,6,4,8,16"
      },
      {
        "id": 12707926,
        "name": "Price_Metadata_1709042",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.88}],\"total_price\":34.95},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.99}],\"total_price\":35.85},\"on_sale\":\"N\"}"
      },
      {
        "id": 12810255,
        "name": "Price_Metadata_1709156",
        "value": "{\"current_price\":{\"deposit\":0.80,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.70}],\"total_price\":24.25},\"previous_price\":{\"deposit\":0.80,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.00}],\"total_price\":0.00},\"on_sale\":\"N\"}"
      }
    ]
  },
  {
    "id": 893974,
    "name": "beaus-porter-porter",
    "type": "physical",
    "sku": "1552_2405",
    "description": "This is a traditional-style brown porter brewed with chicory and a hint of vanilla. It offers immediate aromas of chocolate and espresso, mingled with malty sweetness. Roasty flavours lead into a dry, crisp finish, with lingering notes of vanilla.",
    "weight": 1,
    "width": 0,
    "depth": 0,
    "height": 0,
    "price": 87.6,
    "cost_price": 0,
    "retail_price": 0,
    "sale_price": 0,
    "map_price": 0,
    "tax_class_id": 0,
    "product_tax_code": "",
    "calculated_price": 87.6,
    "categories": [162, 198, 229, 216, 165, 170, 167],
    "brand_id": 1030,
    "option_set_id": 893872,
    "option_set_display": "right",
    "inventory_level": 61,
    "inventory_warning_level": 0,
    "inventory_tracking": "variant",
    "reviews_rating_sum": 0,
    "reviews_count": 0,
    "total_sold": 0,
    "fixed_cost_shipping_price": 0,
    "is_free_shipping": false,
    "is_visible": true,
    "is_featured": false,
    "related_products": [-1],
    "warranty": "",
    "bin_picking_number": "",
    "layout_file": "",
    "upc": "",
    "mpn": "",
    "gtin": "",
    "search_keywords": "",
    "availability": "available",
    "availability_description": "",
    "gift_wrapping_options_type": "any",
    "gift_wrapping_options_list": [],
    "sort_order": 0,
    "condition": "New",
    "is_condition_shown": false,
    "order_quantity_minimum": 0,
    "order_quantity_maximum": 0,
    "page_title": "BEAUS PORTER PORTER~2405",
    "meta_keywords": [],
    "meta_description": "",
    "date_created": "2021-11-27T11:19:20+00:00",
    "date_modified": "2021-12-12T13:17:03+00:00",
    "view_count": 0,
    "preorder_release_date": null,
    "preorder_message": "",
    "is_preorder_only": false,
    "is_price_hidden": false,
    "price_hidden_label": "",
    "custom_url": {
      "url": "/beaus-porter-porter-2405/",
      "is_customized": false
    },
    "base_variant_id": null,
    "open_graph_type": "product",
    "open_graph_title": "",
    "open_graph_description": "",
    "open_graph_use_meta_description": true,
    "open_graph_use_product_name": true,
    "open_graph_use_image": true,
    "variants": [
      {
        "id": 4326081,
        "product_id": 893974,
        "sku": "3020004_2405",
        "sku_id": 3432275,
        "price": 3.65,
        "calculated_price": 3.65,
        "sale_price": 3.65,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 61,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 3433485,
            "label": "1 X Can 473 ml",
            "option_id": 893968,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 4326082,
        "product_id": 893974,
        "sku": "3020041_2405",
        "sku_id": 3432276,
        "price": 87.6,
        "calculated_price": 87.6,
        "sale_price": 87.6,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 3433486,
            "label": "24 X Can 473 ml",
            "option_id": 893968,
            "option_display_name": "Beer"
          }
        ]
      }
    ],
    "images": [],
    "primary_image": null,
    "custom_fields": [
      {
        "id": 13021534,
        "name": "ABV",
        "value": "5.50"
      },
      {
        "id": 13021535,
        "name": "Country",
        "value": "CA"
      },
      {
        "id": 13021536,
        "name": "Category",
        "value": "Ontario Craft"
      },
      {
        "id": 13021537,
        "name": "Type",
        "value": "Porter"
      },
      {
        "id": 13021538,
        "name": "Beverage_type",
        "value": "Beer"
      },
      {
        "id": 13021539,
        "name": "Producer",
        "value": "BEAUS ALL NATURAL BREWING CO"
      },
      {
        "id": 13021540,
        "name": "Beer_styles",
        "value": "Dark"
      },
      {
        "id": 13021541,
        "name": "Price_Metadata_3020004",
        "value": "{\"current_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.41}],\"total_price\":3.65},\"previous_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.00}],\"total_price\":0.00},\"on_sale\":\"N\"}"
      },
      {
        "id": 13021542,
        "name": "Price_Metadata_3020041",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":9.80}],\"total_price\":87.60},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.00}],\"total_price\":0.00},\"on_sale\":\"N\"}"
      },
      {
        "id": 13021543,
        "name": "product_image_1",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/9pp8bzcbrhwk5ph9gwc3c4p7/1552.png?position=1"
      },
      {
        "id": 13021544,
        "name": "product_image_2",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/9pp8bzcbrhwk5ph9gwc3c4p7/1552.png?position=2"
      },
      {
        "id": 13021545,
        "name": "container_type ",
        "value": "C"
      },
      {
        "id": 13021546,
        "name": "pack",
        "value": "1,24"
      }
    ]
  },
  {
    "id": 462164,
    "name": "becks-non-alcoholic-0-0",
    "type": "physical",
    "sku": "1302_2405",
    "description": "Brewed under the German purity law of 1516, only after the brewing process is complete is the alcohol removed, producing the distinct, crisp, clear taste you would expect from Becks bier, but without the alcohol.",
    "weight": 1,
    "width": 0,
    "depth": 0,
    "height": 0,
    "price": 29.95,
    "cost_price": 0,
    "retail_price": 0,
    "sale_price": 0,
    "map_price": 0,
    "tax_class_id": 0,
    "product_tax_code": "",
    "calculated_price": 29.95,
    "categories": [243, 193, 194, 175, 202, 176, 177],
    "brand_id": 1030,
    "option_set_id": 462062,
    "option_set_display": "right",
    "inventory_level": 17,
    "inventory_warning_level": 0,
    "inventory_tracking": "variant",
    "reviews_rating_sum": 0,
    "reviews_count": 0,
    "total_sold": 0,
    "fixed_cost_shipping_price": 0,
    "is_free_shipping": false,
    "is_visible": true,
    "is_featured": false,
    "related_products": [-1],
    "warranty": "",
    "bin_picking_number": "",
    "layout_file": "",
    "upc": "",
    "mpn": "",
    "gtin": "",
    "search_keywords": "",
    "availability": "available",
    "availability_description": "",
    "gift_wrapping_options_type": "any",
    "gift_wrapping_options_list": [],
    "sort_order": 0,
    "condition": "New",
    "is_condition_shown": false,
    "order_quantity_minimum": 0,
    "order_quantity_maximum": 0,
    "page_title": "BECKS NON ALCOHOLIC 0.0~2405",
    "meta_keywords": [],
    "meta_description": "",
    "date_created": "2020-10-16T13:24:49+00:00",
    "date_modified": "2021-12-21T17:18:21+00:00",
    "view_count": 0,
    "preorder_release_date": null,
    "preorder_message": "",
    "is_preorder_only": false,
    "is_price_hidden": false,
    "price_hidden_label": "",
    "custom_url": {
      "url": "/becks-non-alcoholic-0-0-2405/",
      "is_customized": false
    },
    "base_variant_id": null,
    "open_graph_type": "product",
    "open_graph_title": "",
    "open_graph_description": "",
    "open_graph_use_meta_description": true,
    "open_graph_use_product_name": true,
    "open_graph_use_image": true,
    "variants": [
      {
        "id": 2287758,
        "product_id": 462164,
        "sku": "1932011_2405",
        "sku_id": 1825760,
        "price": 9.95,
        "calculated_price": 9.95,
        "sale_price": 9.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 17,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826962,
            "label": "6 X Bottle 330 ml",
            "option_id": 462158,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2287763,
        "product_id": 462164,
        "sku": "1932012_2405",
        "sku_id": 1825765,
        "price": 18.95,
        "calculated_price": 18.95,
        "sale_price": 18.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826967,
            "label": "12 X Bottle 330 ml",
            "option_id": 462158,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2287770,
        "product_id": 462164,
        "sku": "1932013_2405",
        "sku_id": 1825770,
        "price": 29.95,
        "calculated_price": 29.95,
        "sale_price": 29.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826972,
            "label": "24 X Bottle 330 ml",
            "option_id": 462158,
            "option_display_name": "Beer"
          }
        ]
      }
    ],
    "images": [],
    "primary_image": null,
    "custom_fields": [
      {
        "id": 6649864,
        "name": "ABV",
        "value": "0.00"
      },
      {
        "id": 6649865,
        "name": "Country",
        "value": "DE"
      },
      {
        "id": 6649866,
        "name": "Category",
        "value": "Non Beer"
      },
      {
        "id": 6649867,
        "name": "Type",
        "value": "Non Beer"
      },
      {
        "id": 6649868,
        "name": "Beverage_type",
        "value": "Non-Alcoholic"
      },
      {
        "id": 6649869,
        "name": "Producer",
        "value": "LABATT"
      },
      {
        "id": 6649870,
        "name": "Price_Metadata_1932011",
        "value": "{\"current_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.08}],\"total_price\":9.95},\"previous_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.19}],\"total_price\":10.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6649871,
        "name": "Price_Metadata_1932012",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.04}],\"total_price\":18.95},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.21}],\"total_price\":20.45},\"on_sale\":\"N\"}"
      },
      {
        "id": 6649872,
        "name": "Price_Metadata_1932013",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.17}],\"total_price\":29.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.51}],\"total_price\":32.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6649873,
        "name": "product_image_1",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbg6t7-2copuw-deot8/1302_0.png?position=1"
      },
      {
        "id": 6649875,
        "name": "product_image_2",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbg6t7-2copuw-deot8/1302_0.png?position=2"
      },
      {
        "id": 6649877,
        "name": "container_type ",
        "value": "B"
      },
      {
        "id": 6649879,
        "name": "pack",
        "value": "6,12,24"
      }
    ]
  },
  {
    "id": 462215,
    "name": "becks",
    "type": "physical",
    "sku": "2054_2405",
    "description": "Premium quality, light golden colour, hoppy flavour. Brewed using only natural ingredients of barley-malt, hops, yeast and brewingwater.",
    "weight": 1,
    "width": 0,
    "depth": 0,
    "height": 0,
    "price": 19.25,
    "cost_price": 0,
    "retail_price": 0,
    "sale_price": 0,
    "map_price": 0,
    "tax_class_id": 0,
    "product_tax_code": "",
    "calculated_price": 19.25,
    "categories": [
      243, 232, 169, 218, 165, 175, 202, 176, 177, 170, 172, 167, 166, 173
    ],
    "brand_id": 1030,
    "option_set_id": 462112,
    "option_set_display": "right",
    "inventory_level": 458,
    "inventory_warning_level": 0,
    "inventory_tracking": "variant",
    "reviews_rating_sum": 0,
    "reviews_count": 0,
    "total_sold": 2,
    "fixed_cost_shipping_price": 0,
    "is_free_shipping": false,
    "is_visible": true,
    "is_featured": false,
    "related_products": [-1],
    "warranty": "",
    "bin_picking_number": "",
    "layout_file": "",
    "upc": "",
    "mpn": "",
    "gtin": "",
    "search_keywords": "",
    "availability": "available",
    "availability_description": "",
    "gift_wrapping_options_type": "any",
    "gift_wrapping_options_list": [],
    "sort_order": 0,
    "condition": "New",
    "is_condition_shown": false,
    "order_quantity_minimum": 0,
    "order_quantity_maximum": 0,
    "page_title": "BECKS~2405",
    "meta_keywords": [],
    "meta_description": "",
    "date_created": "2020-10-16T13:24:50+00:00",
    "date_modified": "2021-12-21T23:23:20+00:00",
    "view_count": 0,
    "preorder_release_date": null,
    "preorder_message": "",
    "is_preorder_only": false,
    "is_price_hidden": false,
    "price_hidden_label": "",
    "custom_url": {
      "url": "/becks-2405/",
      "is_customized": false
    },
    "base_variant_id": null,
    "open_graph_type": "product",
    "open_graph_title": "",
    "open_graph_description": "",
    "open_graph_use_meta_description": true,
    "open_graph_use_product_name": true,
    "open_graph_use_image": true,
    "variants": [
      {
        "id": 2287995,
        "product_id": 462215,
        "sku": "1142011_2405",
        "sku_id": 1825946,
        "price": 15.25,
        "calculated_price": 15.25,
        "sale_price": 15.25,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 31,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1827146,
            "label": "6 X Bottle 330 ml",
            "option_id": 462208,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2288005,
        "product_id": 462215,
        "sku": "1142012_2405",
        "sku_id": 1825955,
        "price": 28.95,
        "calculated_price": 28.95,
        "sale_price": 28.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1827158,
            "label": "12 X Bottle 330 ml",
            "option_id": 462208,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2288023,
        "product_id": 462215,
        "sku": "1142013_2405",
        "sku_id": 1825970,
        "price": 52.95,
        "calculated_price": 52.95,
        "sale_price": 52.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1827172,
            "label": "24 X Bottle 330 ml",
            "option_id": 462208,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2288043,
        "product_id": 462215,
        "sku": "1142063_2405",
        "sku_id": 1825988,
        "price": 2.75,
        "calculated_price": 2.75,
        "sale_price": 2.75,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 427,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1827187,
            "label": "1 X Can 500 ml",
            "option_id": 462208,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2288068,
        "product_id": 462215,
        "sku": "1142128_2405",
        "sku_id": 1826007,
        "price": 10.5,
        "calculated_price": 10.5,
        "sale_price": 10.5,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1827209,
            "label": "4 X Can 500 ml",
            "option_id": 462208,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2288114,
        "product_id": 462215,
        "sku": "1142130_2405",
        "sku_id": 1826045,
        "price": 54.1,
        "calculated_price": 54.1,
        "sale_price": 54.1,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1827245,
            "label": "24 X Can 500 ml",
            "option_id": 462208,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2288142,
        "product_id": 462215,
        "sku": "1142199_2405",
        "sku_id": 1826073,
        "price": 29.95,
        "calculated_price": 29.95,
        "sale_price": 29.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1827274,
            "label": "12 X Can 500 ml",
            "option_id": 462208,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2288161,
        "product_id": 462215,
        "sku": "1142279_2405",
        "sku_id": 1826088,
        "price": 19.25,
        "calculated_price": 19.25,
        "sale_price": 19.25,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1827289,
            "label": "8 X Can 500 ml",
            "option_id": 462208,
            "option_display_name": "Beer"
          }
        ]
      }
    ],
    "images": [],
    "primary_image": null,
    "custom_fields": [
      {
        "id": 6650617,
        "name": "ABV",
        "value": "5.00"
      },
      {
        "id": 6650622,
        "name": "Country",
        "value": "DE"
      },
      {
        "id": 6650626,
        "name": "Category",
        "value": "Import"
      },
      {
        "id": 6650630,
        "name": "Type",
        "value": "Lager"
      },
      {
        "id": 6650632,
        "name": "Beverage_type",
        "value": "Beer"
      },
      {
        "id": 6650635,
        "name": "Producer",
        "value": "BRAUEREI BECK & COMPANY"
      },
      {
        "id": 6650637,
        "name": "Beer_styles",
        "value": "Golden"
      },
      {
        "id": 6650639,
        "name": "Price_Metadata_1142011",
        "value": "{\"current_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.69}],\"total_price\":15.25},\"previous_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.69}],\"total_price\":15.30},\"on_sale\":\"N\"}"
      },
      {
        "id": 6650642,
        "name": "Price_Metadata_1142012",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.19}],\"total_price\":28.95},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.14}],\"total_price\":28.50},\"on_sale\":\"N\"}"
      },
      {
        "id": 6650644,
        "name": "Price_Metadata_1142013",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.82}],\"total_price\":52.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.70}],\"total_price\":51.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6650647,
        "name": "Price_Metadata_1142063",
        "value": "{\"current_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.30}],\"total_price\":2.75},\"previous_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.28}],\"total_price\":2.55},\"on_sale\":\"N\"}"
      },
      {
        "id": 6650649,
        "name": "Price_Metadata_1142128",
        "value": "{\"current_price\":{\"deposit\":0.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.16}],\"total_price\":10.50},\"previous_price\":{\"deposit\":0.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.13}],\"total_price\":10.20},\"on_sale\":\"N\"}"
      },
      {
        "id": 6650652,
        "name": "Price_Metadata_1142130",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.95}],\"total_price\":54.10},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.95}],\"total_price\":54.10},\"on_sale\":\"N\"}"
      },
      {
        "id": 6650654,
        "name": "Price_Metadata_1142199",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.31}],\"total_price\":29.95},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.31}],\"total_price\":29.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6650659,
        "name": "Price_Metadata_1142279",
        "value": "{\"current_price\":{\"deposit\":0.80,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.12}],\"total_price\":19.25},\"previous_price\":{\"deposit\":0.80,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.12}],\"total_price\":19.25},\"on_sale\":\"N\"}"
      },
      {
        "id": 6650662,
        "name": "product_image_1",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbe4ji-98cyfc-60y60t/2054.png?position=1"
      },
      {
        "id": 6650666,
        "name": "product_image_2",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbe4ji-98cyfc-60y60t/2054.png?position=2"
      },
      {
        "id": 6650669,
        "name": "container_type ",
        "value": "B,C"
      },
      {
        "id": 6650672,
        "name": "pack",
        "value": "6,12,24,1,4,8"
      }
    ]
  },
  {
    "id": 462466,
    "name": "belgian-moon-mango-wheat",
    "type": "physical",
    "sku": "3538_2405",
    "description": "A tropical wheat beer, crafted with fresh mango and honey, inspired by the beaches of the Mazatlán shore. Ripe mango flavour with light amount of wheat and biscuity malt sweetness. Bitterness is low, and it's balanced with slight clover-honey sweetness. ",
    "weight": 1,
    "width": 0,
    "depth": 0,
    "height": 0,
    "price": 11.95,
    "cost_price": 0,
    "retail_price": 0,
    "sale_price": 0,
    "map_price": 0,
    "tax_class_id": 0,
    "product_tax_code": "",
    "calculated_price": 11.95,
    "categories": [162, 163, 178, 222, 197, 165, 170, 171, 167, 166, 172],
    "brand_id": 1030,
    "option_set_id": 462363,
    "option_set_display": "right",
    "inventory_level": 10,
    "inventory_warning_level": 0,
    "inventory_tracking": "variant",
    "reviews_rating_sum": 0,
    "reviews_count": 0,
    "total_sold": 16,
    "fixed_cost_shipping_price": 0,
    "is_free_shipping": false,
    "is_visible": true,
    "is_featured": false,
    "related_products": [-1],
    "warranty": "",
    "bin_picking_number": "",
    "layout_file": "",
    "upc": "",
    "mpn": "",
    "gtin": "",
    "search_keywords": "",
    "availability": "available",
    "availability_description": "",
    "gift_wrapping_options_type": "any",
    "gift_wrapping_options_list": [],
    "sort_order": 0,
    "condition": "New",
    "is_condition_shown": false,
    "order_quantity_minimum": 0,
    "order_quantity_maximum": 0,
    "page_title": "BELGIAN MOON MANGO WHEAT~2405",
    "meta_keywords": [],
    "meta_description": "",
    "date_created": "2020-10-16T13:24:57+00:00",
    "date_modified": "2021-12-22T11:25:14+00:00",
    "view_count": 0,
    "preorder_release_date": null,
    "preorder_message": "",
    "is_preorder_only": false,
    "is_price_hidden": false,
    "price_hidden_label": "",
    "custom_url": {
      "url": "/belgian-moon-mango-wheat-2405/",
      "is_customized": false
    },
    "base_variant_id": null,
    "open_graph_type": "product",
    "open_graph_title": "",
    "open_graph_description": "",
    "open_graph_use_meta_description": true,
    "open_graph_use_product_name": true,
    "open_graph_use_image": true,
    "variants": [
      {
        "id": 2289100,
        "product_id": 462466,
        "sku": "2142004_2405",
        "sku_id": 1826801,
        "price": 3,
        "calculated_price": 3,
        "sale_price": 3,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 10,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828002,
            "label": "1 X Can 473 ml",
            "option_id": 462459,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2289112,
        "product_id": 462466,
        "sku": "2142038_2405",
        "sku_id": 1826812,
        "price": 17.25,
        "calculated_price": 17.25,
        "sale_price": 17.25,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828016,
            "label": "6 X Can 473 ml",
            "option_id": 462459,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2289123,
        "product_id": 462466,
        "sku": "2142041_2405",
        "sku_id": 1826823,
        "price": 59.95,
        "calculated_price": 59.95,
        "sale_price": 59.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828026,
            "label": "24 X Can 473 ml",
            "option_id": 462459,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2289130,
        "product_id": 462466,
        "sku": "2142042_2405",
        "sku_id": 1826829,
        "price": 31.95,
        "calculated_price": 31.95,
        "sale_price": 31.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828032,
            "label": "12 X Can 473 ml",
            "option_id": 462459,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2289137,
        "product_id": 462466,
        "sku": "2142097_2405",
        "sku_id": 1826836,
        "price": 11.95,
        "calculated_price": 11.95,
        "sale_price": 11.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828038,
            "label": "4 X Can 473 ml",
            "option_id": 462459,
            "option_display_name": "Beer"
          }
        ]
      }
    ],
    "images": [],
    "primary_image": null,
    "custom_fields": [
      {
        "id": 6654178,
        "name": "ABV",
        "value": "5.40"
      },
      {
        "id": 6654182,
        "name": "Country",
        "value": "CA"
      },
      {
        "id": 6654185,
        "name": "Category",
        "value": "Domestic Specialty"
      },
      {
        "id": 6654187,
        "name": "Type",
        "value": "Ale"
      },
      {
        "id": 6654189,
        "name": "Beverage_type",
        "value": "Beer"
      },
      {
        "id": 6654192,
        "name": "Producer",
        "value": "MOLSON"
      },
      {
        "id": 6654194,
        "name": "Beer_styles",
        "value": "Wheat,Fruit"
      },
      {
        "id": 6654196,
        "name": "Price_Metadata_2142004",
        "value": "{\"current_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.33}],\"total_price\":3.00},\"previous_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.33}],\"total_price\":2.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6654198,
        "name": "Price_Metadata_2142038",
        "value": "{\"current_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.92}],\"total_price\":17.25},\"previous_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.88}],\"total_price\":16.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6654201,
        "name": "Price_Metadata_2142041",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":6.62}],\"total_price\":59.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":6.62}],\"total_price\":59.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6654202,
        "name": "Price_Metadata_2142042",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.54}],\"total_price\":31.95},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.49}],\"total_price\":31.50},\"on_sale\":\"N\"}"
      },
      {
        "id": 6654206,
        "name": "Price_Metadata_2142097",
        "value": "{\"current_price\":{\"deposit\":0.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.33}],\"total_price\":11.95},\"previous_price\":{\"deposit\":0.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.28}],\"total_price\":11.50},\"on_sale\":\"N\"}"
      },
      {
        "id": 6654209,
        "name": "container_type ",
        "value": "C"
      },
      {
        "id": 6654211,
        "name": "pack",
        "value": "1,6,24,12,4"
      },
      {
        "id": 12204365,
        "name": "product_image_1",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/vjxgmjjz59wkk6wtph3skj/3538.png?position=1"
      },
      {
        "id": 12204366,
        "name": "product_image_2",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/vjxgmjjz59wkk6wtph3skj/3538.png?position=2"
      }
    ]
  },
  {
    "id": 461954,
    "name": "belgian-moon",
    "type": "physical",
    "sku": "137_2405",
    "description": "The award-winning US wheat beer comes to Canada. This delicious Belgian- Style witbier is brewed with malted barley, wheat and rolled oats. Coriander, along with Valencia and navel orange peels are added to create a smooth and refreshing, unfiltered wheat beer. ",
    "weight": 1,
    "width": 0,
    "depth": 0,
    "height": 0,
    "price": 11.95,
    "cost_price": 0,
    "retail_price": 0,
    "sale_price": 0,
    "map_price": 0,
    "tax_class_id": 0,
    "product_tax_code": "",
    "calculated_price": 11.95,
    "categories": [
      162, 163, 178, 179, 180, 165, 175, 176, 170, 177, 181, 166, 167, 171,
      172
    ],
    "brand_id": 1030,
    "option_set_id": 461852,
    "option_set_display": "right",
    "inventory_level": 179,
    "inventory_warning_level": 0,
    "inventory_tracking": "variant",
    "reviews_rating_sum": 0,
    "reviews_count": 0,
    "total_sold": 5,
    "fixed_cost_shipping_price": 0,
    "is_free_shipping": false,
    "is_visible": true,
    "is_featured": false,
    "related_products": [-1],
    "warranty": "",
    "bin_picking_number": "",
    "layout_file": "",
    "upc": "",
    "mpn": "",
    "gtin": "",
    "search_keywords": "",
    "availability": "available",
    "availability_description": "",
    "gift_wrapping_options_type": "any",
    "gift_wrapping_options_list": [],
    "sort_order": 0,
    "condition": "New",
    "is_condition_shown": false,
    "order_quantity_minimum": 0,
    "order_quantity_maximum": 0,
    "page_title": "BELGIAN MOON~2405",
    "meta_keywords": [],
    "meta_description": "",
    "date_created": "2020-10-16T13:24:42+00:00",
    "date_modified": "2021-12-22T11:25:14+00:00",
    "view_count": 0,
    "preorder_release_date": null,
    "preorder_message": "",
    "is_preorder_only": false,
    "is_price_hidden": false,
    "price_hidden_label": "",
    "custom_url": {
      "url": "/belgian-moon-2405/",
      "is_customized": false
    },
    "base_variant_id": null,
    "open_graph_type": "product",
    "open_graph_title": "",
    "open_graph_description": "",
    "open_graph_use_meta_description": true,
    "open_graph_use_product_name": true,
    "open_graph_use_image": true,
    "variants": [
      {
        "id": 2286564,
        "product_id": 461954,
        "sku": "1007002_2405",
        "sku_id": 1824777,
        "price": 25.95,
        "calculated_price": 25.95,
        "sale_price": 25.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 6,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1825978,
            "label": "12 X Bottle 341 ml",
            "option_id": 461948,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286576,
        "product_id": 461954,
        "sku": "1007004_2405",
        "sku_id": 1824785,
        "price": 3,
        "calculated_price": 3,
        "sale_price": 3,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 165,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1825989,
            "label": "1 X Can 473 ml",
            "option_id": 461948,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286588,
        "product_id": 461954,
        "sku": "1007005_2405",
        "sku_id": 1824795,
        "price": 47.95,
        "calculated_price": 47.95,
        "sale_price": 47.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1825998,
            "label": "24 X Bottle 341 ml",
            "option_id": 461948,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286601,
        "product_id": 461954,
        "sku": "1007027_2405",
        "sku_id": 1824804,
        "price": 369.95,
        "calculated_price": 369.95,
        "sale_price": 369.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826008,
            "label": "1 X Keg 58600 ml",
            "option_id": 461948,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286614,
        "product_id": 461954,
        "sku": "1007031_2405",
        "sku_id": 1824817,
        "price": 28.95,
        "calculated_price": 28.95,
        "sale_price": 28.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 6,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826019,
            "label": "12 X Can 355 ml",
            "option_id": 461948,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286634,
        "product_id": 461954,
        "sku": "1007033_2405",
        "sku_id": 1824832,
        "price": 51.95,
        "calculated_price": 51.95,
        "sale_price": 51.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826034,
            "label": "24 X Can 355 ml",
            "option_id": 461948,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286656,
        "product_id": 461954,
        "sku": "1007038_2405",
        "sku_id": 1824852,
        "price": 17.25,
        "calculated_price": 17.25,
        "sale_price": 17.25,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826054,
            "label": "6 X Can 473 ml",
            "option_id": 461948,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286670,
        "product_id": 461954,
        "sku": "1007041_2405",
        "sku_id": 1824865,
        "price": 59.95,
        "calculated_price": 59.95,
        "sale_price": 59.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826067,
            "label": "24 X Can 473 ml",
            "option_id": 461948,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286679,
        "product_id": 461954,
        "sku": "1007042_2405",
        "sku_id": 1824873,
        "price": 31.95,
        "calculated_price": 29.95,
        "sale_price": 29.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 2,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826075,
            "label": "12 X Can 473 ml",
            "option_id": 461948,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286690,
        "product_id": 461954,
        "sku": "1007097_2405",
        "sku_id": 1824884,
        "price": 11.95,
        "calculated_price": 11.95,
        "sale_price": 11.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826086,
            "label": "4 X Can 473 ml",
            "option_id": 461948,
            "option_display_name": "Beer"
          }
        ]
      }
    ],
    "images": [],
    "primary_image": null,
    "custom_fields": [
      {
        "id": 6646694,
        "name": "ABV",
        "value": "5.40"
      },
      {
        "id": 6646699,
        "name": "Country",
        "value": "CA"
      },
      {
        "id": 6646702,
        "name": "Category",
        "value": "Domestic Specialty"
      },
      {
        "id": 6646704,
        "name": "Type",
        "value": "Ale"
      },
      {
        "id": 6646706,
        "name": "Beverage_type",
        "value": "Beer"
      },
      {
        "id": 6646708,
        "name": "Producer",
        "value": "MOLSON"
      },
      {
        "id": 6646709,
        "name": "Price_Metadata_1007002",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.85}],\"total_price\":25.95},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.73}],\"total_price\":24.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6646710,
        "name": "Price_Metadata_1007004",
        "value": "{\"current_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.33}],\"total_price\":3.00},\"previous_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.30}],\"total_price\":2.70},\"on_sale\":\"N\"}"
      },
      {
        "id": 6646711,
        "name": "Price_Metadata_1007005",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.24}],\"total_price\":47.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.24}],\"total_price\":47.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6646712,
        "name": "Price_Metadata_1007027",
        "value": "{\"current_price\":{\"deposit\":50.00,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":36.81}],\"total_price\":369.95},\"previous_price\":{\"deposit\":50.00,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":34.51}],\"total_price\":349.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6646713,
        "name": "Price_Metadata_1007031",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.19}],\"total_price\":28.95},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.96}],\"total_price\":26.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6646714,
        "name": "Price_Metadata_1007033",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.70}],\"total_price\":51.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.65}],\"total_price\":51.50},\"on_sale\":\"N\"}"
      },
      {
        "id": 6646715,
        "name": "Price_Metadata_1007038",
        "value": "{\"current_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.92}],\"total_price\":17.25},\"previous_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.88}],\"total_price\":16.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6646716,
        "name": "Price_Metadata_1007041",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":6.62}],\"total_price\":59.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":6.62}],\"total_price\":59.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6646717,
        "name": "Price_Metadata_1007042",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.31}],\"total_price\":29.95},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.54}],\"total_price\":31.95},\"on_sale\":\"Y\"}"
      },
      {
        "id": 6646718,
        "name": "Price_Metadata_1007097",
        "value": "{\"current_price\":{\"deposit\":0.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.33}],\"total_price\":11.95},\"previous_price\":{\"deposit\":0.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.28}],\"total_price\":11.50},\"on_sale\":\"N\"}"
      },
      {
        "id": 6646719,
        "name": "product_image_1",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbfvln-geobfk-gxbvk/0137.png?position=1"
      },
      {
        "id": 6646720,
        "name": "product_image_2",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbfvln-geobfk-gxbvk/0137.png?position=2"
      },
      {
        "id": 6646721,
        "name": "container_type ",
        "value": "B,C,K"
      },
      {
        "id": 6646723,
        "name": "pack",
        "value": "12,1,24,6,4"
      }
    ]
  },
  {
    "id": 462129,
    "name": "big-rig-shakedown-apa",
    "type": "physical",
    "sku": "1121_2405",
    "description": "Brash and hazy APA that uses exclusively Galaxy and Citra hops. These hops combine to create a harmonious and heavenly blend of tropical and citrus aromas and flavours. It’s hop forward with underlying malt sweetness to create balance and drinkability.",
    "weight": 1,
    "width": 0,
    "depth": 0,
    "height": 0,
    "price": 2.85,
    "cost_price": 0,
    "retail_price": 0,
    "sale_price": 0,
    "map_price": 0,
    "tax_class_id": 0,
    "product_tax_code": "",
    "calculated_price": 2.85,
    "categories": [162, 198, 178, 199, 182, 165, 170],
    "brand_id": 1030,
    "option_set_id": 462027,
    "option_set_display": "right",
    "inventory_level": 31,
    "inventory_warning_level": 0,
    "inventory_tracking": "variant",
    "reviews_rating_sum": 0,
    "reviews_count": 0,
    "total_sold": 0,
    "fixed_cost_shipping_price": 0,
    "is_free_shipping": false,
    "is_visible": true,
    "is_featured": false,
    "related_products": [-1],
    "warranty": "",
    "bin_picking_number": "",
    "layout_file": "",
    "upc": "",
    "mpn": "",
    "gtin": "",
    "search_keywords": "",
    "availability": "available",
    "availability_description": "",
    "gift_wrapping_options_type": "any",
    "gift_wrapping_options_list": [],
    "sort_order": 0,
    "condition": "New",
    "is_condition_shown": false,
    "order_quantity_minimum": 0,
    "order_quantity_maximum": 0,
    "page_title": "BIG RIG SHAKEDOWN APA~2405",
    "meta_keywords": [],
    "meta_description": "",
    "date_created": "2020-10-16T13:24:48+00:00",
    "date_modified": "2021-12-12T13:17:03+00:00",
    "view_count": 0,
    "preorder_release_date": null,
    "preorder_message": "",
    "is_preorder_only": false,
    "is_price_hidden": false,
    "price_hidden_label": "",
    "custom_url": {
      "url": "/big-rig-shakedown-apa-2405/",
      "is_customized": false
    },
    "base_variant_id": null,
    "open_graph_type": "product",
    "open_graph_title": "",
    "open_graph_description": "",
    "open_graph_use_meta_description": true,
    "open_graph_use_product_name": true,
    "open_graph_use_image": true,
    "variants": [
      {
        "id": 2287630,
        "product_id": 462129,
        "sku": "2602004_2405",
        "sku_id": 1825669,
        "price": 2.85,
        "calculated_price": 2.85,
        "sale_price": 2.85,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 31,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826871,
            "label": "1 X Can 473 ml",
            "option_id": 462123,
            "option_display_name": "Beer"
          }
        ]
      }
    ],
    "images": [],
    "primary_image": null,
    "custom_fields": [
      {
        "id": 6649440,
        "name": "ABV",
        "value": "5.60"
      },
      {
        "id": 6649441,
        "name": "Country",
        "value": "CA"
      },
      {
        "id": 6649442,
        "name": "Category",
        "value": "Ontario Craft"
      },
      {
        "id": 6649443,
        "name": "Type",
        "value": "Ale"
      },
      {
        "id": 6649444,
        "name": "Beverage_type",
        "value": "Beer"
      },
      {
        "id": 6649445,
        "name": "Producer",
        "value": "BIG RIG BREWERY"
      },
      {
        "id": 6649446,
        "name": "Beer_styles",
        "value": "India Pale Ale,Pale"
      },
      {
        "id": 6649447,
        "name": "Price_Metadata_2602004",
        "value": "{\"current_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.32}],\"total_price\":2.85},\"previous_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.36}],\"total_price\":3.20},\"on_sale\":\"N\"}"
      },
      {
        "id": 6649448,
        "name": "product_image_1",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qg0xoq-f9et1c-ejaijg/1121.png?position=1"
      },
      {
        "id": 6649449,
        "name": "product_image_2",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qg0xoq-f9et1c-ejaijg/1121.png?position=2"
      },
      {
        "id": 6649450,
        "name": "container_type ",
        "value": "C"
      },
      {
        "id": 6649451,
        "name": "pack",
        "value": "1"
      }
    ]
  },
  {
    "id": 462284,
    "name": "birra-moretti",
    "type": "physical",
    "sku": "2209_2405",
    "description": "Smooth, well-balanced, delicate malt flavor, slight hop aroma, crisp flavor that cleanses the palate with a delicious & pleasant aftertaste. Moretti is well-paired with roasted meats, pasta, Alfredo & seafood sauces, pizza, sharp cheeses; Romano, Asiago",
    "weight": 1,
    "width": 0,
    "depth": 0,
    "height": 0,
    "price": 67.95,
    "cost_price": 0,
    "retail_price": 0,
    "sale_price": 0,
    "map_price": 0,
    "tax_class_id": 0,
    "product_tax_code": "",
    "calculated_price": 67.95,
    "categories": [
      254, 232, 169, 182, 179, 180, 165, 175, 202, 177, 170, 181, 167
    ],
    "brand_id": 1030,
    "option_set_id": 462182,
    "option_set_display": "right",
    "inventory_level": 257,
    "inventory_warning_level": 0,
    "inventory_tracking": "variant",
    "reviews_rating_sum": 0,
    "reviews_count": 0,
    "total_sold": 29,
    "fixed_cost_shipping_price": 0,
    "is_free_shipping": false,
    "is_visible": true,
    "is_featured": false,
    "related_products": [-1],
    "warranty": "",
    "bin_picking_number": "",
    "layout_file": "",
    "upc": "",
    "mpn": "",
    "gtin": "",
    "search_keywords": "",
    "availability": "available",
    "availability_description": "",
    "gift_wrapping_options_type": "any",
    "gift_wrapping_options_list": [],
    "sort_order": 0,
    "condition": "New",
    "is_condition_shown": false,
    "order_quantity_minimum": 0,
    "order_quantity_maximum": 0,
    "page_title": "BIRRA MORETTI~2405",
    "meta_keywords": [],
    "meta_description": "",
    "date_created": "2020-10-16T13:24:53+00:00",
    "date_modified": "2021-12-22T23:24:22+00:00",
    "view_count": 0,
    "preorder_release_date": null,
    "preorder_message": "",
    "is_preorder_only": false,
    "is_price_hidden": false,
    "price_hidden_label": "",
    "custom_url": {
      "url": "/birra-moretti-2405/",
      "is_customized": false
    },
    "base_variant_id": null,
    "open_graph_type": "product",
    "open_graph_title": "",
    "open_graph_description": "",
    "open_graph_use_meta_description": true,
    "open_graph_use_product_name": true,
    "open_graph_use_image": true,
    "variants": [
      {
        "id": 2288386,
        "product_id": 462284,
        "sku": "1221011_2405",
        "sku_id": 1826270,
        "price": 15.5,
        "calculated_price": 15.5,
        "sale_price": 15.5,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 23,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1827471,
            "label": "6 X Bottle 330 ml",
            "option_id": 462278,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2288392,
        "product_id": 462284,
        "sku": "1221013_2405",
        "sku_id": 1826273,
        "price": 50.95,
        "calculated_price": 47.95,
        "sale_price": 47.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1827475,
            "label": "24 X Bottle 330 ml",
            "option_id": 462278,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2288400,
        "product_id": 462284,
        "sku": "1221063_2405",
        "sku_id": 1826279,
        "price": 3.3,
        "calculated_price": 3.3,
        "sale_price": 3.3,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 234,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1827482,
            "label": "1 X Can 500 ml",
            "option_id": 462278,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2288408,
        "product_id": 462284,
        "sku": "1221091_2405",
        "sku_id": 1826285,
        "price": 165,
        "calculated_price": 165,
        "sale_price": 165,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1827486,
            "label": "1 X Keg 20000 ml",
            "option_id": 462278,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2288415,
        "product_id": 462284,
        "sku": "1221130_2405",
        "sku_id": 1826290,
        "price": 67.95,
        "calculated_price": 67.95,
        "sale_price": 67.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1827493,
            "label": "24 X Can 500 ml",
            "option_id": 462278,
            "option_display_name": "Beer"
          }
        ]
      }
    ],
    "images": [],
    "primary_image": null,
    "custom_fields": [
      {
        "id": 6651678,
        "name": "ABV",
        "value": "4.60"
      },
      {
        "id": 6651681,
        "name": "Country",
        "value": "IT"
      },
      {
        "id": 6651683,
        "name": "Category",
        "value": "Import"
      },
      {
        "id": 6651685,
        "name": "Type",
        "value": "Lager"
      },
      {
        "id": 6651686,
        "name": "Beverage_type",
        "value": "Beer"
      },
      {
        "id": 6651688,
        "name": "Producer",
        "value": "BIRRA MORETTI SPA"
      },
      {
        "id": 6651690,
        "name": "Beer_styles",
        "value": "Pale"
      },
      {
        "id": 6651692,
        "name": "Price_Metadata_1221011",
        "value": "{\"current_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.71}],\"total_price\":15.50},\"previous_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.69}],\"total_price\":15.30},\"on_sale\":\"N\"}"
      },
      {
        "id": 6651694,
        "name": "Price_Metadata_1221013",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.24}],\"total_price\":47.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.59}],\"total_price\":50.95},\"on_sale\":\"Y\"}"
      },
      {
        "id": 6651695,
        "name": "Price_Metadata_1221063",
        "value": "{\"current_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.37}],\"total_price\":3.30},\"previous_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.36}],\"total_price\":3.20},\"on_sale\":\"N\"}"
      },
      {
        "id": 6651697,
        "name": "Price_Metadata_1221091",
        "value": "{\"current_price\":{\"deposit\":20.00,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":16.68}],\"total_price\":165.00},\"previous_price\":{\"deposit\":20.00,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":16.68}],\"total_price\":164.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6651699,
        "name": "Price_Metadata_1221130",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":7.54}],\"total_price\":67.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":7.31}],\"total_price\":65.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6651701,
        "name": "product_image_1",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbe1oh-c7ye3k-ekvqdc/2209.png?position=1"
      },
      {
        "id": 6651702,
        "name": "product_image_2",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbe1oh-c7ye3k-ekvqdc/2209.png?position=2"
      },
      {
        "id": 6651703,
        "name": "container_type ",
        "value": "B,C,K"
      },
      {
        "id": 6651704,
        "name": "pack",
        "value": "6,24,1"
      }
    ]
  },
  {
    "id": 462269,
    "name": "bitburger",
    "type": "physical",
    "sku": "2182_2405",
    "description": "A full-bodied premium lager, dry finished with hoppy taste.\n",
    "weight": 1,
    "width": 0,
    "depth": 0,
    "height": 0,
    "price": 2.65,
    "cost_price": 0,
    "retail_price": 0,
    "sale_price": 0,
    "map_price": 0,
    "tax_class_id": 0,
    "product_tax_code": "",
    "calculated_price": 2.65,
    "categories": [243, 232, 169, 192, 165, 170],
    "brand_id": 1030,
    "option_set_id": 462166,
    "option_set_display": "right",
    "inventory_level": 158,
    "inventory_warning_level": 0,
    "inventory_tracking": "variant",
    "reviews_rating_sum": 0,
    "reviews_count": 0,
    "total_sold": 26,
    "fixed_cost_shipping_price": 0,
    "is_free_shipping": false,
    "is_visible": true,
    "is_featured": false,
    "related_products": [-1],
    "warranty": "",
    "bin_picking_number": "",
    "layout_file": "",
    "upc": "",
    "mpn": "",
    "gtin": "",
    "search_keywords": "",
    "availability": "available",
    "availability_description": "",
    "gift_wrapping_options_type": "any",
    "gift_wrapping_options_list": [],
    "sort_order": 0,
    "condition": "New",
    "is_condition_shown": false,
    "order_quantity_minimum": 0,
    "order_quantity_maximum": 0,
    "page_title": "BITBURGER~2405",
    "meta_keywords": [],
    "meta_description": "",
    "date_created": "2020-10-16T13:24:52+00:00",
    "date_modified": "2021-12-22T11:23:10+00:00",
    "view_count": 0,
    "preorder_release_date": null,
    "preorder_message": "",
    "is_preorder_only": false,
    "is_price_hidden": false,
    "price_hidden_label": "",
    "custom_url": {
      "url": "/bitburger-2405/",
      "is_customized": false
    },
    "base_variant_id": null,
    "open_graph_type": "product",
    "open_graph_title": "",
    "open_graph_description": "",
    "open_graph_use_meta_description": true,
    "open_graph_use_product_name": true,
    "open_graph_use_image": true,
    "variants": [
      {
        "id": 2288325,
        "product_id": 462269,
        "sku": "1205053_2405",
        "sku_id": 1826224,
        "price": 13.35,
        "calculated_price": 13.35,
        "sale_price": 13.35,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": true,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1827425,
            "label": "6 X Bottle 355 ml",
            "option_id": 462262,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2288331,
        "product_id": 462269,
        "sku": "1205063_2405",
        "sku_id": 1826229,
        "price": 2.65,
        "calculated_price": 2.65,
        "sale_price": 2.65,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 158,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1827431,
            "label": "1 X Can 500 ml",
            "option_id": 462262,
            "option_display_name": "Beer"
          }
        ]
      }
    ],
    "images": [],
    "primary_image": null,
    "custom_fields": [
      {
        "id": 6651461,
        "name": "ABV",
        "value": "4.80"
      },
      {
        "id": 6651466,
        "name": "Country",
        "value": "DE"
      },
      {
        "id": 6651468,
        "name": "Category",
        "value": "Import"
      },
      {
        "id": 6651471,
        "name": "Type",
        "value": "Lager"
      },
      {
        "id": 6651474,
        "name": "Beverage_type",
        "value": "Beer"
      },
      {
        "id": 6651476,
        "name": "Producer",
        "value": "BITBURGER BRAUGRUPPE GMBH"
      },
      {
        "id": 6651479,
        "name": "Beer_styles",
        "value": "Pilsner"
      },
      {
        "id": 6651480,
        "name": "Price_Metadata_1205053",
        "value": "{\"current_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.47}],\"total_price\":13.35},\"previous_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.46}],\"total_price\":13.30},\"on_sale\":\"N\"}"
      },
      {
        "id": 6651482,
        "name": "Price_Metadata_1205063",
        "value": "{\"current_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.29}],\"total_price\":2.65},\"previous_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.25}],\"total_price\":2.30},\"on_sale\":\"N\"}"
      },
      {
        "id": 6651485,
        "name": "product_image_1",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbe229-8i88oo-e6lmky/2182.png?position=1"
      },
      {
        "id": 6651487,
        "name": "product_image_2",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbe229-8i88oo-e6lmky/2182.png?position=2"
      },
      {
        "id": 6651490,
        "name": "container_type ",
        "value": "B,C"
      },
      {
        "id": 6651491,
        "name": "pack",
        "value": "6,1"
      }
    ]
  },
  {
    "id": 461958,
    "name": "black-ice",
    "type": "physical",
    "sku": "154_2405",
    "description": "An 'ice brewed' beer which has a clean, fresh, snappy taste and a mellow aftertaste from the ice - filtering process. ",
    "weight": 1,
    "width": 0,
    "depth": 0,
    "height": 0,
    "price": 19.5,
    "cost_price": 0,
    "retail_price": 0,
    "sale_price": 0,
    "map_price": 0,
    "tax_class_id": 0,
    "product_tax_code": "",
    "calculated_price": 19.5,
    "categories": [
      162, 185, 169, 186, 182, 165, 175, 177, 170, 166, 187, 188, 171
    ],
    "brand_id": 1030,
    "option_set_id": 461856,
    "option_set_display": "right",
    "inventory_level": 263,
    "inventory_warning_level": 0,
    "inventory_tracking": "variant",
    "reviews_rating_sum": 0,
    "reviews_count": 0,
    "total_sold": 0,
    "fixed_cost_shipping_price": 0,
    "is_free_shipping": false,
    "is_visible": true,
    "is_featured": false,
    "related_products": [-1],
    "warranty": "",
    "bin_picking_number": "",
    "layout_file": "",
    "upc": "",
    "mpn": "",
    "gtin": "",
    "search_keywords": "",
    "availability": "available",
    "availability_description": "",
    "gift_wrapping_options_type": "any",
    "gift_wrapping_options_list": [],
    "sort_order": 0,
    "condition": "New",
    "is_condition_shown": false,
    "order_quantity_minimum": 0,
    "order_quantity_maximum": 0,
    "page_title": "BLACK ICE~2405",
    "meta_keywords": [],
    "meta_description": "",
    "date_created": "2020-10-16T13:24:42+00:00",
    "date_modified": "2021-12-22T19:20:50+00:00",
    "view_count": 0,
    "preorder_release_date": null,
    "preorder_message": "",
    "is_preorder_only": false,
    "is_price_hidden": false,
    "price_hidden_label": "",
    "custom_url": {
      "url": "/black-ice-2405/",
      "is_customized": false
    },
    "base_variant_id": null,
    "open_graph_type": "product",
    "open_graph_title": "",
    "open_graph_description": "",
    "open_graph_use_meta_description": true,
    "open_graph_use_product_name": true,
    "open_graph_use_image": true,
    "variants": [
      {
        "id": 2286581,
        "product_id": 461958,
        "sku": "1015005_2405",
        "sku_id": 1824788,
        "price": 39.5,
        "calculated_price": 39.5,
        "sale_price": 39.5,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1825990,
            "label": "24 X Bottle 341 ml",
            "option_id": 461952,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286592,
        "product_id": 461958,
        "sku": "1015024_2405",
        "sku_id": 1824799,
        "price": 3.3,
        "calculated_price": 3.3,
        "sale_price": 3.3,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 263,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826001,
            "label": "1 X Can 710 ml",
            "option_id": 461952,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286604,
        "product_id": 461958,
        "sku": "1015051_2405",
        "sku_id": 1824808,
        "price": 37.95,
        "calculated_price": 37.95,
        "sale_price": 37.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826010,
            "label": "12 X Can 710 ml",
            "option_id": 461952,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286618,
        "product_id": 461958,
        "sku": "1015069_2405",
        "sku_id": 1824819,
        "price": 6.5,
        "calculated_price": 6.5,
        "sale_price": 6.5,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826022,
            "label": "1 X Bottle 1183 ml",
            "option_id": 461952,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286639,
        "product_id": 461958,
        "sku": "1015090_2405",
        "sku_id": 1824835,
        "price": 44.95,
        "calculated_price": 44.95,
        "sale_price": 44.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826038,
            "label": "8 X Bottle 1183 ml",
            "option_id": 461952,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286668,
        "product_id": 461958,
        "sku": "1015280_2405",
        "sku_id": 1824863,
        "price": 19.5,
        "calculated_price": 19.5,
        "sale_price": 19.5,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826065,
            "label": "6 X Can 710 ml",
            "option_id": 461952,
            "option_display_name": "Beer"
          }
        ]
      }
    ],
    "images": [],
    "primary_image": null,
    "custom_fields": [
      {
        "id": 6646728,
        "name": "ABV",
        "value": "6.10"
      },
      {
        "id": 6646735,
        "name": "Country",
        "value": "CA"
      },
      {
        "id": 6646739,
        "name": "Category",
        "value": "Premium"
      },
      {
        "id": 6646745,
        "name": "Type",
        "value": "Lager"
      },
      {
        "id": 6646749,
        "name": "Beverage_type",
        "value": "Beer"
      },
      {
        "id": 6646754,
        "name": "Producer",
        "value": "MOLSON"
      },
      {
        "id": 6646759,
        "name": "Beer_styles",
        "value": "Strong,Pale"
      },
      {
        "id": 6646764,
        "name": "Price_Metadata_1015005",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":4.27}],\"total_price\":39.50},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":4.20}],\"total_price\":38.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6646770,
        "name": "Price_Metadata_1015024",
        "value": "{\"current_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.37}],\"total_price\":3.30},\"previous_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.36}],\"total_price\":3.25},\"on_sale\":\"N\"}"
      },
      {
        "id": 6646774,
        "name": "Price_Metadata_1015051",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":4.23}],\"total_price\":37.95},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":4.20}],\"total_price\":37.75},\"on_sale\":\"N\"}"
      },
      {
        "id": 6646777,
        "name": "Price_Metadata_1015069",
        "value": "{\"current_price\":{\"deposit\":0.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.72}],\"total_price\":6.50},\"previous_price\":{\"deposit\":0.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.72}],\"total_price\":6.45},\"on_sale\":\"N\"}"
      },
      {
        "id": 6646781,
        "name": "Price_Metadata_1015090",
        "value": "{\"current_price\":{\"deposit\":1.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":4.99}],\"total_price\":44.95},\"previous_price\":{\"deposit\":1.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":4.76}],\"total_price\":42.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6646785,
        "name": "Price_Metadata_1015280",
        "value": "{\"current_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.17}],\"total_price\":19.50},\"previous_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.15}],\"total_price\":19.25},\"on_sale\":\"N\"}"
      },
      {
        "id": 6646795,
        "name": "product_image_1",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbfvms-5i6whc-7f40qy/0154.png?position=1"
      },
      {
        "id": 6646799,
        "name": "product_image_2",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbfvms-5i6whc-7f40qy/0154.png?position=2"
      },
      {
        "id": 6646804,
        "name": "container_type ",
        "value": "B,C"
      },
      {
        "id": 6646808,
        "name": "pack",
        "value": "24,1,12,8,6"
      }
    ]
  },
  {
    "id": 461955,
    "name": "black-label",
    "type": "physical",
    "sku": "151_2405",
    "description": "A contemporary lager beer with a light, crisp, refreshing aroma and taste.",
    "weight": 1,
    "width": 0,
    "depth": 0,
    "height": 0,
    "price": 51.95,
    "cost_price": 0,
    "retail_price": 0,
    "sale_price": 0,
    "map_price": 0,
    "tax_class_id": 0,
    "product_tax_code": "",
    "calculated_price": 51.95,
    "categories": [
      162, 168, 169, 182, 165, 175, 170, 177, 171, 167, 166, 172, 173, 183
    ],
    "brand_id": 1030,
    "option_set_id": 461857,
    "option_set_display": "right",
    "inventory_level": 537,
    "inventory_warning_level": 0,
    "inventory_tracking": "variant",
    "reviews_rating_sum": 0,
    "reviews_count": 0,
    "total_sold": 0,
    "fixed_cost_shipping_price": 0,
    "is_free_shipping": false,
    "is_visible": true,
    "is_featured": false,
    "related_products": [-1],
    "warranty": "",
    "bin_picking_number": "",
    "layout_file": "",
    "upc": "",
    "mpn": "",
    "gtin": "",
    "search_keywords": "",
    "availability": "available",
    "availability_description": "",
    "gift_wrapping_options_type": "any",
    "gift_wrapping_options_list": [],
    "sort_order": 0,
    "condition": "New",
    "is_condition_shown": false,
    "order_quantity_minimum": 0,
    "order_quantity_maximum": 0,
    "page_title": "BLACK LABEL~2405",
    "meta_keywords": [],
    "meta_description": "",
    "date_created": "2020-10-16T13:24:42+00:00",
    "date_modified": "2021-12-22T11:23:11+00:00",
    "view_count": 0,
    "preorder_release_date": null,
    "preorder_message": "",
    "is_preorder_only": false,
    "is_price_hidden": false,
    "price_hidden_label": "",
    "custom_url": {
      "url": "/black-label-2405/",
      "is_customized": false
    },
    "base_variant_id": null,
    "open_graph_type": "product",
    "open_graph_title": "",
    "open_graph_description": "",
    "open_graph_use_meta_description": true,
    "open_graph_use_product_name": true,
    "open_graph_use_image": true,
    "variants": [
      {
        "id": 2286585,
        "product_id": 461955,
        "sku": "1014004_2405",
        "sku_id": 1824792,
        "price": 2.2,
        "calculated_price": 2.2,
        "sale_price": 2.2,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 537,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1825993,
            "label": "1 X Can 473 ml",
            "option_id": 461953,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286605,
        "product_id": 461955,
        "sku": "1014005_2405",
        "sku_id": 1824805,
        "price": 39.5,
        "calculated_price": 39.5,
        "sale_price": 39.5,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826005,
            "label": "24 X Bottle 341 ml",
            "option_id": 461953,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286620,
        "product_id": 461955,
        "sku": "1014038_2405",
        "sku_id": 1824820,
        "price": 12.95,
        "calculated_price": 12.95,
        "sale_price": 12.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826023,
            "label": "6 X Can 473 ml",
            "option_id": 461953,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286649,
        "product_id": 461955,
        "sku": "1014041_2405",
        "sku_id": 1824844,
        "price": 48.95,
        "calculated_price": 48.95,
        "sale_price": 48.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826041,
            "label": "24 X Can 473 ml",
            "option_id": 461953,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286666,
        "product_id": 461955,
        "sku": "1014042_2405",
        "sku_id": 1824862,
        "price": 25.5,
        "calculated_price": 25.5,
        "sale_price": 25.5,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826063,
            "label": "12 X Can 473 ml",
            "option_id": 461953,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286678,
        "product_id": 461955,
        "sku": "1014097_2405",
        "sku_id": 1824872,
        "price": 8.75,
        "calculated_price": 8.75,
        "sale_price": 8.75,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826074,
            "label": "4 X Can 473 ml",
            "option_id": 461953,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286689,
        "product_id": 461955,
        "sku": "1014156_2405",
        "sku_id": 1824883,
        "price": 16.85,
        "calculated_price": 16.85,
        "sale_price": 16.85,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826085,
            "label": "8 X Can 473 ml",
            "option_id": 461953,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286705,
        "product_id": 461955,
        "sku": "1014172_2405",
        "sku_id": 1824893,
        "price": 51.95,
        "calculated_price": 51.95,
        "sale_price": 51.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826095,
            "label": "30 X Can 355 ml",
            "option_id": 461953,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286721,
        "product_id": 461955,
        "sku": "1014173_2405",
        "sku_id": 1824909,
        "price": 28.95,
        "calculated_price": 28.75,
        "sale_price": 28.75,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": true,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826111,
            "label": "15 X Can 355 ml",
            "option_id": 461953,
            "option_display_name": "Beer"
          }
        ]
      }
    ],
    "images": [],
    "primary_image": null,
    "custom_fields": [
      {
        "id": 6646725,
        "name": "ABV",
        "value": "4.70"
      },
      {
        "id": 6646731,
        "name": "Country",
        "value": "CA"
      },
      {
        "id": 6646736,
        "name": "Category",
        "value": "Value"
      },
      {
        "id": 6646741,
        "name": "Type",
        "value": "Lager"
      },
      {
        "id": 6646747,
        "name": "Beverage_type",
        "value": "Beer"
      },
      {
        "id": 6646755,
        "name": "Producer",
        "value": "MOLSON"
      },
      {
        "id": 6646760,
        "name": "Beer_styles",
        "value": "Pale"
      },
      {
        "id": 6646767,
        "name": "Price_Metadata_1014004",
        "value": "{\"current_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.24}],\"total_price\":2.20},\"previous_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.24}],\"total_price\":2.15},\"on_sale\":\"N\"}"
      },
      {
        "id": 6646787,
        "name": "Price_Metadata_1014005",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":4.27}],\"total_price\":39.50},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":4.20}],\"total_price\":38.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6646792,
        "name": "Price_Metadata_1014038",
        "value": "{\"current_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.42}],\"total_price\":12.95},\"previous_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.39}],\"total_price\":12.70},\"on_sale\":\"N\"}"
      },
      {
        "id": 6646796,
        "name": "Price_Metadata_1014041",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.36}],\"total_price\":48.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.24}],\"total_price\":47.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6646801,
        "name": "Price_Metadata_1014042",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.80}],\"total_price\":25.50},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.73}],\"total_price\":24.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6646807,
        "name": "Price_Metadata_1014097",
        "value": "{\"current_price\":{\"deposit\":0.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.96}],\"total_price\":8.75},\"previous_price\":{\"deposit\":0.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.94}],\"total_price\":8.55},\"on_sale\":\"N\"}"
      },
      {
        "id": 6646811,
        "name": "Price_Metadata_1014156",
        "value": "{\"current_price\":{\"deposit\":0.80,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.85}],\"total_price\":16.85},\"previous_price\":{\"deposit\":0.80,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.89}],\"total_price\":17.25},\"on_sale\":\"N\"}"
      },
      {
        "id": 6646813,
        "name": "Price_Metadata_1014172",
        "value": "{\"current_price\":{\"deposit\":3.00,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.63}],\"total_price\":51.95},\"previous_price\":{\"deposit\":3.00,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.06}],\"total_price\":46.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6646815,
        "name": "Price_Metadata_1014173",
        "value": "{\"current_price\":{\"deposit\":1.50,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.13}],\"total_price\":28.75},\"previous_price\":{\"deposit\":1.50,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.16}],\"total_price\":28.95},\"on_sale\":\"Y\"}"
      },
      {
        "id": 6646819,
        "name": "product_image_1",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbfvnx-4lko3s-ewirns/0151.png?position=1"
      },
      {
        "id": 6646822,
        "name": "product_image_2",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbfvnx-4lko3s-ewirns/0151.png?position=2"
      },
      {
        "id": 6646825,
        "name": "container_type ",
        "value": "C,B"
      },
      {
        "id": 6646828,
        "name": "pack",
        "value": "1,24,6,12,4,8,30,15"
      }
    ]
  },
  {
    "id": 462576,
    "name": "black-oak-nut-brown-ale",
    "type": "physical",
    "sku": "5915_2405",
    "description": "Rich smooth taste, slightly roasty and chocolaty. It's a bit less hopped, but still has a fantastic balance with the malt.",
    "weight": 1,
    "width": 0,
    "depth": 0,
    "height": 0,
    "price": 69.95,
    "cost_price": 0,
    "retail_price": 0,
    "sale_price": 0,
    "map_price": 0,
    "tax_class_id": 0,
    "product_tax_code": "",
    "calculated_price": 69.95,
    "categories": [162, 198, 178, 226, 165, 170, 167],
    "brand_id": 1030,
    "option_set_id": 462473,
    "option_set_display": "right",
    "inventory_level": 38,
    "inventory_warning_level": 0,
    "inventory_tracking": "variant",
    "reviews_rating_sum": 0,
    "reviews_count": 0,
    "total_sold": 1,
    "fixed_cost_shipping_price": 0,
    "is_free_shipping": false,
    "is_visible": true,
    "is_featured": false,
    "related_products": [-1],
    "warranty": "",
    "bin_picking_number": "",
    "layout_file": "",
    "upc": "",
    "mpn": "",
    "gtin": "",
    "search_keywords": "",
    "availability": "available",
    "availability_description": "",
    "gift_wrapping_options_type": "any",
    "gift_wrapping_options_list": [],
    "sort_order": 0,
    "condition": "New",
    "is_condition_shown": false,
    "order_quantity_minimum": 0,
    "order_quantity_maximum": 0,
    "page_title": "BLACK OAK NUT BROWN ALE~2405",
    "meta_keywords": [],
    "meta_description": "",
    "date_created": "2020-10-16T13:25:01+00:00",
    "date_modified": "2021-12-22T11:23:10+00:00",
    "view_count": 0,
    "preorder_release_date": null,
    "preorder_message": "",
    "is_preorder_only": false,
    "is_price_hidden": false,
    "price_hidden_label": "",
    "custom_url": {
      "url": "/black-oak-nut-brown-ale-2405/",
      "is_customized": false
    },
    "base_variant_id": null,
    "open_graph_type": "product",
    "open_graph_title": "",
    "open_graph_description": "",
    "open_graph_use_meta_description": true,
    "open_graph_use_product_name": true,
    "open_graph_use_image": true,
    "variants": [
      {
        "id": 2289744,
        "product_id": 462576,
        "sku": "1560001_2405",
        "sku_id": 1827335,
        "price": 13.95,
        "calculated_price": 13.95,
        "sale_price": 13.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": true,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828537,
            "label": "6 X Bottle 341 ml",
            "option_id": 462569,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2289751,
        "product_id": 462576,
        "sku": "1560002_2405",
        "sku_id": 1827340,
        "price": 27.95,
        "calculated_price": 27.95,
        "sale_price": 27.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": true,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828542,
            "label": "12 X Bottle 341 ml",
            "option_id": 462569,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2289760,
        "product_id": 462576,
        "sku": "1560004_2405",
        "sku_id": 1827349,
        "price": 3.25,
        "calculated_price": 3.25,
        "sale_price": 3.25,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 38,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828551,
            "label": "1 X Can 473 ml",
            "option_id": 462569,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2289768,
        "product_id": 462576,
        "sku": "1560005_2405",
        "sku_id": 1827354,
        "price": 51.95,
        "calculated_price": 51.95,
        "sale_price": 51.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": true,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828557,
            "label": "24 X Bottle 341 ml",
            "option_id": 462569,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2289787,
        "product_id": 462576,
        "sku": "1560041_2405",
        "sku_id": 1827367,
        "price": 69.95,
        "calculated_price": 69.95,
        "sale_price": 69.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828570,
            "label": "24 X Can 473 ml",
            "option_id": 462569,
            "option_display_name": "Beer"
          }
        ]
      }
    ],
    "images": [],
    "primary_image": null,
    "custom_fields": [
      {
        "id": 6655863,
        "name": "ABV",
        "value": "5.00"
      },
      {
        "id": 6655868,
        "name": "Country",
        "value": "CA"
      },
      {
        "id": 6655871,
        "name": "Category",
        "value": "Ontario Craft"
      },
      {
        "id": 6655873,
        "name": "Type",
        "value": "Ale"
      },
      {
        "id": 6655876,
        "name": "Beverage_type",
        "value": "Beer"
      },
      {
        "id": 6655879,
        "name": "Producer",
        "value": "BLACK OAK BREWING COMPANY LTD."
      },
      {
        "id": 6655882,
        "name": "Beer_styles",
        "value": "Brown"
      },
      {
        "id": 6655884,
        "name": "Price_Metadata_1560001",
        "value": "{\"current_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.54}],\"total_price\":13.95},\"previous_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.54}],\"total_price\":13.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6655887,
        "name": "Price_Metadata_1560002",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.08}],\"total_price\":27.95},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.08}],\"total_price\":27.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6655890,
        "name": "Price_Metadata_1560004",
        "value": "{\"current_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.36}],\"total_price\":3.25},\"previous_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.36}],\"total_price\":3.25},\"on_sale\":\"N\"}"
      },
      {
        "id": 6655892,
        "name": "Price_Metadata_1560005",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.70}],\"total_price\":51.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.70}],\"total_price\":51.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6655895,
        "name": "Price_Metadata_1560041",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":7.77}],\"total_price\":69.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":7.77}],\"total_price\":69.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6655897,
        "name": "product_image_1",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbe2k6-dzurdc-dz794r/5915.png?position=1"
      },
      {
        "id": 6655901,
        "name": "product_image_2",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbe2k6-dzurdc-dz794r/5915.png?position=2"
      },
      {
        "id": 6655904,
        "name": "container_type ",
        "value": "B,C"
      },
      {
        "id": 6655907,
        "name": "pack",
        "value": "6,12,1,24"
      }
    ]
  },
  {
    "id": 462577,
    "name": "black-oak-pale-ale",
    "type": "physical",
    "sku": "5911_2405",
    "description": "A medium bodied brand, with a beautiful golden colour, good mouth feel and refreshing hop finish.",
    "weight": 1,
    "width": 0,
    "depth": 0,
    "height": 0,
    "price": 69.95,
    "cost_price": 0,
    "retail_price": 0,
    "sale_price": 0,
    "map_price": 0,
    "tax_class_id": 0,
    "product_tax_code": "",
    "calculated_price": 69.95,
    "categories": [162, 198, 178, 182, 165, 175, 202, 176, 170, 177, 167],
    "brand_id": 1030,
    "option_set_id": 462475,
    "option_set_display": "right",
    "inventory_level": 45,
    "inventory_warning_level": 0,
    "inventory_tracking": "variant",
    "reviews_rating_sum": 0,
    "reviews_count": 0,
    "total_sold": 0,
    "fixed_cost_shipping_price": 0,
    "is_free_shipping": false,
    "is_visible": true,
    "is_featured": false,
    "related_products": [-1],
    "warranty": "",
    "bin_picking_number": "",
    "layout_file": "",
    "upc": "",
    "mpn": "",
    "gtin": "",
    "search_keywords": "",
    "availability": "available",
    "availability_description": "",
    "gift_wrapping_options_type": "any",
    "gift_wrapping_options_list": [],
    "sort_order": 0,
    "condition": "New",
    "is_condition_shown": false,
    "order_quantity_minimum": 0,
    "order_quantity_maximum": 0,
    "page_title": "BLACK OAK PALE ALE~2405",
    "meta_keywords": [],
    "meta_description": "",
    "date_created": "2020-10-16T13:25:01+00:00",
    "date_modified": "2021-08-28T18:26:01+00:00",
    "view_count": 0,
    "preorder_release_date": null,
    "preorder_message": "",
    "is_preorder_only": false,
    "is_price_hidden": false,
    "price_hidden_label": "",
    "custom_url": {
      "url": "/black-oak-pale-ale-2405/",
      "is_customized": false
    },
    "base_variant_id": null,
    "open_graph_type": "product",
    "open_graph_title": "",
    "open_graph_description": "",
    "open_graph_use_meta_description": true,
    "open_graph_use_product_name": true,
    "open_graph_use_image": true,
    "variants": [
      {
        "id": 2289749,
        "product_id": 462577,
        "sku": "1559001_2405",
        "sku_id": 1827338,
        "price": 13.95,
        "calculated_price": 13.95,
        "sale_price": 13.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828540,
            "label": "6 X Bottle 341 ml",
            "option_id": 462571,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2289757,
        "product_id": 462577,
        "sku": "1559002_2405",
        "sku_id": 1827347,
        "price": 27.95,
        "calculated_price": 27.95,
        "sale_price": 27.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828549,
            "label": "12 X Bottle 341 ml",
            "option_id": 462571,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2289764,
        "product_id": 462577,
        "sku": "1559004_2405",
        "sku_id": 1827353,
        "price": 3.1,
        "calculated_price": 3.1,
        "sale_price": 3.1,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 45,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828555,
            "label": "1 X Can 473 ml",
            "option_id": 462571,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2289778,
        "product_id": 462577,
        "sku": "1559005_2405",
        "sku_id": 1827360,
        "price": 51.95,
        "calculated_price": 51.95,
        "sale_price": 51.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828566,
            "label": "24 X Bottle 341 ml",
            "option_id": 462571,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2289794,
        "product_id": 462577,
        "sku": "1559041_2405",
        "sku_id": 1827373,
        "price": 69.95,
        "calculated_price": 69.95,
        "sale_price": 69.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828575,
            "label": "24 X Can 473 ml",
            "option_id": 462571,
            "option_display_name": "Beer"
          }
        ]
      }
    ],
    "images": [],
    "primary_image": null,
    "custom_fields": [
      {
        "id": 6655881,
        "name": "ABV",
        "value": "5.00"
      },
      {
        "id": 6655885,
        "name": "Country",
        "value": "CA"
      },
      {
        "id": 6655888,
        "name": "Category",
        "value": "Ontario Craft"
      },
      {
        "id": 6655891,
        "name": "Type",
        "value": "Ale"
      },
      {
        "id": 6655894,
        "name": "Beverage_type",
        "value": "Beer"
      },
      {
        "id": 6655898,
        "name": "Producer",
        "value": "BLACK OAK BREWING COMPANY LTD."
      },
      {
        "id": 6655902,
        "name": "Beer_styles",
        "value": "Pale"
      },
      {
        "id": 6655906,
        "name": "Price_Metadata_1559001",
        "value": "{\"current_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.54}],\"total_price\":13.95},\"previous_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.54}],\"total_price\":13.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6655910,
        "name": "Price_Metadata_1559002",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.08}],\"total_price\":27.95},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.08}],\"total_price\":27.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6655914,
        "name": "Price_Metadata_1559004",
        "value": "{\"current_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.35}],\"total_price\":3.10},\"previous_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.35}],\"total_price\":3.10},\"on_sale\":\"N\"}"
      },
      {
        "id": 6655915,
        "name": "Price_Metadata_1559005",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.70}],\"total_price\":51.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.70}],\"total_price\":51.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6655919,
        "name": "Price_Metadata_1559041",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":7.77}],\"total_price\":69.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":7.77}],\"total_price\":69.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6655921,
        "name": "product_image_1",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbe2k6-dzurdc-24tkzo/5911.png?position=1"
      },
      {
        "id": 6655924,
        "name": "product_image_2",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbe2k6-dzurdc-24tkzo/5911.png?position=2"
      },
      {
        "id": 6655927,
        "name": "container_type ",
        "value": "B,C"
      },
      {
        "id": 6655930,
        "name": "pack",
        "value": "6,12,1,24"
      }
    ]
  },
  {
    "id": 462202,
    "name": "blanche-de-chambly",
    "type": "physical",
    "sku": "2020_2405",
    "description": "UNIBROUE Blanche de Chambly: This smooth, citrusy witbier is brewed with Canadian wheat and subtle spices, and is the first North American refermented white ale brewed according to Belgian tradition.\n",
    "weight": 1,
    "width": 0,
    "depth": 0,
    "height": 0,
    "price": 5.85,
    "cost_price": 0,
    "retail_price": 0,
    "sale_price": 0,
    "map_price": 0,
    "tax_class_id": 0,
    "product_tax_code": "",
    "calculated_price": 5.85,
    "categories": [
      162, 163, 169, 195, 222, 180, 165, 170, 171, 167, 166, 181, 172, 173,
      212, 209
    ],
    "brand_id": 1030,
    "option_set_id": 462109,
    "option_set_display": "right",
    "inventory_level": 79,
    "inventory_warning_level": 0,
    "inventory_tracking": "variant",
    "reviews_rating_sum": 0,
    "reviews_count": 0,
    "total_sold": 2,
    "fixed_cost_shipping_price": 0,
    "is_free_shipping": false,
    "is_visible": true,
    "is_featured": false,
    "related_products": [-1],
    "warranty": "",
    "bin_picking_number": "",
    "layout_file": "",
    "upc": "",
    "mpn": "",
    "gtin": "",
    "search_keywords": "",
    "availability": "available",
    "availability_description": "",
    "gift_wrapping_options_type": "any",
    "gift_wrapping_options_list": [],
    "sort_order": 0,
    "condition": "New",
    "is_condition_shown": false,
    "order_quantity_minimum": 0,
    "order_quantity_maximum": 0,
    "page_title": "BLANCHE DE CHAMBLY~2405",
    "meta_keywords": [],
    "meta_description": "",
    "date_created": "2020-10-16T13:24:50+00:00",
    "date_modified": "2021-12-22T19:20:47+00:00",
    "view_count": 0,
    "preorder_release_date": null,
    "preorder_message": "",
    "is_preorder_only": false,
    "is_price_hidden": false,
    "price_hidden_label": "",
    "custom_url": {
      "url": "/blanche-de-chambly-2405/",
      "is_customized": false
    },
    "base_variant_id": null,
    "open_graph_type": "product",
    "open_graph_title": "",
    "open_graph_description": "",
    "open_graph_use_meta_description": true,
    "open_graph_use_product_name": true,
    "open_graph_use_image": true,
    "variants": [
      {
        "id": 2287968,
        "product_id": 462202,
        "sku": "1126004_2405",
        "sku_id": 1825925,
        "price": 3,
        "calculated_price": 3,
        "sale_price": 3,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 79,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1827125,
            "label": "1 X Can 473 ml",
            "option_id": 462204,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2287987,
        "product_id": 462202,
        "sku": "1126005_2405",
        "sku_id": 1825940,
        "price": 51.95,
        "calculated_price": 51.95,
        "sale_price": 51.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": true,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1827142,
            "label": "24 X Bottle 341 ml",
            "option_id": 462204,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2288007,
        "product_id": 462202,
        "sku": "1126038_2405",
        "sku_id": 1825954,
        "price": 17.25,
        "calculated_price": 17.25,
        "sale_price": 17.25,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1827155,
            "label": "6 X Can 473 ml",
            "option_id": 462204,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2288024,
        "product_id": 462202,
        "sku": "1126041_2405",
        "sku_id": 1825971,
        "price": 59.95,
        "calculated_price": 59.95,
        "sale_price": 59.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1827173,
            "label": "24 X Can 473 ml",
            "option_id": 462204,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2288035,
        "product_id": 462202,
        "sku": "1126042_2405",
        "sku_id": 1825982,
        "price": 31.95,
        "calculated_price": 31.95,
        "sale_price": 31.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1827185,
            "label": "12 X Can 473 ml",
            "option_id": 462204,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2288060,
        "product_id": 462202,
        "sku": "1126089_2405",
        "sku_id": 1826003,
        "price": 223.75,
        "calculated_price": 223.75,
        "sale_price": 223.75,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1827205,
            "label": "1 X Keg 30000 ml",
            "option_id": 462204,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2288085,
        "product_id": 462202,
        "sku": "1126097_2405",
        "sku_id": 1826019,
        "price": 11.95,
        "calculated_price": 11.95,
        "sale_price": 11.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1827221,
            "label": "4 X Can 473 ml",
            "option_id": 462204,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2288109,
        "product_id": 462202,
        "sku": "1126156_2405",
        "sku_id": 1826042,
        "price": 22.95,
        "calculated_price": 22.95,
        "sale_price": 22.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1827243,
            "label": "8 X Can 473 ml",
            "option_id": 462204,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2288125,
        "product_id": 462202,
        "sku": "1126223_2405",
        "sku_id": 1826057,
        "price": 47.75,
        "calculated_price": 47.75,
        "sale_price": 47.75,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1827259,
            "label": "18 X Can 473 ml",
            "option_id": 462204,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2288141,
        "product_id": 462202,
        "sku": "1126293_2405",
        "sku_id": 1826072,
        "price": 5.85,
        "calculated_price": 5.85,
        "sale_price": 5.85,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1827275,
            "label": "2 X Can 473 ml",
            "option_id": 462204,
            "option_display_name": "Beer"
          }
        ]
      }
    ],
    "images": [],
    "primary_image": null,
    "custom_fields": [
      {
        "id": 6650462,
        "name": "ABV",
        "value": "5.00"
      },
      {
        "id": 6650485,
        "name": "Country",
        "value": "CA"
      },
      {
        "id": 6650490,
        "name": "Category",
        "value": "Domestic Specialty"
      },
      {
        "id": 6650494,
        "name": "Type",
        "value": "Lager"
      },
      {
        "id": 6650496,
        "name": "Beverage_type",
        "value": "Beer"
      },
      {
        "id": 6650498,
        "name": "Producer",
        "value": "UNIBROUE"
      },
      {
        "id": 6650500,
        "name": "Beer_attributes",
        "value": "Light"
      },
      {
        "id": 6650503,
        "name": "Beer_styles",
        "value": "Wheat"
      },
      {
        "id": 6650507,
        "name": "Price_Metadata_1126004",
        "value": "{\"current_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.33}],\"total_price\":3.00},\"previous_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.33}],\"total_price\":2.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6650512,
        "name": "Price_Metadata_1126005",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.70}],\"total_price\":51.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.70}],\"total_price\":51.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6650519,
        "name": "Price_Metadata_1126038",
        "value": "{\"current_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.92}],\"total_price\":17.25},\"previous_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.88}],\"total_price\":16.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6650528,
        "name": "Price_Metadata_1126041",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":6.62}],\"total_price\":59.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":7.20}],\"total_price\":64.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6650533,
        "name": "Price_Metadata_1126042",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.54}],\"total_price\":31.95},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.59}],\"total_price\":32.40},\"on_sale\":\"N\"}"
      },
      {
        "id": 6650541,
        "name": "Price_Metadata_1126089",
        "value": "{\"current_price\":{\"deposit\":50.00,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":19.99}],\"total_price\":223.75},\"previous_price\":{\"deposit\":50.00,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":19.99}],\"total_price\":223.75},\"on_sale\":\"N\"}"
      },
      {
        "id": 6650550,
        "name": "Price_Metadata_1126097",
        "value": "{\"current_price\":{\"deposit\":0.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.33}],\"total_price\":11.95},\"previous_price\":{\"deposit\":0.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.30}],\"total_price\":11.70},\"on_sale\":\"N\"}"
      },
      {
        "id": 6650555,
        "name": "Price_Metadata_1126156",
        "value": "{\"current_price\":{\"deposit\":0.80,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.55}],\"total_price\":22.95},\"previous_price\":{\"deposit\":0.80,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.53}],\"total_price\":22.75},\"on_sale\":\"N\"}"
      },
      {
        "id": 6650558,
        "name": "Price_Metadata_1126223",
        "value": "{\"current_price\":{\"deposit\":1.80,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.29}],\"total_price\":47.75},\"previous_price\":{\"deposit\":1.80,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.38}],\"total_price\":48.60},\"on_sale\":\"N\"}"
      },
      {
        "id": 6650561,
        "name": "Price_Metadata_1126293",
        "value": "{\"current_price\":{\"deposit\":0.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.65}],\"total_price\":5.85},\"previous_price\":{\"deposit\":0.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.65}],\"total_price\":5.85},\"on_sale\":\"N\"}"
      },
      {
        "id": 6650563,
        "name": "product_image_1",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbees0-bk6quw-fodser/2020.png?position=1"
      },
      {
        "id": 6650568,
        "name": "product_image_2",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbees0-bk6quw-fodser/2020.png?position=2"
      },
      {
        "id": 6650573,
        "name": "container_type ",
        "value": "C,B,K"
      },
      {
        "id": 6650577,
        "name": "pack",
        "value": "1,24,6,12,4,8,18,2"
      }
    ]
  },
  {
    "id": 462667,
    "name": "blue-ice",
    "type": "physical",
    "sku": "7826_2405",
    "description": "Labatt Blue ICE is a clean, refreshing pilsner with a distinctive hoppy aroma, a delicate character, and a slightly sweet after-taste.",
    "weight": 1,
    "width": 0,
    "depth": 0,
    "height": 0,
    "price": 16,
    "cost_price": 0,
    "retail_price": 0,
    "sale_price": 0,
    "map_price": 0,
    "tax_class_id": 0,
    "product_tax_code": "",
    "calculated_price": 16,
    "categories": [
      162, 168, 169, 165, 175, 170, 177, 171, 167, 166, 172, 173
    ],
    "brand_id": 1030,
    "option_set_id": 462565,
    "option_set_display": "right",
    "inventory_level": 252,
    "inventory_warning_level": 0,
    "inventory_tracking": "variant",
    "reviews_rating_sum": 0,
    "reviews_count": 0,
    "total_sold": 1,
    "fixed_cost_shipping_price": 0,
    "is_free_shipping": false,
    "is_visible": true,
    "is_featured": false,
    "related_products": [-1],
    "warranty": "",
    "bin_picking_number": "",
    "layout_file": "",
    "upc": "",
    "mpn": "",
    "gtin": "",
    "search_keywords": "",
    "availability": "available",
    "availability_description": "",
    "gift_wrapping_options_type": "any",
    "gift_wrapping_options_list": [],
    "sort_order": 0,
    "condition": "New",
    "is_condition_shown": false,
    "order_quantity_minimum": 0,
    "order_quantity_maximum": 0,
    "page_title": "BLUE ICE~2405",
    "meta_keywords": [],
    "meta_description": "",
    "date_created": "2020-10-16T13:25:04+00:00",
    "date_modified": "2021-12-22T01:23:56+00:00",
    "view_count": 0,
    "preorder_release_date": null,
    "preorder_message": "",
    "is_preorder_only": false,
    "is_price_hidden": false,
    "price_hidden_label": "",
    "custom_url": {
      "url": "/blue-ice-2405/",
      "is_customized": false
    },
    "base_variant_id": null,
    "open_graph_type": "product",
    "open_graph_title": "",
    "open_graph_description": "",
    "open_graph_use_meta_description": true,
    "open_graph_use_product_name": true,
    "open_graph_use_image": true,
    "variants": [
      {
        "id": 2290189,
        "product_id": 462667,
        "sku": "1656004_2405",
        "sku_id": 1827688,
        "price": 2,
        "calculated_price": 2,
        "sale_price": 2,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 249,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828890,
            "label": "1 X Can 473 ml",
            "option_id": 462661,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290198,
        "product_id": 462667,
        "sku": "1656005_2405",
        "sku_id": 1827695,
        "price": 39.5,
        "calculated_price": 39.5,
        "sale_price": 39.5,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 3,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828897,
            "label": "24 X Bottle 341 ml",
            "option_id": 462661,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290208,
        "product_id": 462667,
        "sku": "1656038_2405",
        "sku_id": 1827704,
        "price": 11.95,
        "calculated_price": 11.95,
        "sale_price": 11.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828906,
            "label": "6 X Can 473 ml",
            "option_id": 462661,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290219,
        "product_id": 462667,
        "sku": "1656041_2405",
        "sku_id": 1827713,
        "price": 47.95,
        "calculated_price": 47.95,
        "sale_price": 47.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828915,
            "label": "24 X Can 473 ml",
            "option_id": 462661,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290229,
        "product_id": 462667,
        "sku": "1656042_2405",
        "sku_id": 1827721,
        "price": 23.95,
        "calculated_price": 23.95,
        "sale_price": 23.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828923,
            "label": "12 X Can 473 ml",
            "option_id": 462661,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290242,
        "product_id": 462667,
        "sku": "1656097_2405",
        "sku_id": 1827730,
        "price": 7.95,
        "calculated_price": 7.95,
        "sale_price": 7.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828933,
            "label": "4 X Can 473 ml",
            "option_id": 462661,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290259,
        "product_id": 462667,
        "sku": "1656156_2405",
        "sku_id": 1827744,
        "price": 16,
        "calculated_price": 16,
        "sale_price": 16,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828946,
            "label": "8 X Can 473 ml",
            "option_id": 462661,
            "option_display_name": "Beer"
          }
        ]
      }
    ],
    "images": [],
    "primary_image": null,
    "custom_fields": [
      {
        "id": 6657234,
        "name": "ABV",
        "value": "5.50"
      },
      {
        "id": 6657237,
        "name": "Country",
        "value": "CA"
      },
      {
        "id": 6657239,
        "name": "Category",
        "value": "Value"
      },
      {
        "id": 6657242,
        "name": "Type",
        "value": "Lager"
      },
      {
        "id": 6657245,
        "name": "Beverage_type",
        "value": "Beer"
      },
      {
        "id": 6657248,
        "name": "Producer",
        "value": "LABATT"
      },
      {
        "id": 6657252,
        "name": "Price_Metadata_1656004",
        "value": "{\"current_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.22}],\"total_price\":2.00},\"previous_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.24}],\"total_price\":2.15},\"on_sale\":\"N\"}"
      },
      {
        "id": 6657256,
        "name": "Price_Metadata_1656005",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":4.27}],\"total_price\":39.50},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":4.20}],\"total_price\":38.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6657259,
        "name": "Price_Metadata_1656038",
        "value": "{\"current_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.31}],\"total_price\":11.95},\"previous_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.42}],\"total_price\":12.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6657261,
        "name": "Price_Metadata_1656041",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.24}],\"total_price\":47.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.36}],\"total_price\":48.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6657264,
        "name": "Price_Metadata_1656042",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.62}],\"total_price\":23.95},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.73}],\"total_price\":24.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6657266,
        "name": "Price_Metadata_1656097",
        "value": "{\"current_price\":{\"deposit\":0.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.87}],\"total_price\":7.95},\"previous_price\":{\"deposit\":0.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.94}],\"total_price\":8.55},\"on_sale\":\"N\"}"
      },
      {
        "id": 6657269,
        "name": "Price_Metadata_1656156",
        "value": "{\"current_price\":{\"deposit\":0.80,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.75}],\"total_price\":16.00},\"previous_price\":{\"deposit\":0.80,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.85}],\"total_price\":16.85},\"on_sale\":\"N\"}"
      },
      {
        "id": 6657272,
        "name": "product_image_1",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbg6uc-18fa2o-fviydm/7826.png?position=1"
      },
      {
        "id": 6657275,
        "name": "product_image_2",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/ng5frpq9sfj5qt9z3jrt74nv/7826.png?position=1"
      },
      {
        "id": 6657276,
        "name": "container_type ",
        "value": "C,B"
      },
      {
        "id": 6657279,
        "name": "pack",
        "value": "1,24,6,12,4,8"
      },
      {
        "id": 12417250,
        "name": "product_image_3",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbg6uc-18fa2o-fviydm/7826.png?position=2"
      },
      {
        "id": 12417251,
        "name": "product_image_4",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/ng5frpq9sfj5qt9z3jrt74nv/7826.png?position=2"
      }
    ]
  },
  {
    "id": 461988,
    "name": "blue-light",
    "type": "physical",
    "sku": "573_2405",
    "description": "A fresh, crisp, highly drinkable light beer with a fruity hop aroma, pleasant ester, slightly sweet taste, and little aftertaste brewed to Labatt's highest quality standards.",
    "weight": 1,
    "width": 0,
    "depth": 0,
    "height": 0,
    "price": 83.9,
    "cost_price": 0,
    "retail_price": 0,
    "sale_price": 0,
    "map_price": 0,
    "tax_class_id": 0,
    "product_tax_code": "",
    "calculated_price": 83.9,
    "categories": [
      162, 168, 189, 195, 192, 182, 165, 175, 176, 177, 171, 166, 167, 204,
      210, 205
    ],
    "brand_id": 1030,
    "option_set_id": 461886,
    "option_set_display": "right",
    "inventory_level": 89,
    "inventory_warning_level": 0,
    "inventory_tracking": "variant",
    "reviews_rating_sum": 0,
    "reviews_count": 0,
    "total_sold": 1,
    "fixed_cost_shipping_price": 0,
    "is_free_shipping": false,
    "is_visible": true,
    "is_featured": false,
    "related_products": [-1],
    "warranty": "",
    "bin_picking_number": "",
    "layout_file": "",
    "upc": "",
    "mpn": "",
    "gtin": "",
    "search_keywords": "",
    "availability": "available",
    "availability_description": "",
    "gift_wrapping_options_type": "any",
    "gift_wrapping_options_list": [],
    "sort_order": 0,
    "condition": "New",
    "is_condition_shown": false,
    "order_quantity_minimum": 0,
    "order_quantity_maximum": 0,
    "page_title": "BLUE LIGHT~2405",
    "meta_keywords": [],
    "meta_description": "",
    "date_created": "2020-10-16T13:24:43+00:00",
    "date_modified": "2021-12-22T19:20:49+00:00",
    "view_count": 0,
    "preorder_release_date": null,
    "preorder_message": "",
    "is_preorder_only": false,
    "is_price_hidden": false,
    "price_hidden_label": "",
    "custom_url": {
      "url": "/blue-light-2405/",
      "is_customized": false
    },
    "base_variant_id": null,
    "open_graph_type": "product",
    "open_graph_title": "",
    "open_graph_description": "",
    "open_graph_use_meta_description": true,
    "open_graph_use_product_name": true,
    "open_graph_use_image": true,
    "variants": [
      {
        "id": 2286780,
        "product_id": 461988,
        "sku": "1040002_2405",
        "sku_id": 1824958,
        "price": 22.95,
        "calculated_price": 22.95,
        "sale_price": 22.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 14,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826160,
            "label": "12 X Bottle 341 ml",
            "option_id": 461982,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286794,
        "product_id": 461988,
        "sku": "1040005_2405",
        "sku_id": 1824972,
        "price": 39.5,
        "calculated_price": 39.5,
        "sale_price": 39.5,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 19,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826174,
            "label": "24 X Bottle 341 ml",
            "option_id": 461982,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286808,
        "product_id": 461988,
        "sku": "1040030_2405",
        "sku_id": 1824985,
        "price": 11.95,
        "calculated_price": 11.95,
        "sale_price": 11.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 36,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826187,
            "label": "6 X Can 355 ml",
            "option_id": 461982,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286821,
        "product_id": 461988,
        "sku": "1040031_2405",
        "sku_id": 1824997,
        "price": 23.5,
        "calculated_price": 23.5,
        "sale_price": 23.5,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826199,
            "label": "12 X Can 355 ml",
            "option_id": 461982,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286831,
        "product_id": 461988,
        "sku": "1040033_2405",
        "sku_id": 1825006,
        "price": 41.95,
        "calculated_price": 41.95,
        "sale_price": 41.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826208,
            "label": "24 X Can 355 ml",
            "option_id": 461982,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286842,
        "product_id": 461988,
        "sku": "1040160_2405",
        "sku_id": 1825015,
        "price": 27.95,
        "calculated_price": 27.95,
        "sale_price": 27.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 20,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826217,
            "label": "15 X Bottle 341 ml",
            "option_id": 461982,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286859,
        "product_id": 461988,
        "sku": "1040171_2405",
        "sku_id": 1825032,
        "price": 46.95,
        "calculated_price": 46.95,
        "sale_price": 46.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826232,
            "label": "30 X Bottle 341 ml",
            "option_id": 461982,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286875,
        "product_id": 461988,
        "sku": "1040211_2405",
        "sku_id": 1825044,
        "price": 83.9,
        "calculated_price": 83.9,
        "sale_price": 83.9,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826246,
            "label": "48 X Can 355 ml",
            "option_id": 461982,
            "option_display_name": "Beer"
          }
        ]
      }
    ],
    "images": [],
    "primary_image": null,
    "custom_fields": [
      {
        "id": 6647304,
        "name": "ABV",
        "value": "4.00"
      },
      {
        "id": 6647307,
        "name": "Country",
        "value": "CA"
      },
      {
        "id": 6647309,
        "name": "Category",
        "value": "Value"
      },
      {
        "id": 6647311,
        "name": "Type",
        "value": "Light"
      },
      {
        "id": 6647312,
        "name": "Beverage_type",
        "value": "Beer"
      },
      {
        "id": 6647313,
        "name": "Producer",
        "value": "LABATT"
      },
      {
        "id": 6647314,
        "name": "Beer_attributes",
        "value": "Light"
      },
      {
        "id": 6647316,
        "name": "Beer_styles",
        "value": "Pilsner,Pale"
      },
      {
        "id": 6647318,
        "name": "Price_Metadata_1040002",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.50}],\"total_price\":22.95},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.45}],\"total_price\":22.50},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647320,
        "name": "Price_Metadata_1040005",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":4.27}],\"total_price\":39.50},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":4.20}],\"total_price\":38.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647321,
        "name": "Price_Metadata_1040030",
        "value": "{\"current_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.31}],\"total_price\":11.95},\"previous_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.28}],\"total_price\":11.75},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647324,
        "name": "Price_Metadata_1040031",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.57}],\"total_price\":23.50},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.50}],\"total_price\":22.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647326,
        "name": "Price_Metadata_1040033",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":4.55}],\"total_price\":41.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":4.32}],\"total_price\":39.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647328,
        "name": "Price_Metadata_1040160",
        "value": "{\"current_price\":{\"deposit\":1.50,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.04}],\"total_price\":27.95},\"previous_price\":{\"deposit\":1.50,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.02}],\"total_price\":27.75},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647332,
        "name": "Price_Metadata_1040171",
        "value": "{\"current_price\":{\"deposit\":3.00,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.06}],\"total_price\":46.95},\"previous_price\":{\"deposit\":3.00,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.00}],\"total_price\":46.50},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647335,
        "name": "Price_Metadata_1040211",
        "value": "{\"current_price\":{\"deposit\":4.80,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":9.10}],\"total_price\":83.90},\"previous_price\":{\"deposit\":4.80,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":9.45}],\"total_price\":86.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647339,
        "name": "product_image_1",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbg6vd-akkid4-1l4qkq/0573_0.png?position=1"
      },
      {
        "id": 6647343,
        "name": "product_image_2",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbg6vd-akkid4-1l4qkq/0573_0.png?position=2"
      },
      {
        "id": 6647348,
        "name": "container_type ",
        "value": "B,C"
      },
      {
        "id": 6647352,
        "name": "pack",
        "value": "12,24,6,15,30,48"
      }
    ]
  },
  {
    "id": 462291,
    "name": "boxer-ice",
    "type": "physical",
    "sku": "2223_2405",
    "description": "Boxer Ice is a Canadian-style lager made with 2-row Canadian malt and domestic hops. Boxer Ice is lagered for 4 weeks prior to canning. This full bodied crisp beer starts with a gentle maltiness and clean finish.",
    "weight": 1,
    "width": 0,
    "depth": 0,
    "height": 0,
    "price": 2.8,
    "cost_price": 0,
    "retail_price": 0,
    "sale_price": 0,
    "map_price": 0,
    "tax_class_id": 0,
    "product_tax_code": "",
    "calculated_price": 2.8,
    "categories": [239, 168, 169, 195, 182, 165, 170],
    "brand_id": 1030,
    "option_set_id": 462188,
    "option_set_display": "right",
    "inventory_level": 339,
    "inventory_warning_level": 0,
    "inventory_tracking": "variant",
    "reviews_rating_sum": 0,
    "reviews_count": 0,
    "total_sold": 0,
    "fixed_cost_shipping_price": 0,
    "is_free_shipping": false,
    "is_visible": true,
    "is_featured": false,
    "related_products": [-1],
    "warranty": "",
    "bin_picking_number": "",
    "layout_file": "",
    "upc": "",
    "mpn": "",
    "gtin": "",
    "search_keywords": "",
    "availability": "available",
    "availability_description": "",
    "gift_wrapping_options_type": "any",
    "gift_wrapping_options_list": [],
    "sort_order": 0,
    "condition": "New",
    "is_condition_shown": false,
    "order_quantity_minimum": 0,
    "order_quantity_maximum": 0,
    "page_title": "BOXER ICE~2405",
    "meta_keywords": [],
    "meta_description": "",
    "date_created": "2020-10-16T13:24:53+00:00",
    "date_modified": "2021-12-22T11:25:15+00:00",
    "view_count": 0,
    "preorder_release_date": null,
    "preorder_message": "",
    "is_preorder_only": false,
    "is_price_hidden": false,
    "price_hidden_label": "",
    "custom_url": {
      "url": "/boxer-ice-2405/",
      "is_customized": false
    },
    "base_variant_id": null,
    "open_graph_type": "product",
    "open_graph_title": "",
    "open_graph_description": "",
    "open_graph_use_meta_description": true,
    "open_graph_use_product_name": true,
    "open_graph_use_image": true,
    "variants": [
      {
        "id": 2288411,
        "product_id": 462291,
        "sku": "1230024_2405",
        "sku_id": 1826288,
        "price": 2.8,
        "calculated_price": 2.8,
        "sale_price": 2.8,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 339,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1827490,
            "label": "1 X Can 710 ml",
            "option_id": 462284,
            "option_display_name": "Beer"
          }
        ]
      }
    ],
    "images": [],
    "primary_image": null,
    "custom_fields": [
      {
        "id": 6651771,
        "name": "ABV",
        "value": "5.50"
      },
      {
        "id": 6651774,
        "name": "Country",
        "value": "US"
      },
      {
        "id": 6651776,
        "name": "Category",
        "value": "Value"
      },
      {
        "id": 6651778,
        "name": "Type",
        "value": "Lager"
      },
      {
        "id": 6651781,
        "name": "Beverage_type",
        "value": "Beer"
      },
      {
        "id": 6651783,
        "name": "Producer",
        "value": "MOUNTAIN CREST SRL LLC"
      },
      {
        "id": 6651785,
        "name": "Beer_attributes",
        "value": "Light"
      },
      {
        "id": 6651788,
        "name": "Beer_styles",
        "value": "Pale"
      },
      {
        "id": 6651789,
        "name": "Price_Metadata_1230024",
        "value": "{\"current_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.31}],\"total_price\":2.80},\"previous_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.31}],\"total_price\":2.80},\"on_sale\":\"N\"}"
      },
      {
        "id": 6651792,
        "name": "product_image_1",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbfxpq-8sberc-e7461u/2223.png?position=1"
      },
      {
        "id": 6651794,
        "name": "product_image_2",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbfxpq-8sberc-e7461u/2223.png?position=2"
      },
      {
        "id": 6651797,
        "name": "container_type ",
        "value": "C"
      },
      {
        "id": 6651800,
        "name": "pack",
        "value": "1"
      }
    ]
  },
  {
    "id": 462348,
    "name": "boxer-lager-gluten-free",
    "type": "physical",
    "sku": "2424_2405",
    "description": "Boxer Lager Gluten Free is a classic lager beer made from sorghum for consumers with Celiac Disease. A light body is complemented by moderate hopping to make a crisp refreshing beer.The citrus palate gives way to a light sweetness making this a very refreshing and drinkable Gluten Free Lager.",
    "weight": 1,
    "width": 0,
    "depth": 0,
    "height": 0,
    "price": 72,
    "cost_price": 0,
    "retail_price": 0,
    "sale_price": 0,
    "map_price": 0,
    "tax_class_id": 0,
    "product_tax_code": "",
    "calculated_price": 72,
    "categories": [239, 232, 169, 234, 165, 171, 205],
    "brand_id": 1030,
    "option_set_id": 462246,
    "option_set_display": "right",
    "inventory_level": 12,
    "inventory_warning_level": 0,
    "inventory_tracking": "variant",
    "reviews_rating_sum": 0,
    "reviews_count": 0,
    "total_sold": 1,
    "fixed_cost_shipping_price": 0,
    "is_free_shipping": false,
    "is_visible": true,
    "is_featured": false,
    "related_products": [-1],
    "warranty": "",
    "bin_picking_number": "",
    "layout_file": "",
    "upc": "",
    "mpn": "",
    "gtin": "",
    "search_keywords": "",
    "availability": "available",
    "availability_description": "",
    "gift_wrapping_options_type": "any",
    "gift_wrapping_options_list": [],
    "sort_order": 0,
    "condition": "New",
    "is_condition_shown": false,
    "order_quantity_minimum": 0,
    "order_quantity_maximum": 0,
    "page_title": "BOXER LAGER GLUTEN FREE~2405",
    "meta_keywords": [],
    "meta_description": "",
    "date_created": "2020-10-16T13:24:54+00:00",
    "date_modified": "2021-12-05T11:24:04+00:00",
    "view_count": 0,
    "preorder_release_date": null,
    "preorder_message": "",
    "is_preorder_only": false,
    "is_price_hidden": false,
    "price_hidden_label": "",
    "custom_url": {
      "url": "/boxer-lager-gluten-free-2405/",
      "is_customized": false
    },
    "base_variant_id": null,
    "open_graph_type": "product",
    "open_graph_title": "",
    "open_graph_description": "",
    "open_graph_use_meta_description": true,
    "open_graph_use_product_name": true,
    "open_graph_use_image": true,
    "variants": [
      {
        "id": 2288620,
        "product_id": 462348,
        "sku": "1341030_2405",
        "sku_id": 1826438,
        "price": 9.8,
        "calculated_price": 9.8,
        "sale_price": 9.8,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 12,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1827639,
            "label": "6 X Can 355 ml",
            "option_id": 462342,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2288633,
        "product_id": 462348,
        "sku": "1341211_2405",
        "sku_id": 1826446,
        "price": 72,
        "calculated_price": 72,
        "sale_price": 72,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1827648,
            "label": "48 X Can 355 ml",
            "option_id": 462342,
            "option_display_name": "Beer"
          }
        ]
      }
    ],
    "images": [],
    "primary_image": null,
    "custom_fields": [
      {
        "id": 6652515,
        "name": "ABV",
        "value": "5.00"
      },
      {
        "id": 6652518,
        "name": "Country",
        "value": "US"
      },
      {
        "id": 6652520,
        "name": "Category",
        "value": "Import"
      },
      {
        "id": 6652522,
        "name": "Type",
        "value": "Lager"
      },
      {
        "id": 6652524,
        "name": "Beverage_type",
        "value": "Beer"
      },
      {
        "id": 6652526,
        "name": "Producer",
        "value": "MOUNTAIN CREST SRL LLC"
      },
      {
        "id": 6652527,
        "name": "Beer_attributes",
        "value": "Gluten Free"
      },
      {
        "id": 6652528,
        "name": "Price_Metadata_1341030",
        "value": "{\"current_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.06}],\"total_price\":9.80},\"previous_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.06}],\"total_price\":9.80},\"on_sale\":\"N\"}"
      },
      {
        "id": 6652529,
        "name": "Price_Metadata_1341211",
        "value": "{\"current_price\":{\"deposit\":4.80,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":7.73}],\"total_price\":72.00},\"previous_price\":{\"deposit\":4.80,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":7.73}],\"total_price\":72.00},\"on_sale\":\"N\"}"
      },
      {
        "id": 6652530,
        "name": "product_image_1",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbfxqi-53ryds-cc35u4/2424_0.png?position=1"
      },
      {
        "id": 6652532,
        "name": "product_image_2",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbfxqi-53ryds-cc35u4/2424_0.png?position=2"
      },
      {
        "id": 6652533,
        "name": "container_type ",
        "value": "C"
      },
      {
        "id": 6652535,
        "name": "pack",
        "value": "6,48"
      }
    ]
  },
  {
    "id": 462683,
    "name": "brava",
    "type": "physical",
    "sku": "7888_2405",
    "description": "Brava is a premium lager that is brewed in small batches using only the finest, hand-selected hops and barley. The result is a light, crisp, distinctive lager that is easy drinking and very refreshing.",
    "weight": 1,
    "width": 0,
    "depth": 0,
    "height": 0,
    "price": 28.95,
    "cost_price": 0,
    "retail_price": 0,
    "sale_price": 0,
    "map_price": 0,
    "tax_class_id": 0,
    "product_tax_code": "",
    "calculated_price": 28.95,
    "categories": [
      162, 168, 169, 182, 165, 175, 202, 176, 170, 177, 171, 167, 166, 172,
      203, 173, 183, 184
    ],
    "brand_id": 1030,
    "option_set_id": 462582,
    "option_set_display": "right",
    "inventory_level": 326,
    "inventory_warning_level": 0,
    "inventory_tracking": "variant",
    "reviews_rating_sum": 0,
    "reviews_count": 0,
    "total_sold": 0,
    "fixed_cost_shipping_price": 0,
    "is_free_shipping": false,
    "is_visible": true,
    "is_featured": false,
    "related_products": [-1],
    "warranty": "",
    "bin_picking_number": "",
    "layout_file": "",
    "upc": "",
    "mpn": "",
    "gtin": "",
    "search_keywords": "",
    "availability": "available",
    "availability_description": "",
    "gift_wrapping_options_type": "any",
    "gift_wrapping_options_list": [],
    "sort_order": 0,
    "condition": "New",
    "is_condition_shown": false,
    "order_quantity_minimum": 0,
    "order_quantity_maximum": 0,
    "page_title": "BRAVA~2405",
    "meta_keywords": [],
    "meta_description": "",
    "date_created": "2020-10-16T13:25:04+00:00",
    "date_modified": "2021-12-22T11:25:14+00:00",
    "view_count": 0,
    "preorder_release_date": null,
    "preorder_message": "",
    "is_preorder_only": false,
    "is_price_hidden": false,
    "price_hidden_label": "",
    "custom_url": {
      "url": "/brava-2405/",
      "is_customized": false
    },
    "base_variant_id": null,
    "open_graph_type": "product",
    "open_graph_title": "",
    "open_graph_description": "",
    "open_graph_use_meta_description": true,
    "open_graph_use_product_name": true,
    "open_graph_use_image": true,
    "variants": [
      {
        "id": 2290277,
        "product_id": 462683,
        "sku": "1673001_2405",
        "sku_id": 1827760,
        "price": 11.95,
        "calculated_price": 11.95,
        "sale_price": 11.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 21,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828960,
            "label": "6 X Bottle 341 ml",
            "option_id": 462678,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290286,
        "product_id": 462683,
        "sku": "1673002_2405",
        "sku_id": 1827767,
        "price": 22.95,
        "calculated_price": 22.95,
        "sale_price": 22.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828969,
            "label": "12 X Bottle 341 ml",
            "option_id": 462678,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290300,
        "product_id": 462683,
        "sku": "1673004_2405",
        "sku_id": 1827779,
        "price": 2,
        "calculated_price": 2,
        "sale_price": 2,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 273,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828981,
            "label": "1 X Can 473 ml",
            "option_id": 462678,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290312,
        "product_id": 462683,
        "sku": "1673005_2405",
        "sku_id": 1827787,
        "price": 39.5,
        "calculated_price": 39.5,
        "sale_price": 39.5,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 27,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828990,
            "label": "24 X Bottle 341 ml",
            "option_id": 462678,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290330,
        "product_id": 462683,
        "sku": "1673038_2405",
        "sku_id": 1827802,
        "price": 12.95,
        "calculated_price": 12.95,
        "sale_price": 12.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1829005,
            "label": "6 X Can 473 ml",
            "option_id": 462678,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290340,
        "product_id": 462683,
        "sku": "1673041_2405",
        "sku_id": 1827812,
        "price": 47.95,
        "calculated_price": 47.95,
        "sale_price": 47.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1829013,
            "label": "24 X Can 473 ml",
            "option_id": 462678,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290350,
        "product_id": 462683,
        "sku": "1673042_2405",
        "sku_id": 1827820,
        "price": 23.95,
        "calculated_price": 23.95,
        "sale_price": 23.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1829022,
            "label": "12 X Can 473 ml",
            "option_id": 462678,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290368,
        "product_id": 462683,
        "sku": "1673097_2405",
        "sku_id": 1827834,
        "price": 7.95,
        "calculated_price": 7.95,
        "sale_price": 7.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1829034,
            "label": "4 X Can 473 ml",
            "option_id": 462678,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290377,
        "product_id": 462683,
        "sku": "1673100_2405",
        "sku_id": 1827843,
        "price": 42.5,
        "calculated_price": 42.5,
        "sale_price": 42.5,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1829045,
            "label": "28 X Bottle 341 ml",
            "option_id": 462678,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290388,
        "product_id": 462683,
        "sku": "1673156_2405",
        "sku_id": 1827853,
        "price": 16,
        "calculated_price": 16,
        "sale_price": 16,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1829054,
            "label": "8 X Can 473 ml",
            "option_id": 462678,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290396,
        "product_id": 462683,
        "sku": "1673172_2405",
        "sku_id": 1827860,
        "price": 51.95,
        "calculated_price": 51.95,
        "sale_price": 51.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1829061,
            "label": "30 X Can 355 ml",
            "option_id": 462678,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290412,
        "product_id": 462683,
        "sku": "1673173_2405",
        "sku_id": 1827871,
        "price": 28.95,
        "calculated_price": 28.95,
        "sale_price": 28.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 5,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1829072,
            "label": "15 X Can 355 ml",
            "option_id": 462678,
            "option_display_name": "Beer"
          }
        ]
      }
    ],
    "images": [],
    "primary_image": null,
    "custom_fields": [
      {
        "id": 6657478,
        "name": "ABV",
        "value": "4.90"
      },
      {
        "id": 6657481,
        "name": "Country",
        "value": "CA"
      },
      {
        "id": 6657483,
        "name": "Category",
        "value": "Value"
      },
      {
        "id": 6657485,
        "name": "Type",
        "value": "Lager"
      },
      {
        "id": 6657487,
        "name": "Beverage_type",
        "value": "Beer"
      },
      {
        "id": 6657489,
        "name": "Producer",
        "value": "BRAVA BREWING COMPANY"
      },
      {
        "id": 6657491,
        "name": "Beer_styles",
        "value": "Pale"
      },
      {
        "id": 6657493,
        "name": "Price_Metadata_1673001",
        "value": "{\"current_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.31}],\"total_price\":11.95},\"previous_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.25}],\"total_price\":11.50},\"on_sale\":\"N\"}"
      },
      {
        "id": 6657496,
        "name": "Price_Metadata_1673002",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.50}],\"total_price\":22.95},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.45}],\"total_price\":22.50},\"on_sale\":\"N\"}"
      },
      {
        "id": 6657498,
        "name": "Price_Metadata_1673004",
        "value": "{\"current_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.22}],\"total_price\":2.00},\"previous_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.24}],\"total_price\":2.15},\"on_sale\":\"N\"}"
      },
      {
        "id": 6657501,
        "name": "Price_Metadata_1673005",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":4.27}],\"total_price\":39.50},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":4.20}],\"total_price\":38.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6657503,
        "name": "Price_Metadata_1673038",
        "value": "{\"current_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.42}],\"total_price\":12.95},\"previous_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.31}],\"total_price\":11.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6657505,
        "name": "Price_Metadata_1673041",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.24}],\"total_price\":47.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.36}],\"total_price\":48.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6657506,
        "name": "Price_Metadata_1673042",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.62}],\"total_price\":23.95},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.73}],\"total_price\":24.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6657507,
        "name": "Price_Metadata_1673097",
        "value": "{\"current_price\":{\"deposit\":0.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.87}],\"total_price\":7.95},\"previous_price\":{\"deposit\":0.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.94}],\"total_price\":8.55},\"on_sale\":\"N\"}"
      },
      {
        "id": 6657509,
        "name": "Price_Metadata_1673100",
        "value": "{\"current_price\":{\"deposit\":2.80,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":4.57}],\"total_price\":42.50},\"previous_price\":{\"deposit\":2.80,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":4.50}],\"total_price\":41.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6657511,
        "name": "Price_Metadata_1673156",
        "value": "{\"current_price\":{\"deposit\":0.80,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.75}],\"total_price\":16.00},\"previous_price\":{\"deposit\":0.80,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.85}],\"total_price\":16.85},\"on_sale\":\"N\"}"
      },
      {
        "id": 6657513,
        "name": "Price_Metadata_1673172",
        "value": "{\"current_price\":{\"deposit\":3.00,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.63}],\"total_price\":51.95},\"previous_price\":{\"deposit\":3.00,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.40}],\"total_price\":49.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6657515,
        "name": "Price_Metadata_1673173",
        "value": "{\"current_price\":{\"deposit\":1.50,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.16}],\"total_price\":28.95},\"previous_price\":{\"deposit\":1.50,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.13}],\"total_price\":28.75},\"on_sale\":\"N\"}"
      },
      {
        "id": 6657517,
        "name": "product_image_1",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbe4rt-8va3t4-2nwcw9/7888_0.png?position=1"
      },
      {
        "id": 6657519,
        "name": "product_image_2",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbe4rt-8va3t4-2nwcw9/7888_0.png?position=2"
      },
      {
        "id": 6657521,
        "name": "container_type ",
        "value": "B,C"
      },
      {
        "id": 6657523,
        "name": "pack",
        "value": "6,12,1,24,4,28,8,30,15"
      }
    ]
  },
  {
    "id": 793963,
    "name": "brugse-zot-blond",
    "type": "physical",
    "sku": "1194_2405",
    "description": "Traditional top-fermented Belgian beer with additional fermentation in the bottle. Halve Maan brewery located in the heart of Bruges from 1856. A legend calls locals Brugse Zot, fools of Bruges. The brewery built the world first underground beer pipeline. ",
    "weight": 1,
    "width": 0,
    "depth": 0,
    "height": 0,
    "price": 31.8,
    "cost_price": 0,
    "retail_price": 0,
    "sale_price": 0,
    "map_price": 0,
    "tax_class_id": 0,
    "product_tax_code": "",
    "calculated_price": 31.8,
    "categories": [241, 232, 178, 217, 186, 175, 177, 252, 188],
    "brand_id": 1030,
    "option_set_id": 793860,
    "option_set_display": "right",
    "inventory_level": 5,
    "inventory_warning_level": 0,
    "inventory_tracking": "variant",
    "reviews_rating_sum": 0,
    "reviews_count": 0,
    "total_sold": 0,
    "fixed_cost_shipping_price": 0,
    "is_free_shipping": false,
    "is_visible": true,
    "is_featured": false,
    "related_products": [-1],
    "warranty": "",
    "bin_picking_number": "",
    "layout_file": "",
    "upc": "",
    "mpn": "",
    "gtin": "",
    "search_keywords": "",
    "availability": "available",
    "availability_description": "",
    "gift_wrapping_options_type": "any",
    "gift_wrapping_options_list": [],
    "sort_order": 0,
    "condition": "New",
    "is_condition_shown": false,
    "order_quantity_minimum": 0,
    "order_quantity_maximum": 0,
    "page_title": "BRUGSE ZOT BLOND~2405",
    "meta_keywords": [],
    "meta_description": "",
    "date_created": "2020-11-28T09:37:15+00:00",
    "date_modified": "2021-12-09T11:25:27+00:00",
    "view_count": 0,
    "preorder_release_date": null,
    "preorder_message": "",
    "is_preorder_only": false,
    "is_price_hidden": false,
    "price_hidden_label": "",
    "custom_url": {
      "url": "/brugse-zot-blond-2405/",
      "is_customized": false
    },
    "base_variant_id": null,
    "open_graph_type": "product",
    "open_graph_title": "",
    "open_graph_description": "",
    "open_graph_use_meta_description": true,
    "open_graph_use_product_name": true,
    "open_graph_use_image": true,
    "variants": [
      {
        "id": 3918953,
        "product_id": 793963,
        "sku": "2709013_2405",
        "sku_id": 3125157,
        "price": 95.1,
        "calculated_price": 95.1,
        "sale_price": 95.1,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 3126367,
            "label": "24 X Bottle 330 ml",
            "option_id": 793957,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 3918961,
        "product_id": 793963,
        "sku": "2709080_2405",
        "sku_id": 3125164,
        "price": 16,
        "calculated_price": 16,
        "sale_price": 16,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 5,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 3126374,
            "label": "4 X Bottle 330 ml",
            "option_id": 793957,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 3918969,
        "product_id": 793963,
        "sku": "2709619_2405",
        "sku_id": 3125171,
        "price": 31.8,
        "calculated_price": 31.8,
        "sale_price": 31.8,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 3126381,
            "label": "8 X Bottle 330 ml",
            "option_id": 793957,
            "option_display_name": "Beer"
          }
        ]
      }
    ],
    "images": [],
    "primary_image": null,
    "custom_fields": [
      {
        "id": 11509602,
        "name": "ABV",
        "value": "6.00"
      },
      {
        "id": 11509606,
        "name": "Country",
        "value": "BE"
      },
      {
        "id": 11509608,
        "name": "Category",
        "value": "Import"
      },
      {
        "id": 11509610,
        "name": "Type",
        "value": "Ale"
      },
      {
        "id": 11509612,
        "name": "Beverage_type",
        "value": "Beer"
      },
      {
        "id": 11509614,
        "name": "Producer",
        "value": "BROUWERIJ DE HALVE MAAN"
      },
      {
        "id": 11509617,
        "name": "Beer_styles",
        "value": "Blonde,Strong"
      },
      {
        "id": 11509620,
        "name": "Price_Metadata_2709013",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":10.66}],\"total_price\":95.10},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":11.31}],\"total_price\":100.68},\"on_sale\":\"N\"}"
      },
      {
        "id": 11509622,
        "name": "Price_Metadata_2709080",
        "value": "{\"current_price\":{\"deposit\":0.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.79}],\"total_price\":16.00},\"previous_price\":{\"deposit\":0.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.79}],\"total_price\":15.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 11509624,
        "name": "Price_Metadata_2709619",
        "value": "{\"current_price\":{\"deposit\":0.80,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.57}],\"total_price\":31.80},\"previous_price\":{\"deposit\":0.80,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.79}],\"total_price\":33.73},\"on_sale\":\"N\"}"
      },
      {
        "id": 11509627,
        "name": "container_type ",
        "value": "B"
      },
      {
        "id": 11509628,
        "name": "pack",
        "value": "24,4,8"
      },
      {
        "id": 11553802,
        "name": "product_image_1",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/6gc6pxq4wft756jcm67qkv/1194.png?position=1"
      },
      {
        "id": 11553803,
        "name": "product_image_2",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/6gc6pxq4wft756jcm67qkv/1194.png?position=2"
      }
    ]
  },
  {
    "id": 462668,
    "name": "bud-light-apple",
    "type": "physical",
    "sku": "7825_2405",
    "description": "Bud Light Apple, a refreshing light beer with a crisp apple taste. Refreshing and easy to drink.",
    "weight": 1,
    "width": 0,
    "depth": 0,
    "height": 0,
    "price": 33.95,
    "cost_price": 0,
    "retail_price": 0,
    "sale_price": 0,
    "map_price": 0,
    "tax_class_id": 0,
    "product_tax_code": "",
    "calculated_price": 33.95,
    "categories": [162, 163, 189, 165, 175, 170, 177, 166, 167, 171],
    "brand_id": 1030,
    "option_set_id": 462567,
    "option_set_display": "right",
    "inventory_level": 76,
    "inventory_warning_level": 0,
    "inventory_tracking": "variant",
    "reviews_rating_sum": 0,
    "reviews_count": 0,
    "total_sold": 0,
    "fixed_cost_shipping_price": 0,
    "is_free_shipping": false,
    "is_visible": true,
    "is_featured": false,
    "related_products": [-1],
    "warranty": "",
    "bin_picking_number": "",
    "layout_file": "",
    "upc": "",
    "mpn": "",
    "gtin": "",
    "search_keywords": "",
    "availability": "available",
    "availability_description": "",
    "gift_wrapping_options_type": "any",
    "gift_wrapping_options_list": [],
    "sort_order": 0,
    "condition": "New",
    "is_condition_shown": false,
    "order_quantity_minimum": 0,
    "order_quantity_maximum": 0,
    "page_title": "BUD LIGHT APPLE~2405",
    "meta_keywords": [],
    "meta_description": "",
    "date_created": "2020-10-16T13:25:04+00:00",
    "date_modified": "2021-12-20T11:31:48+00:00",
    "view_count": 0,
    "preorder_release_date": null,
    "preorder_message": "",
    "is_preorder_only": false,
    "is_price_hidden": false,
    "price_hidden_label": "",
    "custom_url": {
      "url": "/bud-light-apple-2405/",
      "is_customized": false
    },
    "base_variant_id": null,
    "open_graph_type": "product",
    "open_graph_title": "",
    "open_graph_description": "",
    "open_graph_use_meta_description": true,
    "open_graph_use_product_name": true,
    "open_graph_use_image": true,
    "variants": [
      {
        "id": 2290193,
        "product_id": 462668,
        "sku": "1655004_2405",
        "sku_id": 1827691,
        "price": 3,
        "calculated_price": 3,
        "sale_price": 3,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 76,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828893,
            "label": "1 X Can 473 ml",
            "option_id": 462663,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290200,
        "product_id": 462668,
        "sku": "1655005_2405",
        "sku_id": 1827696,
        "price": 47.95,
        "calculated_price": 47.95,
        "sale_price": 47.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828902,
            "label": "24 X Bottle 341 ml",
            "option_id": 462663,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290210,
        "product_id": 462668,
        "sku": "1655031_2405",
        "sku_id": 1827706,
        "price": 28.5,
        "calculated_price": 28.5,
        "sale_price": 28.5,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828908,
            "label": "12 X Can 355 ml",
            "option_id": 462663,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290222,
        "product_id": 462668,
        "sku": "1655033_2405",
        "sku_id": 1827715,
        "price": 50.95,
        "calculated_price": 50.95,
        "sale_price": 50.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828917,
            "label": "24 X Can 355 ml",
            "option_id": 462663,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290233,
        "product_id": 462668,
        "sku": "1655038_2405",
        "sku_id": 1827724,
        "price": 17.5,
        "calculated_price": 17.5,
        "sale_price": 17.5,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828926,
            "label": "6 X Can 473 ml",
            "option_id": 462663,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290249,
        "product_id": 462668,
        "sku": "1655041_2405",
        "sku_id": 1827735,
        "price": 63.95,
        "calculated_price": 63.95,
        "sale_price": 63.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828937,
            "label": "24 X Can 473 ml",
            "option_id": 462663,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290264,
        "product_id": 462668,
        "sku": "1655042_2405",
        "sku_id": 1827748,
        "price": 33.95,
        "calculated_price": 33.95,
        "sale_price": 33.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828951,
            "label": "12 X Can 473 ml",
            "option_id": 462663,
            "option_display_name": "Beer"
          }
        ]
      }
    ],
    "images": [],
    "primary_image": null,
    "custom_fields": [
      {
        "id": 6657240,
        "name": "ABV",
        "value": "4.00"
      },
      {
        "id": 6657246,
        "name": "Country",
        "value": "CA"
      },
      {
        "id": 6657250,
        "name": "Category",
        "value": "Domestic Specialty"
      },
      {
        "id": 6657255,
        "name": "Type",
        "value": "Light"
      },
      {
        "id": 6657258,
        "name": "Beverage_type",
        "value": "Beer"
      },
      {
        "id": 6657262,
        "name": "Producer",
        "value": "LABATT"
      },
      {
        "id": 6657265,
        "name": "Price_Metadata_1655004",
        "value": "{\"current_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.33}],\"total_price\":3.00},\"previous_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.33}],\"total_price\":3.00},\"on_sale\":\"N\"}"
      },
      {
        "id": 6657268,
        "name": "Price_Metadata_1655005",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.24}],\"total_price\":47.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.01}],\"total_price\":45.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6657271,
        "name": "Price_Metadata_1655031",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.14}],\"total_price\":28.50},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.08}],\"total_price\":27.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6657274,
        "name": "Price_Metadata_1655033",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.59}],\"total_price\":50.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.47}],\"total_price\":49.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6657278,
        "name": "Price_Metadata_1655038",
        "value": "{\"current_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.94}],\"total_price\":17.50},\"previous_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.88}],\"total_price\":16.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6657280,
        "name": "Price_Metadata_1655041",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":7.08}],\"total_price\":63.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":6.89}],\"total_price\":62.25},\"on_sale\":\"N\"}"
      },
      {
        "id": 6657282,
        "name": "Price_Metadata_1655042",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.77}],\"total_price\":33.95},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.51}],\"total_price\":31.75},\"on_sale\":\"N\"}"
      },
      {
        "id": 6657284,
        "name": "product_image_1",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbg6wp-dxk75c-cul3e8/7825.png?position=1"
      },
      {
        "id": 6657286,
        "name": "product_image_2",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbg6wp-dxk75c-cul3e8/7825.png?position=2"
      },
      {
        "id": 6657287,
        "name": "container_type ",
        "value": "C,B"
      },
      {
        "id": 6657288,
        "name": "pack",
        "value": "1,24,12,6"
      }
    ]
  },
  {
    "id": 462681,
    "name": "bud-light-flavour-faves-summer-mixer",
    "type": "physical",
    "sku": "7896_2405",
    "description": "Bud Light Flavour mix pack is the perfect pick up for all your Summer destinations. With 4 Bud Light Lime, 4 Bud Light Apple, 4 Bud Light Strawberry Lemonade cans, it's made for Summer! ",
    "weight": 1,
    "width": 0,
    "depth": 0,
    "height": 0,
    "price": 50.95,
    "cost_price": 0,
    "retail_price": 0,
    "sale_price": 0,
    "map_price": 0,
    "tax_class_id": 0,
    "product_tax_code": "",
    "calculated_price": 50.95,
    "categories": [162, 185, 164, 179, 165, 166, 167],
    "brand_id": 1030,
    "option_set_id": 462578,
    "option_set_display": "right",
    "inventory_level": 10,
    "inventory_warning_level": 0,
    "inventory_tracking": "variant",
    "reviews_rating_sum": 0,
    "reviews_count": 0,
    "total_sold": 0,
    "fixed_cost_shipping_price": 0,
    "is_free_shipping": false,
    "is_visible": true,
    "is_featured": false,
    "related_products": [-1],
    "warranty": "",
    "bin_picking_number": "",
    "layout_file": "",
    "upc": "",
    "mpn": "",
    "gtin": "",
    "search_keywords": "",
    "availability": "available",
    "availability_description": "",
    "gift_wrapping_options_type": "any",
    "gift_wrapping_options_list": [],
    "sort_order": 0,
    "condition": "New",
    "is_condition_shown": false,
    "order_quantity_minimum": 0,
    "order_quantity_maximum": 0,
    "page_title": "BUD LIGHT FLAVOUR FAVES SUMMER MIXER~2405",
    "meta_keywords": [],
    "meta_description": "",
    "date_created": "2020-10-16T13:25:04+00:00",
    "date_modified": "2021-12-06T11:27:22+00:00",
    "view_count": 0,
    "preorder_release_date": null,
    "preorder_message": "",
    "is_preorder_only": false,
    "is_price_hidden": false,
    "price_hidden_label": "",
    "custom_url": {
      "url": "/bud-light-summer-mixer-2405/",
      "is_customized": false
    },
    "base_variant_id": null,
    "open_graph_type": "product",
    "open_graph_title": "",
    "open_graph_description": "",
    "open_graph_use_meta_description": true,
    "open_graph_use_product_name": true,
    "open_graph_use_image": true,
    "variants": [
      {
        "id": 2290254,
        "product_id": 462681,
        "sku": "1676031_2405",
        "sku_id": 1827740,
        "price": 29.95,
        "calculated_price": 29.95,
        "sale_price": 29.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 10,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828938,
            "label": "12 X Can 355 ml",
            "option_id": 462674,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2290270,
        "product_id": 462681,
        "sku": "1676033_2405",
        "sku_id": 1827753,
        "price": 50.95,
        "calculated_price": 49.95,
        "sale_price": 49.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1828955,
            "label": "24 X Can 355 ml",
            "option_id": 462674,
            "option_display_name": "Beer"
          }
        ]
      }
    ],
    "images": [],
    "primary_image": null,
    "custom_fields": [
      {
        "id": 6657426,
        "name": "ABV",
        "value": "3.60"
      },
      {
        "id": 6657432,
        "name": "Country",
        "value": "CA"
      },
      {
        "id": 6657440,
        "name": "Category",
        "value": "Premium"
      },
      {
        "id": 6657445,
        "name": "Type",
        "value": "Mixed Case"
      },
      {
        "id": 6657458,
        "name": "Beverage_type",
        "value": "Beer"
      },
      {
        "id": 6657462,
        "name": "Producer",
        "value": "LABATT"
      },
      {
        "id": 6657464,
        "name": "Price_Metadata_1676031",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.31}],\"total_price\":29.95},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.96}],\"total_price\":26.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6657466,
        "name": "Price_Metadata_1676033",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.47}],\"total_price\":49.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.59}],\"total_price\":50.95},\"on_sale\":\"Y\"}"
      },
      {
        "id": 6657467,
        "name": "product_image_1",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbg6zh-5y4ydk-6ymigb/7896.png?position=1"
      },
      {
        "id": 6657469,
        "name": "product_image_2",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbg6zh-5y4ydk-6ymigb/7896.png?position=2"
      },
      {
        "id": 6657471,
        "name": "container_type ",
        "value": "C"
      },
      {
        "id": 6657473,
        "name": "pack",
        "value": "12,24"
      }
    ]
  },
  {
    "id": 461975,
    "name": "bud-light-lime",
    "type": "physical",
    "sku": "529_2405",
    "description": "American Style light flavoured lager. Delicate malt sweetness with a crisp, clean finish and the taste of fresh squeezed lime for ultimate refreshment.",
    "weight": 1,
    "width": 0,
    "depth": 0,
    "height": 0,
    "price": 33.95,
    "cost_price": 0,
    "retail_price": 0,
    "sale_price": 0,
    "map_price": 0,
    "tax_class_id": 0,
    "product_tax_code": "",
    "calculated_price": 33.95,
    "categories": [
      162, 163, 190, 195, 192, 206, 165, 175, 176, 170, 177, 166, 171, 167
    ],
    "brand_id": 1030,
    "option_set_id": 461873,
    "option_set_display": "right",
    "inventory_level": 320,
    "inventory_warning_level": 0,
    "inventory_tracking": "variant",
    "reviews_rating_sum": 0,
    "reviews_count": 0,
    "total_sold": 12,
    "fixed_cost_shipping_price": 0,
    "is_free_shipping": false,
    "is_visible": true,
    "is_featured": false,
    "related_products": [-1],
    "warranty": "",
    "bin_picking_number": "",
    "layout_file": "",
    "upc": "",
    "mpn": "",
    "gtin": "",
    "search_keywords": "",
    "availability": "available",
    "availability_description": "",
    "gift_wrapping_options_type": "any",
    "gift_wrapping_options_list": [],
    "sort_order": 0,
    "condition": "New",
    "is_condition_shown": false,
    "order_quantity_minimum": 0,
    "order_quantity_maximum": 0,
    "page_title": "BUD LIGHT LIME~2405",
    "meta_keywords": [],
    "meta_description": "",
    "date_created": "2020-10-16T13:24:42+00:00",
    "date_modified": "2021-12-22T11:25:14+00:00",
    "view_count": 0,
    "preorder_release_date": null,
    "preorder_message": "",
    "is_preorder_only": false,
    "is_price_hidden": false,
    "price_hidden_label": "",
    "custom_url": {
      "url": "/bud-light-lime-2405/",
      "is_customized": false
    },
    "base_variant_id": null,
    "open_graph_type": "product",
    "open_graph_title": "",
    "open_graph_description": "",
    "open_graph_use_meta_description": true,
    "open_graph_use_product_name": true,
    "open_graph_use_image": true,
    "variants": [
      {
        "id": 2286699,
        "product_id": 461975,
        "sku": "1029001_2405",
        "sku_id": 1824890,
        "price": 14.75,
        "calculated_price": 14.75,
        "sale_price": 14.75,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": true,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826091,
            "label": "6 X Bottle 341 ml",
            "option_id": 461969,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286712,
        "product_id": 461975,
        "sku": "1029002_2405",
        "sku_id": 1824900,
        "price": 27.75,
        "calculated_price": 27.75,
        "sale_price": 27.75,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826101,
            "label": "12 X Bottle 341 ml",
            "option_id": 461969,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286723,
        "product_id": 461975,
        "sku": "1029004_2405",
        "sku_id": 1824911,
        "price": 3,
        "calculated_price": 3,
        "sale_price": 3,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 320,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826113,
            "label": "1 X Can 473 ml",
            "option_id": 461969,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286735,
        "product_id": 461975,
        "sku": "1029005_2405",
        "sku_id": 1824921,
        "price": 47.95,
        "calculated_price": 47.95,
        "sale_price": 47.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826123,
            "label": "24 X Bottle 341 ml",
            "option_id": 461969,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286751,
        "product_id": 461975,
        "sku": "1029031_2405",
        "sku_id": 1824933,
        "price": 28.5,
        "calculated_price": 28.5,
        "sale_price": 28.5,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826134,
            "label": "12 X Can 355 ml",
            "option_id": 461969,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286766,
        "product_id": 461975,
        "sku": "1029033_2405",
        "sku_id": 1824947,
        "price": 50.95,
        "calculated_price": 50.95,
        "sale_price": 50.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": true,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826149,
            "label": "24 X Can 355 ml",
            "option_id": 461969,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286785,
        "product_id": 461975,
        "sku": "1029038_2405",
        "sku_id": 1824963,
        "price": 17.5,
        "calculated_price": 17.5,
        "sale_price": 17.5,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826164,
            "label": "6 X Can 473 ml",
            "option_id": 461969,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286799,
        "product_id": 461975,
        "sku": "1029041_2405",
        "sku_id": 1824977,
        "price": 63.95,
        "calculated_price": 63.95,
        "sale_price": 63.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826179,
            "label": "24 X Can 473 ml",
            "option_id": 461969,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286815,
        "product_id": 461975,
        "sku": "1029042_2405",
        "sku_id": 1824991,
        "price": 33.95,
        "calculated_price": 33.95,
        "sale_price": 33.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826193,
            "label": "12 X Can 473 ml",
            "option_id": 461969,
            "option_display_name": "Beer"
          }
        ]
      }
    ],
    "images": [],
    "primary_image": null,
    "custom_fields": [
      {
        "id": 6647043,
        "name": "ABV",
        "value": "4.00"
      },
      {
        "id": 6647044,
        "name": "Country",
        "value": "CA"
      },
      {
        "id": 6647045,
        "name": "Category",
        "value": "Domestic Specialty"
      },
      {
        "id": 6647046,
        "name": "Type",
        "value": "Flavoured Malt"
      },
      {
        "id": 6647048,
        "name": "Beverage_type",
        "value": "Beer"
      },
      {
        "id": 6647049,
        "name": "Producer",
        "value": "LABATT"
      },
      {
        "id": 6647051,
        "name": "Beer_attributes",
        "value": "Light"
      },
      {
        "id": 6647053,
        "name": "Beer_styles",
        "value": "Pilsner,Lime"
      },
      {
        "id": 6647056,
        "name": "Price_Metadata_1029001",
        "value": "{\"current_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.63}],\"total_price\":14.75},\"previous_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.63}],\"total_price\":14.75},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647058,
        "name": "Price_Metadata_1029002",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.05}],\"total_price\":27.75},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.05}],\"total_price\":27.75},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647061,
        "name": "Price_Metadata_1029004",
        "value": "{\"current_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.33}],\"total_price\":3.00},\"previous_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.31}],\"total_price\":2.80},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647065,
        "name": "Price_Metadata_1029005",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.24}],\"total_price\":47.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.13}],\"total_price\":46.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647069,
        "name": "Price_Metadata_1029031",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.14}],\"total_price\":28.50},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.08}],\"total_price\":27.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647073,
        "name": "Price_Metadata_1029033",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.59}],\"total_price\":50.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.47}],\"total_price\":49.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647077,
        "name": "Price_Metadata_1029038",
        "value": "{\"current_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.94}],\"total_price\":17.50},\"previous_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.88}],\"total_price\":16.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647081,
        "name": "Price_Metadata_1029041",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":7.08}],\"total_price\":63.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":6.89}],\"total_price\":62.25},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647086,
        "name": "Price_Metadata_1029042",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.77}],\"total_price\":33.95},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.51}],\"total_price\":31.75},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647090,
        "name": "product_image_1",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbg6yh-ey9mwo-g8wntf/0529_0.png?position=1"
      },
      {
        "id": 6647094,
        "name": "product_image_2",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbg6yh-ey9mwo-g8wntf/0529_0.png?position=2"
      },
      {
        "id": 6647098,
        "name": "container_type ",
        "value": "B,C"
      },
      {
        "id": 6647103,
        "name": "pack",
        "value": "6,12,1,24"
      }
    ]
  },
  {
    "id": 825207,
    "name": "bud-light-mango",
    "type": "physical",
    "sku": "1267_2405",
    "description": "Bud Light Mango features the renowned crisp and refreshing fast finish of Bud Light. Pours a brilliant sunny golden colour, with the mildly sweet aromas of fully ripened Alphonso mangos. ",
    "weight": 1,
    "width": 0,
    "depth": 0,
    "height": 0,
    "price": 33.95,
    "cost_price": 0,
    "retail_price": 0,
    "sale_price": 0,
    "map_price": 0,
    "tax_class_id": 0,
    "product_tax_code": "",
    "calculated_price": 33.95,
    "categories": [162, 163, 169, 165, 170, 166, 167, 171],
    "brand_id": 1030,
    "option_set_id": 825105,
    "option_set_display": "right",
    "inventory_level": 76,
    "inventory_warning_level": 0,
    "inventory_tracking": "variant",
    "reviews_rating_sum": 0,
    "reviews_count": 0,
    "total_sold": 2,
    "fixed_cost_shipping_price": 0,
    "is_free_shipping": false,
    "is_visible": true,
    "is_featured": false,
    "related_products": [-1],
    "warranty": "",
    "bin_picking_number": "",
    "layout_file": "",
    "upc": "",
    "mpn": "",
    "gtin": "",
    "search_keywords": "",
    "availability": "available",
    "availability_description": "",
    "gift_wrapping_options_type": "any",
    "gift_wrapping_options_list": [],
    "sort_order": 0,
    "condition": "New",
    "is_condition_shown": false,
    "order_quantity_minimum": 0,
    "order_quantity_maximum": 0,
    "page_title": "BUD LIGHT MANGO~2405",
    "meta_keywords": [],
    "meta_description": "",
    "date_created": "2021-03-20T08:38:27+00:00",
    "date_modified": "2021-12-22T01:23:57+00:00",
    "view_count": 0,
    "preorder_release_date": null,
    "preorder_message": "",
    "is_preorder_only": false,
    "is_price_hidden": false,
    "price_hidden_label": "",
    "custom_url": {
      "url": "/bud-light-mango-2405/",
      "is_customized": false
    },
    "base_variant_id": null,
    "open_graph_type": "product",
    "open_graph_title": "",
    "open_graph_description": "",
    "open_graph_use_meta_description": true,
    "open_graph_use_product_name": true,
    "open_graph_use_image": true,
    "variants": [
      {
        "id": 4034682,
        "product_id": 825207,
        "sku": "2782004_2405",
        "sku_id": 3209643,
        "price": 3,
        "calculated_price": 3,
        "sale_price": 3,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 76,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 3210853,
            "label": "1 X Can 473 ml",
            "option_id": 825201,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 4034683,
        "product_id": 825207,
        "sku": "2782038_2405",
        "sku_id": 3209644,
        "price": 16.95,
        "calculated_price": 16.95,
        "sale_price": 16.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 3210854,
            "label": "6 X Can 473 ml",
            "option_id": 825201,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 4034684,
        "product_id": 825207,
        "sku": "2782041_2405",
        "sku_id": 3209645,
        "price": 61.25,
        "calculated_price": 61.25,
        "sale_price": 61.25,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 3210855,
            "label": "24 X Can 473 ml",
            "option_id": 825201,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 4034685,
        "product_id": 825207,
        "sku": "2782042_2405",
        "sku_id": 3209646,
        "price": 33.95,
        "calculated_price": 33.95,
        "sale_price": 33.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 3210856,
            "label": "12 X Can 473 ml",
            "option_id": 825201,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 4070436,
        "product_id": 825207,
        "sku": "2782033_2405",
        "sku_id": 3238319,
        "price": 49.5,
        "calculated_price": 49.5,
        "sale_price": 49.5,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 3239529,
            "label": "24 X Can 355 ml",
            "option_id": 825201,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 4070453,
        "product_id": 825207,
        "sku": "2782031_2405",
        "sku_id": 3238336,
        "price": 28.5,
        "calculated_price": 28.5,
        "sale_price": 28.5,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 3239541,
            "label": "12 X Can 355 ml",
            "option_id": 825201,
            "option_display_name": "Beer"
          }
        ]
      }
    ],
    "images": [],
    "primary_image": null,
    "custom_fields": [
      {
        "id": 11950384,
        "name": "ABV",
        "value": "4.00"
      },
      {
        "id": 11950385,
        "name": "Country",
        "value": "CA"
      },
      {
        "id": 11950386,
        "name": "Category",
        "value": "Domestic Specialty"
      },
      {
        "id": 11950387,
        "name": "Type",
        "value": "Lager"
      },
      {
        "id": 11950388,
        "name": "Beverage_type",
        "value": "Beer"
      },
      {
        "id": 11950389,
        "name": "Producer",
        "value": "LABATT"
      },
      {
        "id": 11950390,
        "name": "Price_Metadata_2782004",
        "value": "{\"current_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.33}],\"total_price\":3.00},\"previous_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.31}],\"total_price\":2.80},\"on_sale\":\"N\"}"
      },
      {
        "id": 11950391,
        "name": "Price_Metadata_2782038",
        "value": "{\"current_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.88}],\"total_price\":16.95},\"previous_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.00}],\"total_price\":0.00},\"on_sale\":\"N\"}"
      },
      {
        "id": 11950392,
        "name": "Price_Metadata_2782041",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":6.77}],\"total_price\":61.25},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.00}],\"total_price\":0.00},\"on_sale\":\"N\"}"
      },
      {
        "id": 11950393,
        "name": "Price_Metadata_2782042",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.77}],\"total_price\":33.95},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.51}],\"total_price\":31.75},\"on_sale\":\"N\"}"
      },
      {
        "id": 11950394,
        "name": "container_type ",
        "value": "C"
      },
      {
        "id": 11950395,
        "name": "pack",
        "value": "1,6,24,12"
      },
      {
        "id": 12110258,
        "name": "Price_Metadata_2782031",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.14}],\"total_price\":28.50},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.85}],\"total_price\":25.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 12110259,
        "name": "Price_Metadata_2782033",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.42}],\"total_price\":49.50},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.00}],\"total_price\":0.00},\"on_sale\":\"N\"}"
      },
      {
        "id": 12151080,
        "name": "product_image_1",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/nhg7gcnfkwx39n287ssfr2w/1267.png?position=1"
      },
      {
        "id": 12151081,
        "name": "product_image_2",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/nhg7gcnfkwx39n287ssfr2w/1267.png?position=2"
      }
    ]
  },
  {
    "id": 462074,
    "name": "bud-light-strawberry-lemonade",
    "type": "physical",
    "sku": "1041_2405",
    "description": "Combines Bud Light’s smooth, easy-drinking taste with a fresh burst of Strawberry Lemonade.",
    "weight": 1,
    "width": 0,
    "depth": 0,
    "height": 0,
    "price": 63.95,
    "cost_price": 0,
    "retail_price": 0,
    "sale_price": 0,
    "map_price": 0,
    "tax_class_id": 0,
    "product_tax_code": "",
    "calculated_price": 63.95,
    "categories": [162, 163, 189, 165, 170, 166, 167, 171],
    "brand_id": 1030,
    "option_set_id": 461972,
    "option_set_display": "right",
    "inventory_level": 47,
    "inventory_warning_level": 0,
    "inventory_tracking": "variant",
    "reviews_rating_sum": 0,
    "reviews_count": 0,
    "total_sold": 0,
    "fixed_cost_shipping_price": 0,
    "is_free_shipping": false,
    "is_visible": true,
    "is_featured": false,
    "related_products": [-1],
    "warranty": "",
    "bin_picking_number": "",
    "layout_file": "",
    "upc": "",
    "mpn": "",
    "gtin": "",
    "search_keywords": "",
    "availability": "available",
    "availability_description": "",
    "gift_wrapping_options_type": "any",
    "gift_wrapping_options_list": [],
    "sort_order": 0,
    "condition": "New",
    "is_condition_shown": false,
    "order_quantity_minimum": 0,
    "order_quantity_maximum": 0,
    "page_title": "BUD LIGHT STRAWBERRY LEMONADE~2405",
    "meta_keywords": [],
    "meta_description": "",
    "date_created": "2020-10-16T13:24:46+00:00",
    "date_modified": "2021-12-22T11:23:10+00:00",
    "view_count": 0,
    "preorder_release_date": null,
    "preorder_message": "",
    "is_preorder_only": false,
    "is_price_hidden": false,
    "price_hidden_label": "",
    "custom_url": {
      "url": "/bud-light-strawberry-lemonade-2405/",
      "is_customized": false
    },
    "base_variant_id": null,
    "open_graph_type": "product",
    "open_graph_title": "",
    "open_graph_description": "",
    "open_graph_use_meta_description": true,
    "open_graph_use_product_name": true,
    "open_graph_use_image": true,
    "variants": [
      {
        "id": 2287415,
        "product_id": 462074,
        "sku": "2527004_2405",
        "sku_id": 1825509,
        "price": 3,
        "calculated_price": 3,
        "sale_price": 3,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 47,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826710,
            "label": "1 X Can 473 ml",
            "option_id": 462068,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2287423,
        "product_id": 462074,
        "sku": "2527031_2405",
        "sku_id": 1825514,
        "price": 28.5,
        "calculated_price": 28.5,
        "sale_price": 28.5,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826716,
            "label": "12 X Can 355 ml",
            "option_id": 462068,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2287431,
        "product_id": 462074,
        "sku": "2527033_2405",
        "sku_id": 1825521,
        "price": 49.5,
        "calculated_price": 49.5,
        "sale_price": 49.5,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826723,
            "label": "24 X Can 355 ml",
            "option_id": 462068,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2287439,
        "product_id": 462074,
        "sku": "2527038_2405",
        "sku_id": 1825526,
        "price": 16.95,
        "calculated_price": 16.95,
        "sale_price": 16.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826729,
            "label": "6 X Can 473 ml",
            "option_id": 462068,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2287449,
        "product_id": 462074,
        "sku": "2527041_2405",
        "sku_id": 1825536,
        "price": 63.95,
        "calculated_price": 63.95,
        "sale_price": 63.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826739,
            "label": "24 X Can 473 ml",
            "option_id": 462068,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2287462,
        "product_id": 462074,
        "sku": "2527042_2405",
        "sku_id": 1825547,
        "price": 33.95,
        "calculated_price": 33.95,
        "sale_price": 33.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": true,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826749,
            "label": "12 X Can 473 ml",
            "option_id": 462068,
            "option_display_name": "Beer"
          }
        ]
      }
    ],
    "images": [],
    "primary_image": null,
    "custom_fields": [
      {
        "id": 6648705,
        "name": "ABV",
        "value": "4.00"
      },
      {
        "id": 6648712,
        "name": "Country",
        "value": "CA"
      },
      {
        "id": 6648716,
        "name": "Category",
        "value": "Domestic Specialty"
      },
      {
        "id": 6648720,
        "name": "Type",
        "value": "Light"
      },
      {
        "id": 6648724,
        "name": "Beverage_type",
        "value": "Beer"
      },
      {
        "id": 6648728,
        "name": "Producer",
        "value": "LABATT"
      },
      {
        "id": 6648732,
        "name": "Price_Metadata_2527004",
        "value": "{\"current_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.33}],\"total_price\":3.00},\"previous_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.31}],\"total_price\":2.80},\"on_sale\":\"N\"}"
      },
      {
        "id": 6648736,
        "name": "Price_Metadata_2527031",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.14}],\"total_price\":28.50},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.85}],\"total_price\":25.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6648739,
        "name": "Price_Metadata_2527033",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.42}],\"total_price\":49.50},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.42}],\"total_price\":49.50},\"on_sale\":\"N\"}"
      },
      {
        "id": 6648741,
        "name": "Price_Metadata_2527038",
        "value": "{\"current_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.88}],\"total_price\":16.95},\"previous_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.88}],\"total_price\":16.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6648743,
        "name": "Price_Metadata_2527041",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":7.08}],\"total_price\":63.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":6.77}],\"total_price\":61.25},\"on_sale\":\"N\"}"
      },
      {
        "id": 6648745,
        "name": "Price_Metadata_2527042",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.77}],\"total_price\":33.95},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.51}],\"total_price\":31.75},\"on_sale\":\"N\"}"
      },
      {
        "id": 6648747,
        "name": "product_image_1",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qfz8yo-69izy8-7dy4r5/1041.png?position=1"
      },
      {
        "id": 6648749,
        "name": "product_image_2",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qfz8yo-69izy8-7dy4r5/1041.png?position=2"
      },
      {
        "id": 6648751,
        "name": "container_type ",
        "value": "C"
      },
      {
        "id": 6648753,
        "name": "pack",
        "value": "1,12,24,6"
      }
    ]
  },
  {
    "id": 461989,
    "name": "bud-light",
    "type": "physical",
    "sku": "574_2405",
    "description": "Bud Light is brewed longer, for a refreshingly easy drinking taste, using a blend of rice and malted barley to give it a clean aroma and crisp, smooth finish. Only the finest ingredients are used: water, barley malt, rice, hops, and yeast.",
    "weight": 1,
    "width": 0,
    "depth": 0,
    "height": 0,
    "price": 47.5,
    "cost_price": 0,
    "retail_price": 0,
    "sale_price": 0,
    "map_price": 0,
    "tax_class_id": 0,
    "product_tax_code": "",
    "calculated_price": 47.5,
    "categories": [
      162, 185, 189, 195, 192, 182, 180, 165, 175, 202, 176, 211, 170, 177,
      181, 171, 166, 167, 172, 212, 173, 204, 210, 183, 184, 205, 213, 209,
      214, 203
    ],
    "brand_id": 1030,
    "option_set_id": 461889,
    "option_set_display": "right",
    "inventory_level": 683,
    "inventory_warning_level": 0,
    "inventory_tracking": "variant",
    "reviews_rating_sum": 0,
    "reviews_count": 0,
    "total_sold": 7,
    "fixed_cost_shipping_price": 0,
    "is_free_shipping": false,
    "is_visible": true,
    "is_featured": false,
    "related_products": [-1],
    "warranty": "",
    "bin_picking_number": "",
    "layout_file": "",
    "upc": "",
    "mpn": "",
    "gtin": "",
    "search_keywords": "",
    "availability": "available",
    "availability_description": "",
    "gift_wrapping_options_type": "any",
    "gift_wrapping_options_list": [],
    "sort_order": 0,
    "condition": "New",
    "is_condition_shown": false,
    "order_quantity_minimum": 0,
    "order_quantity_maximum": 0,
    "page_title": "BUD LIGHT~2405",
    "meta_keywords": [],
    "meta_description": "",
    "date_created": "2020-10-16T13:24:43+00:00",
    "date_modified": "2021-12-22T11:25:14+00:00",
    "view_count": 0,
    "preorder_release_date": null,
    "preorder_message": "",
    "is_preorder_only": false,
    "is_price_hidden": false,
    "price_hidden_label": "",
    "custom_url": {
      "url": "/bud-light-2405/",
      "is_customized": false
    },
    "base_variant_id": null,
    "open_graph_type": "product",
    "open_graph_title": "",
    "open_graph_description": "",
    "open_graph_use_meta_description": true,
    "open_graph_use_product_name": true,
    "open_graph_use_image": true,
    "variants": [
      {
        "id": 2286811,
        "product_id": 461989,
        "sku": "1041001_2405",
        "sku_id": 1824987,
        "price": 13.5,
        "calculated_price": 13.5,
        "sale_price": 13.5,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826189,
            "label": "6 X Bottle 341 ml",
            "option_id": 461985,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286825,
        "product_id": 461989,
        "sku": "1041002_2405",
        "sku_id": 1825000,
        "price": 24.5,
        "calculated_price": 24.5,
        "sale_price": 24.5,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 15,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826202,
            "label": "12 X Bottle 341 ml",
            "option_id": 461985,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286846,
        "product_id": 461989,
        "sku": "1041003_2405",
        "sku_id": 1825016,
        "price": 33.5,
        "calculated_price": 33.5,
        "sale_price": 33.5,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826216,
            "label": "18 X Bottle 341 ml",
            "option_id": 461985,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286861,
        "product_id": 461989,
        "sku": "1041004_2405",
        "sku_id": 1825028,
        "price": 2.85,
        "calculated_price": 2.85,
        "sale_price": 2.85,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 534,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826230,
            "label": "1 X Can 473 ml",
            "option_id": 461985,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286876,
        "product_id": 461989,
        "sku": "1041005_2405",
        "sku_id": 1825045,
        "price": 42.5,
        "calculated_price": 42.5,
        "sale_price": 42.5,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826247,
            "label": "24 X Bottle 341 ml",
            "option_id": 461985,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286892,
        "product_id": 461989,
        "sku": "1041024_2405",
        "sku_id": 1825060,
        "price": 3.65,
        "calculated_price": 3.65,
        "sale_price": 3.65,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": true,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826262,
            "label": "1 X Can 710 ml",
            "option_id": 461985,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286904,
        "product_id": 461989,
        "sku": "1041027_2405",
        "sku_id": 1825072,
        "price": 357.5,
        "calculated_price": 357.5,
        "sale_price": 357.5,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826274,
            "label": "1 X Keg 58600 ml",
            "option_id": 461985,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286922,
        "product_id": 461989,
        "sku": "1041030_2405",
        "sku_id": 1825087,
        "price": 13.95,
        "calculated_price": 13.95,
        "sale_price": 13.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 37,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826289,
            "label": "6 X Can 355 ml",
            "option_id": 461985,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286942,
        "product_id": 461989,
        "sku": "1041031_2405",
        "sku_id": 1825106,
        "price": 25.5,
        "calculated_price": 25.5,
        "sale_price": 25.5,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826308,
            "label": "12 X Can 355 ml",
            "option_id": 461985,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286965,
        "product_id": 461989,
        "sku": "1041033_2405",
        "sku_id": 1825123,
        "price": 47.5,
        "calculated_price": 47.5,
        "sale_price": 47.5,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826325,
            "label": "24 X Can 355 ml",
            "option_id": 461985,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2286990,
        "product_id": 461989,
        "sku": "1041038_2405",
        "sku_id": 1825145,
        "price": 16.25,
        "calculated_price": 16.25,
        "sale_price": 16.25,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826346,
            "label": "6 X Can 473 ml",
            "option_id": 461985,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2287017,
        "product_id": 461989,
        "sku": "1041041_2405",
        "sku_id": 1825170,
        "price": 56.95,
        "calculated_price": 56.95,
        "sale_price": 56.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826371,
            "label": "24 X Can 473 ml",
            "option_id": 461985,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2287050,
        "product_id": 461989,
        "sku": "1041042_2405",
        "sku_id": 1825203,
        "price": 29.95,
        "calculated_price": 29.95,
        "sale_price": 29.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 49,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826405,
            "label": "12 X Can 473 ml",
            "option_id": 461985,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2287078,
        "product_id": 461989,
        "sku": "1041049_2405",
        "sku_id": 1825228,
        "price": 137.95,
        "calculated_price": 137.95,
        "sale_price": 137.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826429,
            "label": "1 X Keg 20000 ml",
            "option_id": 461985,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2287116,
        "product_id": 461989,
        "sku": "1041097_2405",
        "sku_id": 1825263,
        "price": 11.25,
        "calculated_price": 11.25,
        "sale_price": 11.25,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826458,
            "label": "4 X Can 473 ml",
            "option_id": 461985,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2287135,
        "product_id": 461989,
        "sku": "1041133_2405",
        "sku_id": 1825280,
        "price": 35.5,
        "calculated_price": 35.5,
        "sale_price": 35.5,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826482,
            "label": "18 X Can 355 ml",
            "option_id": 461985,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2287156,
        "product_id": 461989,
        "sku": "1041156_2405",
        "sku_id": 1825298,
        "price": 20.75,
        "calculated_price": 20.75,
        "sale_price": 20.75,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826500,
            "label": "8 X Can 473 ml",
            "option_id": 461985,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2287174,
        "product_id": 461989,
        "sku": "1041160_2405",
        "sku_id": 1825317,
        "price": 28.95,
        "calculated_price": 28.95,
        "sale_price": 28.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 9,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826518,
            "label": "15 X Bottle 341 ml",
            "option_id": 461985,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2287185,
        "product_id": 461989,
        "sku": "1041171_2405",
        "sku_id": 1825325,
        "price": 50.95,
        "calculated_price": 50.95,
        "sale_price": 50.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826525,
            "label": "30 X Bottle 341 ml",
            "option_id": 461985,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2287210,
        "product_id": 461989,
        "sku": "1041172_2405",
        "sku_id": 1825344,
        "price": 52.95,
        "calculated_price": 52.95,
        "sale_price": 52.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 29,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826543,
            "label": "30 X Can 355 ml",
            "option_id": 461985,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2287245,
        "product_id": 461989,
        "sku": "1041173_2405",
        "sku_id": 1825373,
        "price": 30.95,
        "calculated_price": 30.95,
        "sale_price": 30.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826575,
            "label": "15 X Can 355 ml",
            "option_id": 461985,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2287270,
        "product_id": 461989,
        "sku": "1041211_2405",
        "sku_id": 1825389,
        "price": 92.95,
        "calculated_price": 92.95,
        "sale_price": 92.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826590,
            "label": "48 X Can 355 ml",
            "option_id": 461985,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2287303,
        "product_id": 461989,
        "sku": "1041227_2405",
        "sku_id": 1825421,
        "price": 59.5,
        "calculated_price": 59.5,
        "sale_price": 59.5,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826622,
            "label": "36 X Bottle 341 ml",
            "option_id": 461985,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2287323,
        "product_id": 461989,
        "sku": "1041288_2405",
        "sku_id": 1825436,
        "price": 4.15,
        "calculated_price": 4.15,
        "sale_price": 4.15,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826638,
            "label": "1 X Can 740 ml",
            "option_id": 461985,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2287339,
        "product_id": 461989,
        "sku": "1041289_2405",
        "sku_id": 1825449,
        "price": 7.85,
        "calculated_price": 7.85,
        "sale_price": 7.85,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826651,
            "label": "2 X Can 740 ml",
            "option_id": 461985,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2287358,
        "product_id": 461989,
        "sku": "1041292_2405",
        "sku_id": 1825466,
        "price": 45.4,
        "calculated_price": 45.4,
        "sale_price": 45.4,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826668,
            "label": "12 X Can 740 ml",
            "option_id": 461985,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2287387,
        "product_id": 461989,
        "sku": "1041604_2405",
        "sku_id": 1825488,
        "price": 69.95,
        "calculated_price": 69.95,
        "sale_price": 69.95,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 0,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826690,
            "label": "36 X Can 355 ml",
            "option_id": 461985,
            "option_display_name": "Beer"
          }
        ]
      },
      {
        "id": 2287419,
        "product_id": 461989,
        "sku": "2084100_2405",
        "sku_id": 1825511,
        "price": 47.5,
        "calculated_price": 47.5,
        "sale_price": 47.5,
        "retail_price": null,
        "map_price": null,
        "weight": null,
        "calculated_weight": 1,
        "width": null,
        "height": null,
        "depth": null,
        "is_free_shipping": false,
        "fixed_cost_shipping_price": null,
        "purchasing_disabled": false,
        "purchasing_disabled_message": "",
        "image_url": "",
        "cost_price": 0,
        "upc": "",
        "mpn": "",
        "gtin": "",
        "inventory_level": 10,
        "inventory_warning_level": 0,
        "bin_picking_number": "",
        "option_values": [
          {
            "id": 1826713,
            "label": "28 X Bottle 341 ml",
            "option_id": 461985,
            "option_display_name": "Beer"
          }
        ]
      }
    ],
    "images": [],
    "primary_image": null,
    "custom_fields": [
      {
        "id": 6647329,
        "name": "ABV",
        "value": "4.00"
      },
      {
        "id": 6647336,
        "name": "Country",
        "value": "CA"
      },
      {
        "id": 6647340,
        "name": "Category",
        "value": "Premium"
      },
      {
        "id": 6647344,
        "name": "Type",
        "value": "Light"
      },
      {
        "id": 6647349,
        "name": "Beverage_type",
        "value": "Beer"
      },
      {
        "id": 6647354,
        "name": "Producer",
        "value": "LABATT"
      },
      {
        "id": 6647357,
        "name": "Beer_attributes",
        "value": "Light"
      },
      {
        "id": 6647359,
        "name": "Beer_styles",
        "value": "Pilsner,Pale"
      },
      {
        "id": 6647361,
        "name": "Price_Metadata_1041001",
        "value": "{\"current_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.48}],\"total_price\":13.50},\"previous_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.42}],\"total_price\":12.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647364,
        "name": "Price_Metadata_1041002",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.68}],\"total_price\":24.50},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.62}],\"total_price\":23.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647366,
        "name": "Price_Metadata_1041003",
        "value": "{\"current_price\":{\"deposit\":1.80,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.65}],\"total_price\":33.50},\"previous_price\":{\"deposit\":1.80,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.58}],\"total_price\":32.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647367,
        "name": "Price_Metadata_1041004",
        "value": "{\"current_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.32}],\"total_price\":2.85},\"previous_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.31}],\"total_price\":2.80},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647368,
        "name": "Price_Metadata_1041005",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":4.61}],\"total_price\":42.50},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":4.55}],\"total_price\":41.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647369,
        "name": "Price_Metadata_1041024",
        "value": "{\"current_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.41}],\"total_price\":3.65},\"previous_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.41}],\"total_price\":3.65},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647370,
        "name": "Price_Metadata_1041027",
        "value": "{\"current_price\":{\"deposit\":50.00,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":35.38}],\"total_price\":357.50},\"previous_price\":{\"deposit\":50.00,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":34.80}],\"total_price\":352.50},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647371,
        "name": "Price_Metadata_1041030",
        "value": "{\"current_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.54}],\"total_price\":13.95},\"previous_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.40}],\"total_price\":12.75},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647372,
        "name": "Price_Metadata_1041031",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.80}],\"total_price\":25.50},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.73}],\"total_price\":24.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647373,
        "name": "Price_Metadata_1041033",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.19}],\"total_price\":47.50},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.07}],\"total_price\":46.50},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647374,
        "name": "Price_Metadata_1041038",
        "value": "{\"current_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.80}],\"total_price\":16.25},\"previous_price\":{\"deposit\":0.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.77}],\"total_price\":15.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647375,
        "name": "Price_Metadata_1041041",
        "value": "{\"current_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":6.28}],\"total_price\":56.95},\"previous_price\":{\"deposit\":2.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":6.16}],\"total_price\":55.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647376,
        "name": "Price_Metadata_1041042",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.31}],\"total_price\":29.95},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.26}],\"total_price\":29.50},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647377,
        "name": "Price_Metadata_1041049",
        "value": "{\"current_price\":{\"deposit\":20.00,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":13.57}],\"total_price\":137.95},\"previous_price\":{\"deposit\":20.00,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":13.22}],\"total_price\":134.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647378,
        "name": "Price_Metadata_1041097",
        "value": "{\"current_price\":{\"deposit\":0.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.25}],\"total_price\":11.25},\"previous_price\":{\"deposit\":0.40,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":1.21}],\"total_price\":10.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647380,
        "name": "Price_Metadata_1041133",
        "value": "{\"current_price\":{\"deposit\":1.80,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.88}],\"total_price\":35.50},\"previous_price\":{\"deposit\":1.80,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.81}],\"total_price\":34.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647381,
        "name": "Price_Metadata_1041156",
        "value": "{\"current_price\":{\"deposit\":0.80,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.30}],\"total_price\":20.75},\"previous_price\":{\"deposit\":0.80,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":2.27}],\"total_price\":20.50},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647383,
        "name": "Price_Metadata_1041160",
        "value": "{\"current_price\":{\"deposit\":1.50,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.16}],\"total_price\":28.95},\"previous_price\":{\"deposit\":1.50,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.11}],\"total_price\":28.50},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647386,
        "name": "Price_Metadata_1041171",
        "value": "{\"current_price\":{\"deposit\":3.00,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.52}],\"total_price\":50.95},\"previous_price\":{\"deposit\":3.00,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.40}],\"total_price\":49.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647388,
        "name": "Price_Metadata_1041172",
        "value": "{\"current_price\":{\"deposit\":3.00,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.75}],\"total_price\":52.95},\"previous_price\":{\"deposit\":3.00,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.98}],\"total_price\":54.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647390,
        "name": "Price_Metadata_1041173",
        "value": "{\"current_price\":{\"deposit\":1.50,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.39}],\"total_price\":30.95},\"previous_price\":{\"deposit\":1.50,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":3.27}],\"total_price\":29.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647393,
        "name": "Price_Metadata_1041211",
        "value": "{\"current_price\":{\"deposit\":4.80,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":10.14}],\"total_price\":92.95},\"previous_price\":{\"deposit\":4.80,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":9.91}],\"total_price\":90.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647395,
        "name": "Price_Metadata_1041227",
        "value": "{\"current_price\":{\"deposit\":3.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":6.43}],\"total_price\":59.50},\"previous_price\":{\"deposit\":3.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":6.37}],\"total_price\":58.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647398,
        "name": "Price_Metadata_1041288",
        "value": "{\"current_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.47}],\"total_price\":4.15},\"previous_price\":{\"deposit\":0.10,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.46}],\"total_price\":4.10},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647400,
        "name": "Price_Metadata_1041289",
        "value": "{\"current_price\":{\"deposit\":0.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.88}],\"total_price\":7.85},\"previous_price\":{\"deposit\":0.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":0.85}],\"total_price\":7.60},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647402,
        "name": "Price_Metadata_1041292",
        "value": "{\"current_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.08}],\"total_price\":45.40},\"previous_price\":{\"deposit\":1.20,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.03}],\"total_price\":44.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647404,
        "name": "Price_Metadata_1041604",
        "value": "{\"current_price\":{\"deposit\":3.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":7.63}],\"total_price\":69.95},\"previous_price\":{\"deposit\":3.60,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":7.47}],\"total_price\":68.50},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647407,
        "name": "Price_Metadata_2084100",
        "value": "{\"current_price\":{\"deposit\":2.80,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.14}],\"total_price\":47.50},\"previous_price\":{\"deposit\":2.80,\"tax\":[{\"tax_type\":\"HST\",\"tax_amount\":5.08}],\"total_price\":46.95},\"on_sale\":\"N\"}"
      },
      {
        "id": 6647410,
        "name": "product_image_1",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbg6w5-4yi51s-efuwo7/0574.png?position=1"
      },
      {
        "id": 6647412,
        "name": "product_image_2",
        "value": "https://cdn.brandfolder.io/DRTYD0A2/as/qbg6w5-4yi51s-efuwo7/0574.png?position=2"
      },
      {
        "id": 6647415,
        "name": "container_type ",
        "value": "B,C,K"
      },
      {
        "id": 6647417,
        "name": "pack",
        "value": "6,12,18,1,24,4,8,15,30,48,36,2,28"
      }
    ]
  }
];

export const getBeers = () => {
  return { beers };
}